import { CodeTitle, TitlesDTO } from '@platform/front-types';
import { CodeTitleNull } from '@platform/front-utils';
import { FieldValues } from '@platform/multi-lang-field';
import {
    CounterWidgetCreateEditFormSettingsFields,
    CounterWidgetCreateEditFormSettingsValues,
    CounterWidgetCreateUpdateDTO,
    CounterWidgetDTO,
    CounterWidgetSpecificAttributes,
    CounterWidgetUpdateInfoDTO,
    CounterWidgetCreationInfoSettings,
    CounterWidgetCreationInfoSettingsFromBackend,
} from './counterWidget';
import {
    LinkWidgetCreateEditFormSettingsFields,
    LinkWidgetCreateEditFormSettingsValues,
    LinkWidgetCreateUpdateDTO,
    LinkWidgetDTO,
    LinkWidgetSpecificAttributes,
    LinkWidgetUpdateInfoDTO,
} from './linkWidget';

export enum WidgetType {
    counter = 'counter',
    link = 'link',
}

/**
 *  Название общих для всех виджетов полей формы создания/редактирования виджета
 */
export enum WidgetCreateEditFormCommonFields {
    titles = 'titles',
    widgetType = 'widgetType',
    backgroundColor = 'backgroundColor',
    iconCode = 'iconCode',
}

/**
 *  Дженерик-тип значений общих для всех виджетов полей формы создания/редактирования виджета
 */
export type WidgetCreateEditFormCommonFieldsValues<SettingsType> = {
    [WidgetCreateEditFormCommonFields.titles]: FieldValues[];
    [WidgetCreateEditFormCommonFields.widgetType]: CodeTitleNull;
    settings: SettingsType;
    [WidgetCreateEditFormCommonFields.backgroundColor]?: WidgetBackgroundColorViewSetting;
    [WidgetCreateEditFormCommonFields.iconCode]?: WidgetBaseIconCode;
};

/**
 *  Тип значений всех полей формы создания/редактирования виджета
 */
export type WidgetCreateEditFormValues = WidgetCreateEditFormCommonFieldsValues<
    CounterWidgetCreateEditFormSettingsValues & LinkWidgetCreateEditFormSettingsValues
>;

/**
 *  Тип данных, описывающий преобразованные данные, необходимые для заполнения полей формы создания/редактирования
 *  виджета (опции селектов полей настройки создания/редактирования виджета-счетчика)
 */
export type WidgetCreationInfoSettings = CounterWidgetCreationInfoSettings;

/**
 *  Тип данных, описывающий преобразованные данные, необходимые для заполнения полей формы создания/редактирования виджета
 */
export type WidgetCreationInfoSettingsFromBackend = CounterWidgetCreationInfoSettingsFromBackend;

/**
 *  Объект со всеми полями формы созадния/редактирования виджета
 */
export const widgetCreateEditAllPossibleFields = {
    ...WidgetCreateEditFormCommonFields,
    ...CounterWidgetCreateEditFormSettingsFields,
    ...LinkWidgetCreateEditFormSettingsFields,
};

export type WidgetCreateEditAllPossibleFields = typeof widgetCreateEditAllPossibleFields;

/**
 *  Базовый тип-дженерик, содержащий данные общих полей DTO, отправляющегося на бэк при сохранении/редактировании виджета
 */
export type WidgetCreateUpdateDTOBase<WT extends WidgetType, S> = {
    titles: TitlesDTO;
    widgetType: WT;
    settings: S;
    viewSettings: Partial<WidgetBaseDataDTOViewSettings>;
    iconCode?: WidgetBaseIconCode;
};

/**
 *  Базовый тип-дженерик, содержащий общие для всех виджетов данные которые мы получаем с бэка
 */
export type WidgetUpdateInfoDTOBase<S> = {
    titles: TitlesDTO;
    widgetType: CodeTitle;
    settings: S;
    viewSettings: Partial<WidgetBaseDataDTOViewSettings>;
    iconCode?: WidgetBaseIconCode;
};

/**
 *  Тип, содержащий итоговые (соответствующие определенному типу виджета) данные, отправляющегося на бэк при сохранении/редактировании виджета
 */
export type WidgetCreateUpdateDTO = CounterWidgetCreateUpdateDTO | LinkWidgetCreateUpdateDTO;

/**
 *  Тип данных, описывающий данные сохраненного на бэке виджета, и использующиеся для редактирования виджета (инициализация полей редактирования существующего виджета)
 */
export type WidgetUpdateInfoDTO = CounterWidgetUpdateInfoDTO | LinkWidgetUpdateInfoDTO;

/**
 *  Тип настроек отображения виджета
 */
export type WidgetBaseDataDTOViewSettings = {
    fontSize: WidgetFontSizeViewSetting;
    backgroundColor: WidgetBackgroundColorViewSetting;
};

/**
 "Базовые" данные виджета, которые приходят в данных раздела (остальное подргужает WidgetModel)
 */
export type WidgetBaseDataDTO<Type = WidgetType> = {
    id: string;
    widgetType: Type;
    title: string;
    viewSettings: Partial<WidgetBaseDataDTOViewSettings>;
    iconCode?: WidgetBaseIconCode;
};

/**
    Тип-генератор специфичных для виджета данных
 */
export type WidgetBaseDataDTOWithWidgetAttributes<
    Type = WidgetType,
    WidgetSpecificAttributes = WidgetTypeSpecificAttributes,
> = WidgetBaseDataDTO<Type> & {
    failed?: boolean;
    widgetAttributes: WidgetSpecificAttributes;
};

/**
     Виды специфичных для типов виджета данных
 */
export type WidgetTypeSpecificAttributes = CounterWidgetSpecificAttributes | LinkWidgetSpecificAttributes;

/**
 * Тип размера шрифта настроек отображения виджета
 */
export enum WidgetFontSizeViewSetting {
    standard = 'standard', // стандартный
    large = 'large', // крупный
    huge = 'huge', // огромный
}

/**
 * Результаты проверки пермишенов, передаваемые в виджеты из раздела и состояние загрузки запроса на проверку пермишенов
 */
export type WidgetPermissions = {
    isAllowedToUpdateWidget?: boolean;
    isAllowedToDeleteWidget?: boolean;
    isAllowedToUpdateSection?: boolean;
    isPermissionsLoading: boolean;
};

/**
 * Тип цвета фона виджета
 */
export enum WidgetBackgroundColorViewSetting {
    white = 'white',

    lightRed = 'lightRed',
    lightOrange = 'lightOrange',
    lightChlorine = 'lightChlorine',
    lightGreen = 'lightGreen',
    lightTurquoise = 'lightTurquoise',
    lightBlue = 'lightBlue',
    lightViolet = 'lightViolet',
    lightPink = 'lightPink',

    red = 'red',
    orange = 'orange',
    chlorine = 'chlorine',
    green = 'green',
    turquoise = 'turquoise',
    blue = 'blue',
    violet = 'violet',
    pink = 'pink',

    redGradient = 'redGradient',
    orangeGradient = 'orangeGradient',
    chlorineGradient = 'chlorineGradient',
    greenGradient = 'greenGradient',
    turquoiseGradient = 'turquoiseGradient',
    blueGradient = 'blueGradient',
    violetGradient = 'violetGradient',
    pinkGradient = 'pinkGradient',
}

/**
 * Тип иконки виджета
 */
export enum WidgetBaseIconCode {
    Alarm = 'Alarm',
    Add = 'Add',
    Anchor = 'Anchor',
    Announcement = 'Announcement',
    AttachFile = 'AttachFile',
    BackHand = 'BackHand',
    Beenhere = 'Beenhere',
    Biotech = 'Biotech',
    Block = 'Block',
    Bolt = 'Bolt',
    Bookmark = 'Bookmark',
    Build = 'Build',
    Cached = 'Cached',
    CalendarMonth = 'CalendarMonth',
    Campaign = 'Campaign',
    Cancel = 'Cancel',
    CheckCircle = 'CheckCircle',
    Delete = 'Delete',
    Drafts = 'Drafts',
    Edit = 'Edit',
    Flag = 'Flag',
    Folder = 'Folder',
    Help = 'Help',
    Home = 'Home',
    HourglassTop = 'HourglassTop',
    Hub = 'Hub',
    Lightbulb = 'Lightbulb',
    LocalFireDepartment = 'LocalFireDepartment',
    MenuBook = 'MenuBook',
    None = 'none',
    NotificationsActive = 'NotificationsActive',
    Pause = 'Pause',
    Payments = 'Payments',
    Person = 'Person',
    Star = 'Star',
    Work = 'Work',
}

export type WidgetDTO = CounterWidgetDTO | LinkWidgetDTO;

export type WidgetDTOWithOptionalWidgetAttributes = Omit<WidgetDTO, 'widgetAttributes'> &
    Partial<Pick<WidgetDTO, 'widgetAttributes'>>;
