import { IntlStore } from '@platform/front-core';
import { CodeTitleGroup, ServerErrorsModel } from '@platform/front-utils';
import { getRuTitleInitialValue } from '@platform/multi-lang-field';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { DashboardStore, RootStore } from '../../stores';
import { DashboardRoutes, SectionCreateEditFields, SectionCreateEditFormValues } from '../../types';

export const sectionCreateEditDialogModelObservables = {
    dashboardStore: observable,
    intlStore: observable,
    history: observable,

    dashboardId: observable,
    serverErrors: observable,

    userGroupsList: observable,
    isUserGroupsListLoading: observable,

    formInitialValues: observable,
    isFormInitialValuesLoading: observable,

    routes: observable,

    onCancel: action.bound,
    loadUserGroupsList: action.bound,
    setUserGroupList: action.bound,
    setIsUserGroupsListLoading: action.bound,
    setFormInitialValues: action.bound,
    setIsFormInitialValuesLoading: action.bound,
};

export const sectionCreateEditDialogModelAbstractObservables = {
    onSubmit: action.bound,
};

export const getSectionCreateEditDialogDefaultFormInitialValues = (): SectionCreateEditFormValues => ({
    titles: getRuTitleInitialValue(),
    roles: [],
});

/**
 * Класс, содержащий общие данные и логику диалога создания/редактирования раздела
 */
export abstract class SectionCreateEditDialogModel {
    abstract onSubmit(formValues: SectionCreateEditFormValues): Promise<void>;

    protected dashboardStore: DashboardStore;
    protected intlStore: IntlStore;
    protected history: History;

    dashboardId: string;
    serverErrors = new ServerErrorsModel<SectionCreateEditFields>();

    userGroupsList: CodeTitleGroup[] = [];
    isUserGroupsListLoading = false;

    formInitialValues: SectionCreateEditFormValues = getSectionCreateEditDialogDefaultFormInitialValues();
    isFormInitialValuesLoading = false;

    routes: DashboardRoutes;

    constructor(dashboardId: string, rootStore: RootStore, routes: DashboardRoutes) {
        const { coreRootStore, dashboardStore } = rootStore;
        this.dashboardStore = dashboardStore;
        this.history = coreRootStore.history;
        this.intlStore = coreRootStore.intlStore;
        this.dashboardId = dashboardId;
        this.routes = routes;
        makeObservable(this, sectionCreateEditDialogModelObservables);
    }

    onCancel(): void {
        this.dashboardId && this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
    }

    loadUserGroupsList(): void {
        this.setIsUserGroupsListLoading(true);
        this.dashboardStore
            .getSectionCreationInfo(this.dashboardId)
            .then((dto) => {
                this.setUserGroupList(dto.roles);
            })
            .finally(() => this.setIsUserGroupsListLoading(false));
    }

    setUserGroupList(userGroupsList: CodeTitleGroup[]): void {
        this.userGroupsList = userGroupsList;
    }

    setIsUserGroupsListLoading(isLoading: boolean): void {
        this.isUserGroupsListLoading = isLoading;
    }

    setFormInitialValues(initialValues: SectionCreateEditFormValues): void {
        this.formInitialValues = initialValues;
    }

    setIsFormInitialValuesLoading(isLoading: boolean): void {
        this.isFormInitialValuesLoading = isLoading;
    }
}
