import { useLocale } from '@platform/front-core';
import { ConfirmationDialog } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext } from '../../../../../hooks';

export type DashboardDeleteDialogProps = {
    onCancel: VoidFunction;
};

export const DashboardDeleteDialog = observer((props: DashboardDeleteDialogProps): JSX.Element => {
    const { onCancel } = props;
    const history = useHistory();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { delete: deleteDashboard } = dashboardModel;

    const title = useLocale('dashboard.phrases.delete', true, {
        dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
    })[0];

    const message = useLocale('dashboard.phrases.deleteDialogText', true, {
        dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
    })[0];

    const handleDashboardDeleting = async (): Promise<void> => {
        await deleteDashboard();
        history.push(routes.dashboardRedirectAfterDelete);
        onCancel();
    };

    return (
        <ConfirmationDialog
            open
            id="dashboard-delete-dialog"
            keepMounted
            onCancel={onCancel}
            onConfirm={handleDashboardDeleting}
            title={title}
            message={message}
        />
    );
});
