import { Box } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { WidgetType } from '../../../../../../../types';
import { CounterWidgetSkeleton as CounterWidgetSkeletonInj } from './counter-widget';
import { UnknownWidgetTypeLoader as UnknownWidgetTypeLoaderInj } from './UnknownWidgetTypeLoader';

export type WidgetSkeletonProps = {
    widgetType: WidgetType;
};

const sxStyles = makeSxStyles({
    box: {
        width: '100%',
        height: '100%',
    },
});

export const WidgetSkeleton = observer((props: WidgetSkeletonProps): JSX.Element => {
    const [CounterWidgetSkeleton] = di([CounterWidgetSkeletonInj], WidgetSkeleton);
    const [UnknownWidgetTypeLoader] = di([UnknownWidgetTypeLoaderInj], WidgetSkeleton);
    const { widgetType } = props;

    const renderSkeleton = (): React.ReactNode => {
        switch (widgetType) {
            case WidgetType.counter: {
                return <CounterWidgetSkeleton />;
            }
            default: {
                return <UnknownWidgetTypeLoader />;
            }
        }
    };

    return <Box sx={sxStyles.box}>{renderSkeleton()}</Box>;
});
