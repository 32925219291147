import { CodeTitleGroupWithRegistryUrlData, RegistrySelectOptionDataItem } from '../../types';

/**
 * Преобразованые бэковой DTO для опции селекта выбора типа реестра в подходящую для передачи в AutocompleteField
 */
export const mapRegistrySelectOptionDataItemToCodeTitleGroup = (
    registrySelectOption: RegistrySelectOptionDataItem,
): CodeTitleGroupWithRegistryUrlData => {
    const { title, code, moduleCode, moduleTitle, linkUrl, apiUrlPrefix } = registrySelectOption;
    return {
        code,
        title,
        group: moduleCode,
        groupTitle: moduleTitle,
        linkUrl,
        apiUrlPrefix,
    };
};
