import { EmailOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
    AppContentContainer,
    Authentication,
    AuthPage,
    ConsolePage,
    coreClientRoute,
    Footer,
    NewPasswordPage,
    NotificationComponent,
    NotificationPage,
    RedirectByRole,
} from '@platform/front-core';
import { SxStyle } from '@platform/front-ui';
import { ErrorCode } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import { Header as HeaderInj } from './components';
import { myDashboardsRoutesList } from './constants';
import { useStore } from './hooks';
import {
    DashboardPages as DashboardPagesInj,
    LoginPageCustomForgotPasswordLink as LoginPageCustomForgotPasswordLinkInj,
    MyDashboards as MyDashboardsInj,
} from './pages';

const wrapperSx: SxStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'nowrap',
};

export const additionalPagesInj = (): JSX.Element[] => [];
export const routesWithAuthenticationInj = (): string[] => [
    clientRoute.myDashboards.dashboards,
    clientRoute.allDashboards.dashboards,
    coreClientRoute.console,
    coreClientRoute.notFound,
    coreClientRoute.notAllowed,
];

const emailOutlinedIcon = <EmailOutlined />;

export const App = observer((): JSX.Element => {
    const [additionalPages] = di([additionalPagesInj], App);
    const [routesWithAuthentication] = di([routesWithAuthenticationInj], App);
    const [Header] = di([HeaderInj], App);
    const [MyDashboards] = di([MyDashboardsInj], App);
    const [DashboardPages] = di([DashboardPagesInj], App);
    const [LoginPageCustomForgotPasswordLink] = di([LoginPageCustomForgotPasswordLinkInj], App);

    const additionalPagesArray = additionalPages();
    const routesWithAuthenticationArray = routesWithAuthentication();

    const { settingsFromBackend } = useStore();
    const { loginPageLogo } = settingsFromBackend;
    const { formatMessage } = useIntl();

    return (
        <Box sx={wrapperSx}>
            <Switch>
                <Route path={coreClientRoute.authPrefix}>
                    <AuthPage
                        logo={loginPageLogo}
                        CustomForgotPasswordLinkComponent={LoginPageCustomForgotPasswordLink}
                        isWithRegistrationPage={false}
                        isWithRecoveryPasswordPage={false}
                    />
                </Route>
                <Route path={coreClientRoute.newPassword} exact>
                    <NewPasswordPage logo={loginPageLogo} isWithServerErrors />
                </Route>
                <Route>
                    <Header />
                    <AppContentContainer>
                        <Switch>
                            <Route path={routesWithAuthenticationArray}>
                                <Authentication>
                                    <Switch>
                                        {additionalPagesArray}
                                        <Route path={clientRoute.myDashboards.dashboards}>
                                            <Route path={myDashboardsRoutesList}>
                                                <MyDashboards />
                                            </Route>
                                        </Route>
                                        <Route path={clientRoute.allDashboards.dashboards}>
                                            <DashboardPages />
                                        </Route>
                                        <Route path={coreClientRoute.console}>
                                            <ConsolePage />
                                        </Route>
                                        <Route path={coreClientRoute.notAllowed}>
                                            <NotificationPage
                                                errorCode={ErrorCode.notAllowed}
                                                notificationText={formatMessage({ id: 'common.actionNotAllowed' })}
                                                withActionButtons
                                            />
                                        </Route>
                                        <Route path={coreClientRoute.notFound}>
                                            <NotificationPage
                                                errorCode={ErrorCode.notFound}
                                                notificationText={formatMessage({ id: 'notFoundPage.notExist' })}
                                                withActionButtons
                                            />
                                        </Route>
                                    </Switch>
                                </Authentication>
                            </Route>
                            <Route path={coreClientRoute.recoveryPasswordInfoSuccess}>
                                <NotificationPage
                                    icon={emailOutlinedIcon}
                                    notificationText={formatMessage({ id: 'authentication.recoveryLinkSent' })}
                                />
                            </Route>
                            <Route path={clientRoute.root} exact>
                                <Authentication>
                                    <RedirectByRole />
                                </Authentication>
                            </Route>
                            <Route>
                                <Redirect to={coreClientRoute.notFound} />
                            </Route>
                        </Switch>
                    </AppContentContainer>
                    <Footer />
                </Route>
            </Switch>
            <NotificationComponent />
        </Box>
    );
});
