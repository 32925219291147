import { ApiStore, CoreRootStore } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { Layouts } from 'react-grid-layout';
import { ApiConfigs } from '../apiConfigs';
import {
    DashboardCreateEditFields,
    DashboardCreateUpdateDTO,
    DashboardCreationInfoDTO,
    DashboardDTO,
    DashboardTabDTO,
    SectionCreateEditFields,
    SectionCreateUpdateDTO,
    SectionCreationInfoDTO,
    SectionDTO,
    WidgetBaseDataDTOViewSettings,
    WidgetCreateUpdateDTO,
    WidgetCreationInfoSettingsFromBackend,
    WidgetDTO,
    WidgetType,
    WidgetUpdateInfoDTO,
} from '../types';

export const dashboardStoreObservables = {
    api: observable,
    apiConfigs: observable,

    getDashboardCreationInfo: action.bound,
    getDashboardUpdateInfo: action.bound,
    getDashboard: action.bound,
    createDashboard: action.bound,
    updateDashboard: action.bound,
    deleteDashboard: action.bound,

    getSectionCreationInfo: action.bound,
    createSection: action.bound,
    getSectionInfo: action.bound,
    changeSectionPosition: action.bound,
    deleteSection: action.bound,
    getSectionUpdateInfo: action.bound,
    updateSection: action.bound,

    getWidgetCreationInfoSettingsByWidgetType: action.bound,
    createWidget: action.bound,
    updateWidget: action.bound,
    updateWidgetViewSettings: action.bound,
    deleteWidget: action.bound,
    getWidgetInfo: action.bound,
    getWidgetUpdateInfo: action.bound,
    updateWidgetPositions: action.bound,
    getDashboardTabs: action.bound,
};

export class DashboardStore {
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;

    constructor(coreRootStore: CoreRootStore) {
        const { api } = coreRootStore;
        this.api = api;
        this.apiConfigs = api.apiConfigs as ApiConfigs;
        makeObservable(this, dashboardStoreObservables);
    }

    getDashboardCreationInfo(): Promise<DashboardCreationInfoDTO> {
        return this.api.client(this.apiConfigs.getDashboardCreationInfo).then((r) => r.data);
    }

    getDashboardUpdateInfo(id: string): Promise<DashboardCreateUpdateDTO> {
        return this.api.client(this.apiConfigs.getDashboardUpdateInfo(id)).then((r) => r.data);
    }

    getDashboard(id: string): Promise<DashboardDTO> {
        return this.api.mainInfoClient(this.apiConfigs.getDashboard(id)).then((r) => r.data);
    }

    createDashboard(dto: DashboardCreateUpdateDTO): Promise<string> {
        return this.api
            .userActionClient(this.apiConfigs.createDashboard(dto), Object.values(DashboardCreateEditFields))
            .then((r) => r.data);
    }

    updateDashboard(id: string, dto: DashboardCreateUpdateDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.updateDashboard(id, dto), Object.values(DashboardCreateEditFields))
            .then((r) => r.data);
    }

    deleteDashboard(dashboardId: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteDashboard(dashboardId)).then((r) => r.data);
    }

    getSectionCreationInfo(dashboardId: string): Promise<SectionCreationInfoDTO> {
        return this.api.client(this.apiConfigs.getSectionCreationInfo(dashboardId)).then((r) => r.data);
    }

    createSection(dashboardId: string, dto: SectionCreateUpdateDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.createSection(dashboardId, dto), Object.values(SectionCreateEditFields))
            .then((r) => r.data);
    }

    getSectionInfo(sectionId: string): Promise<SectionDTO> {
        return this.api.mainInfoClient(this.apiConfigs.getSectionInfo(sectionId)).then((r) => r.data);
    }

    changeSectionPosition(dashboardId: string, sectionId: string, newPosition: number): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.changeSectionPosition(sectionId, newPosition))
            .then((r) => r.data);
    }

    deleteSection(sectionId: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteSection(sectionId)).then((r) => r.data);
    }

    getSectionUpdateInfo(sectionId: string): Promise<SectionCreateUpdateDTO> {
        return this.api.userActionClient(this.apiConfigs.getSectionUpdateInfo(sectionId)).then((r) => r.data);
    }

    updateSection(sectionId: string, newSectionData: SectionCreateUpdateDTO): Promise<void> {
        return this.api
            .userActionClient(
                this.apiConfigs.updateSection(sectionId, newSectionData),
                Object.values(SectionCreateEditFields),
            )
            .then((r) => r.data);
    }

    getWidgetCreationInfoSettingsByWidgetType(
        sectionId: string,
        widgetType: WidgetType,
    ): Promise<WidgetCreationInfoSettingsFromBackend> {
        return this.api
            .userActionClient(this.apiConfigs.getDashboardCreationInfoSettingsByWidgetType(sectionId, widgetType))
            .then((r) => r.data);
    }

    createWidget(
        dto: WidgetCreateUpdateDTO,
        sectionId: string,
        fieldsListForServerValidation: string[],
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.createWidget(sectionId, dto), fieldsListForServerValidation)
            .then((r) => r.data);
    }

    updateWidget(
        widgetId: string,
        sectionId: string,
        dto: WidgetCreateUpdateDTO,
        fieldsListForServerValidation: string[],
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.updateWidget(widgetId, sectionId, dto), fieldsListForServerValidation)
            .then((r) => r.data);
    }

    updateWidgetViewSettings(
        widgetId: string,
        sectionId: string,
        viewSettings: Partial<WidgetBaseDataDTOViewSettings>,
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.updateWidgetViewSettings(widgetId, sectionId, viewSettings))
            .then((r) => r.data);
    }

    deleteWidget(widgetId: string, sectionId: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteWidget(widgetId, sectionId)).then((r) => r.data);
    }

    getWidgetInfo(sectionId: string, widgetId: string): Promise<WidgetDTO> {
        return this.api.mainInfoClient(this.apiConfigs.getWidgetInfo(sectionId, widgetId)).then((r) => r.data);
    }

    getWidgetUpdateInfo(sectionId: string, widgetId: string): Promise<WidgetUpdateInfoDTO> {
        return this.api.userActionClient(this.apiConfigs.getWidgetUpdateInfo(sectionId, widgetId)).then((r) => r.data);
    }

    updateWidgetPositions(sectionId: string, layouts: Layouts): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.updateWidgetPositions(sectionId, layouts)).then((r) => r.data);
    }

    getDashboardTabs(): Promise<DashboardTabDTO[]> {
        return this.api.mainInfoClient(this.apiConfigs.getDashboardTabs).then((r) => r.data);
    }

    syncDashboardsTabsPosition(arrayOfDashboardIdsInOrder: string[]): Promise<void> {
        return this.api
            .client(this.apiConfigs.syncDashboardsTabsPosition(arrayOfDashboardIdsInOrder))
            .then((r) => r.data);
    }
}
