import { Grid } from '@mui/material';
import { grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router';
import { useDashboardPageContext } from '../../hooks';
import { DashboardTabsModel } from '../../models';
import { DashboardRouteParams } from '../../types';
import { DashboardPageContent as DashboardPageContentInj } from '../dashboards';
import {
    MyDashboardPageHeader as MyDashboardPageHeaderInj,
    MyDashboardsPageTabs as MyDashboardsPageTabsInj,
} from './components';

const sxStyles = makeSxStyles({
    mainWrapper: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    tabsWithHeaderContainer: {
        flex: '0 0 auto',
    },
    tabsContainer: {
        borderBottom: `1px solid ${grey[300]}`,
        px: 2,
    },
    headerContainer: {
        px: 2,
        py: 1,
    },
    mainContentWrapper: {
        flex: '1 1 auto',
        overflow: 'hidden auto',
    },
    mainContent: {
        px: 1,
        pb: 1,
        height: '100%',
    },
});

export type MyDashboardProps = {
    tabsModel: DashboardTabsModel;
};

export const MyDashboard = observer((props: MyDashboardProps): JSX.Element => {
    const [MyDashboardPageHeader] = di([MyDashboardPageHeaderInj], MyDashboard);
    const [MyDashboardsPageTabs] = di([MyDashboardsPageTabsInj], MyDashboard);
    const [DashboardPageContent] = di([DashboardPageContentInj], MyDashboard);

    const { tabsModel } = props;
    const { dashboardModel } = useDashboardPageContext();
    const params = useParams<DashboardRouteParams>();
    const { dashboardId } = params;
    const { load, silentLoad } = dashboardModel;
    const { locale } = useIntl();

    useEffect(() => {
        if (!dashboardId) {
            return;
        }
        load();
    }, [load, dashboardId]);

    useEffect(() => {
        !dashboardModel.isLoading && silentLoad();
    }, [locale]);

    return (
        <Grid container direction="column" flexWrap="nowrap" sx={sxStyles.mainWrapper}>
            <Grid item direction="column" flexWrap="nowrap" sx={sxStyles.tabsWithHeaderContainer}>
                <Grid item sx={sxStyles.tabsContainer}>
                    <MyDashboardsPageTabs model={tabsModel} />
                </Grid>
                <Grid item sx={sxStyles.headerContainer}>
                    <MyDashboardPageHeader />
                </Grid>
            </Grid>
            <Grid item container direction="column" flexWrap="nowrap" sx={sxStyles.mainContentWrapper}>
                <Grid item sx={sxStyles.mainContent} id="main-content">
                    <DashboardPageContent />
                </Grid>
            </Grid>
        </Grid>
    );
});
