import {
    WidgetDTOWithOptionalWidgetAttributes,
    WidgetType,
    CounterWidgetDTO,
    CounterWidgetSpecificAttributes,
} from '../../types';

const objContainingAllRequiredCounterWidgetAttributes: Record<keyof CounterWidgetSpecificAttributes, undefined> = {
    count: undefined,
    description: undefined,
    url: undefined,
};

/**
 * Функция type predicate для определения что в модели виджета содержатся данные виджета-счетчика
 * У нас изначально WidgetModel создается на основе {@link WidgetBaseDataDTO}, которая
 * НЕ содержит специфичные для конкретного виджета данные (они подгружаются в самой модели отдельным запросом)
 * Поэтому когда мы рендерим компонент виджета-счетчика нам нужно убедиться что в модели
 * есть необходимые для его отображения данные
 */
export const isWidgetTypeCounter = (widget: WidgetDTOWithOptionalWidgetAttributes): widget is CounterWidgetDTO => {
    const { widgetType, widgetAttributes } = widget;

    if (widgetAttributes) {
        const isWidgetAttributesContainsWidgetTypeCounterData = Object.keys(
            objContainingAllRequiredCounterWidgetAttributes,
        ).every((key) => key in widgetAttributes);
        return widgetType === WidgetType.counter && isWidgetAttributesContainsWidgetTypeCounterData;
    }
    return false;
};
