import { CodeTitleNull } from '@platform/front-utils';
import {
    CounterWidgetCreateEditFormSettingsFields,
    LinkWidgetCreateEditFormSettingsFields,
    WidgetCreateEditFormCommonFields,
    WidgetType,
} from '../../../types';

const counterWidgetServerValidationFields = Object.values({
    ...WidgetCreateEditFormCommonFields,
    ...CounterWidgetCreateEditFormSettingsFields,
});

const linkWidgetServerValidationFields = Object.values({
    ...WidgetCreateEditFormCommonFields,
    ...LinkWidgetCreateEditFormSettingsFields,
});

/**
 * Функция, генерирующая массив полей для серверной валидации значений формы создания/редактирования виджета
 * @param {CodeTitleNull} widgetType - данные типа виджета
 */
export const getWidgetCreateUpdateFieldsForServerValidation = (widgetType: CodeTitleNull): string[] | undefined => {
    const widgetTypeCode = widgetType ? widgetType.code : undefined;
    if (widgetTypeCode) {
        switch (widgetTypeCode) {
            case WidgetType.counter: {
                return counterWidgetServerValidationFields;
            }
            case WidgetType.link: {
                return linkWidgetServerValidationFields;
            }
        }
    }
};
