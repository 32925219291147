import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { Field, FormikValues, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { backgroundColorOrderedPalette, defaultWidgetBackgroundColor } from '../../../../../../../../constants';
import { WidgetBackgroundColorViewSetting, WidgetCreateEditFormCommonFields } from '../../../../../../../../types';
import { getWidgetColorItem as getWidgetColorItemInj } from './getWidgetColorItem';

const sxStyles = makeSxStyles({
    paletteMenu: {
        py: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gridTemplateRows: '1fr',
        gridAutoRows: '1fr',
        gap: '8px 16px',
        gridAutoFlow: 'row',
    },
});

export const WidgetColorsField = observer((): JSX.Element => {
    const [getWidgetColorItem] = di([getWidgetColorItemInj], WidgetColorsField);

    const { setFieldValue, values } = useFormikContext<FormikValues>();
    const currentBackgroundColor: WidgetBackgroundColorViewSetting = values.backgroundColor;

    const otherWidgetColors: JSX.Element[] = backgroundColorOrderedPalette.map((colorSetting) =>
        getWidgetColorItem(colorSetting, currentBackgroundColor),
    );

    const whiteWidgetColor: JSX.Element = getWidgetColorItem(defaultWidgetBackgroundColor, currentBackgroundColor);

    const onChange = (event: React.SyntheticEvent, value: string): void => {
        setFieldValue(WidgetCreateEditFormCommonFields.backgroundColor, value);
    };

    return (
        <Field
            component={RadioGroup}
            fieldName={WidgetCreateEditFormCommonFields.backgroundColor}
            value={currentBackgroundColor}
            onChange={onChange}
        >
            <Grid item container spacing={2} paddingX={1}>
                <Grid item>{whiteWidgetColor}</Grid>
                <Grid item sx={sxStyles.paletteMenu}>
                    {otherWidgetColors}
                </Grid>
            </Grid>
        </Field>
    );
});
