import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import {
    SectionCreateDialog as SectionCreateDialogInj,
    SectionEditDialog as SectionEditDialogInj,
    SectionList as SectionListInj,
} from '../section-components';
import { DashboardPageContentSkeleton as DashboardPageContentSkeletonInj } from './DashboardPageContentSkeleton';
import { DashboardPageNoSections as DashboardPageNoSectionsInj } from './DashboardPageNoSections';
import { DashboardPagesRoutesFiltering } from '../../../../../types';

export const DashboardPageContent = observer((): JSX.Element => {
    const [DashboardPageNoSections] = di([DashboardPageNoSectionsInj], DashboardPageContent);
    const [DashboardPageContentSkeleton] = di([DashboardPageContentSkeletonInj], DashboardPageContent);
    const [SectionCreateDialog] = di([SectionCreateDialogInj], DashboardPageContent);
    const [SectionEditDialog] = di([SectionEditDialogInj], DashboardPageContent);
    const [SectionList] = di([SectionListInj], DashboardPageContent);

    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id: dashboardId, isLoading, sections } = dashboardModel;
    const { dashboardConfig } = useStore().coreRootStore.permissionsStore as PermissionsStore;
    const createSectionQuery = dashboardConfig.createSection(dashboardId);

    const redirectComponent = dashboardId && <Redirect to={generatePath(routes.dashboard, { dashboardId })} />;
    const pageContentRoutes: string[] = routes.getRoutesByFilter(DashboardPagesRoutesFiltering.singleDashboard);

    return (
        <Switch>
            <Route path={pageContentRoutes} exact>
                <Route path={routes.dashboardCreateSection} exact>
                    <AuthorizationCheck {...createSectionQuery}>
                        {(allowed) => (allowed ? <SectionCreateDialog /> : redirectComponent)}
                    </AuthorizationCheck>
                </Route>
                <Route path={routes.dashboardEditSection}>
                    <SectionEditDialog />
                </Route>
                {isLoading ? (
                    <DashboardPageContentSkeleton />
                ) : sections.length ? (
                    <SectionList />
                ) : (
                    <DashboardPageNoSections />
                )}
            </Route>
            <Route>{redirectComponent}</Route>
        </Switch>
    );
});
