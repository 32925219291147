import { titlesDTOtoTitles, titlesToTitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { RootStore } from '../../stores';
import { DashboardRoutes, SectionCreateEditFormValues, SectionCreateUpdateDTO } from '../../types';
import {
    SectionCreateEditDialogModel,
    sectionCreateEditDialogModelAbstractObservables,
} from './SectionCreateEditDialogModel';

export const sectionEditDialogModelObservables = {
    ...sectionCreateEditDialogModelAbstractObservables,
    sectionId: observable,
    reloadSectionModel: action.bound,
    loadSectionInfo: action.bound,
    setFormInitialValuesFromUpdateInfo: action.bound,
};

/**
 * Класс, содержащий данные и логику для диалога редактирования раздела дашборда
 */
export class SectionEditDialogModel extends SectionCreateEditDialogModel {
    sectionId: string;
    reloadSectionModel: VoidFunction;

    constructor(
        dashboardId: string,
        sectionId: string,
        rootStore: RootStore,
        reloadSectionModel: VoidFunction,
        routes: DashboardRoutes,
    ) {
        super(dashboardId, rootStore, routes);
        this.sectionId = sectionId;
        this.reloadSectionModel = reloadSectionModel;
        makeObservable(this, sectionEditDialogModelObservables);
    }

    loadSectionInfo(): void {
        this.setIsFormInitialValuesLoading(true);
        this.dashboardStore
            .getSectionUpdateInfo(this.sectionId)
            .then(this.setFormInitialValuesFromUpdateInfo)
            .finally(() => this.setIsFormInitialValuesLoading(false));
    }

    setFormInitialValuesFromUpdateInfo(dto: SectionCreateUpdateDTO): void {
        const formValues: SectionCreateEditFormValues = {
            ...dto,
            titles: titlesDTOtoTitles(dto.titles),
        };
        this.setFormInitialValues(formValues);
    }

    onSubmit(formValues: SectionCreateEditFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const { titles, roles } = formValues;

        const dto: SectionCreateUpdateDTO = {
            titles: titlesToTitlesDTO(titles),
            roles,
        };

        return this.dashboardStore
            .updateSection(this.sectionId, dto)
            .then(() => {
                this.dashboardId &&
                    this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
                this.reloadSectionModel();
            })
            .catch(this.serverErrors.setServerFormErrors);
    }
}
