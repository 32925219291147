import { PromiseVoidFunction } from '@platform/front-utils';
import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { RootStore } from '../../stores';
import { DashboardRoutes, SectionCreateEditFormValues, SectionCreateUpdateDTO } from '../../types';
import {
    SectionCreateEditDialogModel,
    sectionCreateEditDialogModelAbstractObservables,
} from './SectionCreateEditDialogModel';

export const sectionCreateModelObservables = {
    ...sectionCreateEditDialogModelAbstractObservables,

    reloadDashboardData: action.bound,
};

/**
 * Класс, содержащий данные и логику для диалога создания раздела дашборда
 */
export class SectionCreateDialogModel extends SectionCreateEditDialogModel {
    reloadDashboardData: (shouldScrollAfterSectionCreate?: boolean) => Promise<void>;

    constructor(
        dashboardId: string,
        rootStore: RootStore,
        reloadDashboardData: PromiseVoidFunction,
        routes: DashboardRoutes,
    ) {
        super(dashboardId, rootStore, routes);
        this.reloadDashboardData = reloadDashboardData;
        makeObservable(this, sectionCreateModelObservables);
    }

    onSubmit(formValues: SectionCreateEditFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const { titles, roles } = formValues;

        const dto: SectionCreateUpdateDTO = {
            titles: titlesToTitlesDTO(titles),
            roles,
        };

        return this.dashboardStore
            .createSection(this.dashboardId, dto)
            .then(() => {
                this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
                this.reloadDashboardData(true);
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    onCancel(): void {
        this.dashboardId && this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
    }
}
