import { LocalesTree, ObjectLocales } from '@platform/front-utils';

export const section: LocalesTree & ObjectLocales = {
    phrases: {
        add: 'Добавить {section}',
        deleteDialogText: '{section} будет удалён вместе со своими виджетами',
        deleteDialogTitle: 'Удалить {section}',
        editing: 'Редактирование {sectionGenitive}',
        new: 'Новый {sectionNominative}',
        titlesPlaceholder: 'Название {sectionGenitive}',
    },
    plural: {
        genitive: 'разделов',
        nominative: 'разделы',
    },
    single: {
        accusative: 'раздел',
        genitive: 'раздела',
        nominative: 'раздел',
    },
};
