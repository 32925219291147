import ReportIcon from '@mui/icons-material/Report';
import { Grid, Link, Typography } from '@mui/material';
import { AuthorizationCheck, useLocale } from '@platform/front-core';
import { blue, grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useHistory } from 'react-router-dom';
import { dashboardMessages } from '../../../customization';
import { useDashboardsAuxContext, useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores/permissions-store/PermissionsStore';
import { DashboardCreateDialog as DashboardCreateDialogInj } from '../../dashboards';

const sxStyles = makeSxStyles({
    reportIcon: {
        color: blue[700],
        width: '40px',
        height: '40px',
    },
    subtitle: {
        maxWidth: '453px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        color: grey[500],
    },
    link: {
        color: blue[600],
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        pt: 3.75,
    },
});

export const MyDashboardsEmptyList = observer((): JSX.Element => {
    const [DashboardCreateDialog] = di([DashboardCreateDialogInj], MyDashboardsEmptyList);
    const history = useHistory();
    const noItemsTitle: string = useLocale('dashboard.phrases.noMyDashboards', true, {
        dashboardPluralGenitive: useLocale('dashboard.plural.genitive', false)[0],
    })[0];
    const noMyDashboardsSubtitle = useLocale('dashboard.phrases.noMyDashboardsSubtitle', true, {
        dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
    })[0];

    const rootStore = useStore();
    const { intlStore } = rootStore.coreRootStore;
    const { formatMessageFromDefineMessage } = intlStore;
    const { routes } = useDashboardsAuxContext();

    const createDashboardButton = formatMessageFromDefineMessage(dashboardMessages.createDashboard);

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const createDashboardQuery = systemConfig.createDashboard();

    const createDashboard = (): void => {
        history.push(generatePath(routes.dashboardCreate));
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
            <Grid item>
                <ReportIcon sx={sxStyles.reportIcon} />
            </Grid>
            <Grid item container pt={3} direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h4" fontSize={16}>
                    {noItemsTitle}
                </Typography>
                <Typography sx={sxStyles.subtitle} textAlign="center">
                    {noMyDashboardsSubtitle}
                </Typography>
                <AuthorizationCheck {...createDashboardQuery}>
                    <Route path={routes.dashboardCreate} exact>
                        <DashboardCreateDialog />
                    </Route>
                    <Link component="button" onClick={createDashboard} sx={sxStyles.link}>
                        {createDashboardButton}
                    </Link>
                </AuthorizationCheck>
            </Grid>
        </Grid>
    );
});
