import { PermissionsStore as PermissionsStoreCore } from '@platform/front-core';
import { di } from 'react-magnetic-di';
import { PermissionEntities, Permissions } from '../../types';
import { entities, permissions } from './EntitiesPermissions';
import {
    AccountPermissions,
    DashboardPermissions,
    getAccountPermissionsConfig,
    getDashboardPermissionsConfig,
    getSectionPermissionsConfig,
    getSystemPermissionsConfig,
    SectionPermissions,
    SystemPermissions,
} from './PermissionsConfigs';

export class PermissionsStore extends PermissionsStoreCore {
    get entities(): PermissionEntities {
        return entities;
    }

    get permissions(): Permissions {
        return permissions;
    }

    get systemConfig(): SystemPermissions {
        return getSystemPermissionsConfig(this.entities, this.permissions);
    }

    get accountConfig(): AccountPermissions {
        return getAccountPermissionsConfig(this.entities, this.permissions);
    }

    get dashboardConfig(): DashboardPermissions {
        return getDashboardPermissionsConfig(this.entities, this.permissions);
    }

    get sectionConfig(): SectionPermissions {
        return getSectionPermissionsConfig(this.entities, this.permissions);
    }
}

export const getPermissionsStore = (): any => {
    const [_PermissionsStore] = di([PermissionsStore], getPermissionsStore);
    return _PermissionsStore;
};
