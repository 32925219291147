import { LocalesTree, ObjectLocales } from '@platform/front-utils';

export const dashboard: ObjectLocales & LocalesTree = {
    binding: 'Привязка',
    makeInitial: 'Сделать стартовым',
    name: 'Название',
    phrases: {
        create: 'Создание {dashboardGenitive}',
        createDashboard: 'Создать {dashboardNominative}',
        delete: 'Удалить {dashboardNominative}',
        deleteDialogText: '{dashboardNominative} будет удалён вместе со своими разделами и виджетами',
        draftDashboardCannotBeInitial: '{dashboardNominative} в состоянии Draft не может быть стартовым',
        edit: 'Изменение {dashboardGenitive}',
        makeInitialCreateDashboardInfo:
            'Вкладка {dashboardGenitive} на стартовой странице будет закреплена слева. Стартовый {dashboardNominative} всегда один - если другой {dashboardNominative} был стартовым, то после сохранения стартовым станет этот.',
        myDashboards: 'Мои {dashboardPluralNominative}',
        noMyDashboards: 'Нет {dashboardPluralGenitive}',
        noMyDashboardsSubtitle:
            'Создайте свой первый {dashboardNominative} или перейдите в другой модуль из меню навигации вверху слева',
        publishAsDraftHelper: '{dashboardNominative} в состоянии Draft не виден пользователям.',
        registryTitle: 'Реестр {dashboardPluralGenitive}',
        titlesPlaceholder: 'Название {dashboardGenitive}',
    },
    plural: {
        genitive: 'дашбордов',
        nominative: 'дашборды',
    },
    publishAsDraft: 'Опубликовать как черновик',
    single: {
        genitive: 'дашборда',
        nominative: 'дашборд',
    },
};
