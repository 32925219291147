import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useDashboardsAuxContext, useStore } from '../../../../hooks';
import { DashboardCreateDialogModel } from '../../../../models';
import { DashboardCreateEditDialog as DashboardCreateEditDialogInj } from '../../dashboard-create-edit-dialog';

export const DashboardCreateDialog = observer((): JSX.Element => {
    const [DashboardCreateEditDialog] = di([DashboardCreateEditDialogInj], DashboardCreateDialog);

    const rootStore = useStore();
    const { routes } = useDashboardsAuxContext();
    const dashboardCreateDialogModel = useMemo(
        () => new DashboardCreateDialogModel(rootStore, routes),
        [rootStore, routes],
    );

    return (
        <DashboardCreateEditDialog
            dashboardCreateOrEditDialogModel={dashboardCreateDialogModel}
            isCreateDashboard={true}
        />
    );
});
