import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../../hooks';
import { SectionCreateDialogModel } from '../../../../../../models';
import { SectionCreateEditDialog as SectionCreateEditDialogInj } from './SectionCreateEditDialog';

export const SectionCreateDialog = observer((): JSX.Element => {
    const [SectionCreateEditDialog] = di([SectionCreateEditDialogInj], SectionCreateDialog);

    const rootStore = useStore();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id, load } = dashboardModel;

    const sectionCreateDialogModel = useMemo(
        () => new SectionCreateDialogModel(id, rootStore, load, routes),
        [id, rootStore, dashboardModel, routes],
    );

    return <SectionCreateEditDialog sectionCreateOrEditDialogModel={sectionCreateDialogModel} />;
});
