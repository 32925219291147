import { TotLocale } from '@platform/front-types';
import headerLogoEn from './resources/images/INKK-header-eng.png';
import headerLogoRu from './resources/images/INKK-header-rus.png';
import { PSFeatures } from '../../types';

const getHeaderLogoLangConfig = (lang: string) => ({
    alt: lang === 'ru' ? 'ИНКК логотип' : 'INKK logo',
    image: lang === 'ru' ? headerLogoRu : headerLogoEn,
    style: {
        height: '44px',
    },
});

const features: PSFeatures = {
    customLogo: {
        isEnabled: true,
        locales: {
            ru: { src: headerLogoRu, alt: 'headerLogoRu' },
            en: { src: headerLogoEn, alt: 'headerLogoEn' },
        },
    },
    footerLogo: false,
    globalSearch: true,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: getHeaderLogoLangConfig(TotLocale.en),
            ru: getHeaderLogoLangConfig(TotLocale.ru),
        },
    },
    helpIcon: false,
    modularNavigation: true,
    phoneNumber: false,
};

export default features;
