/**
 * Данные для модалки создания/редактирования виджета типа 'counter' приходят в примерном виде
 * ```
 * {
    registries: {...otherFields: registryModuleCode: string, registryCode: string; }[];
    presets: Partial<Record<`registryModuleCode-registryCode`, IdTitle[]>>;
};
 * ```
 * Функция вычисляет ключ `registryModuleCode-registryCode` для объекта presets
 */
export const getRegistryIdentifier = (registryModuleCode: string, registryCode: string): string => {
    return `${registryModuleCode}-${registryCode}`;
};
