import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { blue, hoverSelector, makeSxStylesWithProps } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import {
    StylesForCounterOrLinkWidgetFontSizeSettingType,
    stylesForCounterWidgetFontSizeSetting,
    stylesForLinkWidgetFontSizeSetting,
} from '../../../../../../../../constants';
import { WidgetFontSizeViewSetting, WidgetType } from '../../../../../../../../types';

type GetFontSizeBoxSxStylesProps = {
    selected: boolean;
};

const getFontSizeBoxSxStyles = makeSxStylesWithProps((props: GetFontSizeBoxSxStylesProps) => {
    const { selected } = props;
    return {
        container: {
            mr: 1,
            height: '32px',
            width: '32px',
            userSelect: 'none',
            borderRadius: '4px',
            border: selected ? `2px solid ${blue[600]}` : 'none',
            [hoverSelector]: {
                border: `2px solid ${blue[500]}`,
            },
            cursor: 'pointer',
        },
    };
});

export type FontButtonMenuOptionButtonProps = {
    selected: boolean;
    fontSizeOption: WidgetFontSizeViewSetting;
    widgetType: WidgetType;
    onClick?: VoidFunction;
};

export const FontButtonMenuOption = observer((props: FontButtonMenuOptionButtonProps): JSX.Element => {
    const { onClick, fontSizeOption, selected, widgetType } = props;
    const { formatMessage } = useIntl();
    const tooltipMessage = formatMessage({ id: `widget.widgetFontSize.${fontSizeOption}` });
    const text = 'Aa';

    const sxStyles = getFontSizeBoxSxStyles({ selected });

    let stylesObject: StylesForCounterOrLinkWidgetFontSizeSettingType;
    switch (widgetType) {
        case WidgetType.link: {
            stylesObject = stylesForLinkWidgetFontSizeSetting;
            break;
        }
        case WidgetType.counter:
        default: {
            stylesObject = stylesForCounterWidgetFontSizeSetting;
            break;
        }
    }

    return (
        <Tooltip placement="bottom" title={tooltipMessage} followCursor>
            <Grid
                container
                item
                onClick={onClick}
                role="menuitem"
                tabIndex={0}
                alignItems="center"
                justifyContent="center"
                sx={sxStyles.container}
            >
                <Box>
                    <Typography sx={stylesObject[fontSizeOption].title}>{text}</Typography>
                </Box>
            </Grid>
        </Tooltip>
    );
});
