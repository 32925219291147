import { GlobalSearchStore as CoreGlobalSearchStore, ICoreRootStore } from '@platform/front-core';
import { apiConfigs, SearchQueryData, SearchResultDTO } from '@platform/global-search';
import { makeObservable, override } from 'mobx';
import { di } from 'react-magnetic-di';

export const globalSearchStoreObservables = {
    loadSearchResults: override,
};

/** Временный стор для управления новой версией глобального поиска */
export class GlobalSearchStore extends CoreGlobalSearchStore {
    constructor(coreRootStore: ICoreRootStore) {
        super(coreRootStore, undefined);
        makeObservable(this, globalSearchStoreObservables);
    }

    loadSearchResults(queryData: SearchQueryData): Promise<SearchResultDTO> {
        // Используем apiConfigs из @platform/global-search т.к в новой версии
        // фильтры передаются в поле filters
        return this.api
            .userActionClient({
                ...apiConfigs.loadSearchResults(queryData),
                signal: this.abortController.signal,
            })
            .then((r) => r.data);
    }
}

export const getGlobalSearchStore = (): any => {
    const [_GlobalSearchStore] = di([GlobalSearchStore], getGlobalSearchStore);
    return _GlobalSearchStore;
};
