import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute, getDashboardsRoutes } from '../../clientRoute';
import { DashboardsAuxContext, DashboardsAuxContextType } from '../../contexts';
import { DashboardPageParentRouteName, DashboardRoutesWithAuxHandlers } from '../../types';
import { DashboardPage as DashboardPageInj } from './dashboard-page';
import { DashboardRegistry as DashboardRegistryInj } from './dashboard-registry/DashboardRegistry';

const dashboardRoutes: DashboardRoutesWithAuxHandlers = getDashboardsRoutes(DashboardPageParentRouteName.allDashboards);
const dashboardPageRoutes: string[] = dashboardRoutes.asArray();

export const DashboardPages = (): JSX.Element => {
    const [DashboardRegistry] = di([DashboardRegistryInj], DashboardPages);
    const [DashboardPage] = di([DashboardPageInj], DashboardPages);

    const dashboardsAuxContextValue: DashboardsAuxContextType = {
        routes: dashboardRoutes,
    };

    return (
        <DashboardsAuxContext.Provider value={dashboardsAuxContextValue}>
            <Switch>
                <Route path={[clientRoute.allDashboards.dashboards, clientRoute.allDashboards.dashboardCreate]} exact>
                    <DashboardRegistry />
                </Route>
                <Route path={dashboardPageRoutes}>
                    <DashboardPage />
                </Route>
            </Switch>
        </DashboardsAuxContext.Provider>
    );
};
