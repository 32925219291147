import {
    DashboardPageParentRouteName,
    DashboardPagesRoutesFiltering,
    DashboardRoutes,
    DashboardRoutesWithAuxHandlers,
} from './types';

/**
 * Генерирует объект с роутами под нужды страниц дашбордов, интерполируя
 * *parentRoute* в возвращаемый объект с роутами. Например для стр. "Мои дашборды",
 * подставит в первый сегмент роутов переданную в parentRoute строку.
 * Также содержит некоторые вспомогательные методы для работы с этим объектом
 * @param {DashboardPageParentRouteName} parentRoute Сегмент роута для интерполяции в конфиг
 * @example
 * const routes = getDashboardsRoutes(DashboardPageNameTokens.myDashboards);
 * console.log(routes)
 * // > {
 * // >  dashboard: /my-dashboard/:dashboardId
 * // >  ...
 * // > }
 * generatePath(routes.dashboards)
 */
export const getDashboardsRoutes = (
    parentRoute: DashboardPageParentRouteName,
    redirectAfterDeletePath?: string,
): DashboardRoutesWithAuxHandlers => {
    const routes: DashboardRoutes = {
        dashboard: `/${parentRoute}/:dashboardId`,
        dashboards: `/${parentRoute}/`,
        dashboardCreate: `/${parentRoute}/create`,
        myDashboardsCreate: `/${parentRoute}/:dashboardId/create`,
        dashboardEdit: `/${parentRoute}/:dashboardId/edit`,
        dashboardCreateSection: `/${parentRoute}/:dashboardId/createSection`,
        dashboardEditSection: `/${parentRoute}/:dashboardId/editSection/:sectionId`,
        dashboardSectionCreateWidget: `/${parentRoute}/:dashboardId/section/:sectionId/createWidget`,
        dashboardSectionEditWidget: `/${parentRoute}/:dashboardId/section/:sectionId/editWidget/:widgetId`,
        dashboardRedirectAfterDelete: redirectAfterDeletePath || `/${parentRoute}/`,
    };

    return {
        ...routes,
        asArray: () => Object.values(routes),
        getRoutesByFilter: (type: DashboardPagesRoutesFiltering): string[] => {
            if (type === DashboardPagesRoutesFiltering.singleDashboard) {
                return [
                    'dashboard',
                    'dashboardEdit',
                    'dashboardEditSection',
                    'dashboardCreateSection',
                    'dashboardSectionCreateWidget',
                    'dashboardSectionEditWidget',
                    'myDashboardsCreate',
                ].map((key) => routes[key as keyof DashboardRoutes]);
            }
            return Object.values(routes);
        },
    };
};

class ClientRoute {
    root = '/';

    myDashboards = getDashboardsRoutes(DashboardPageParentRouteName.myDashboards);
    allDashboards = getDashboardsRoutes(DashboardPageParentRouteName.allDashboards);
}

export const clientRoute = new ClientRoute();
