import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { WidgetModel } from '../../../../../../../models';
import { WidgetType } from '../../../../../../../types';
import { CounterWidgetErrorComponent as CounterWidgetErrorComponentInj } from './counter-widget';

export type WidgetErrorComponentProps = {
    widgetModel: WidgetModel;
};

export const WidgetErrorComponent = observer((props: WidgetErrorComponentProps): JSX.Element => {
    const [CounterWidgetErrorComponent] = di([CounterWidgetErrorComponentInj], WidgetErrorComponent);
    const { widgetModel } = props;

    switch (widgetModel.widgetType) {
        case WidgetType.counter: {
            return <CounterWidgetErrorComponent widgetModel={widgetModel} />;
        }
        default: {
            return <React.Fragment />;
        }
    }
});
