import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import { makeObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { RootStore } from '../../stores';
import { DashboardCreateFormValues, DashboardCreateUpdateDTO, DashboardRoutes } from '../../types';
import {
    DashboardCreateEditDialogModel,
    dashboardCreateEditDialogModelAbstractObservables,
} from './DashboardCreateEditDialogModel';

export const dashboardCreateDialogModelObservables = {
    ...dashboardCreateEditDialogModelAbstractObservables,
};

/**
 * Класс, содержащий данные и логику для диалога создания дашборда
 */
export class DashboardCreateDialogModel extends DashboardCreateEditDialogModel {
    constructor(rootStore: RootStore, routes: DashboardRoutes) {
        super(rootStore, routes);
        makeObservable(this, dashboardCreateDialogModelObservables);
    }

    onSubmit(formValues: DashboardCreateFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const { titles, roles, publishAsDraft, isInitial, iconCode } = formValues;

        const dto: DashboardCreateUpdateDTO = {
            titles: titlesToTitlesDTO(titles),
            roles,
            publishAsDraft,
            isInitial,
            iconCode,
        };

        return this.dashboardStore
            .createDashboard(dto)
            .then((dashboardId) => {
                if (publishAsDraft) {
                    this.history.push(generatePath(clientRoute.allDashboards.dashboard, { dashboardId }));
                    return;
                }
                this.history.push(generatePath(this.routes.dashboard, { dashboardId }));
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    onCancel(): void {
        this.history.push(this.routes.dashboards);
    }
}
