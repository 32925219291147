import { activeSelector, blue, grey, hoverSelector, makeSxStylesWithProps } from '@platform/front-ui';
import { highlightDuration, newWidgetOrSectionHighlightAnimation } from '../../../../../constants';

export type GetSectionListItemSxTylesProps = {
    isWithDragging: boolean;
    isWithoutWidgets: boolean;
};

export const getSectionListItemSxStyles = makeSxStylesWithProps((props: GetSectionListItemSxTylesProps) => {
    const { isWithDragging, isWithoutWidgets } = props;

    return {
        listItem: {
            mb: 2,
            pb: 2,
            backgroundColor: grey[100],
            minHeight: '160px',
            borderRadius: '8px',
            border: isWithDragging ? `1px solid ${blue[800]}` : 'none',
            ':hover': {
                '#dragIndicatorIcon, #actionsContainer, #actionsButton': {
                    visibility: 'visible',
                },
            },
        },
        sectionTitleHighlighted: {
            animation: `${highlightDuration}ms linear ${newWidgetOrSectionHighlightAnimation}`,
        },
        sectionTitleWithActions: {
            backgroundColor: grey[100],
            borderRadius: '8px 8px 0px 0px',
            border: '1px solid transparent',
            minHeight: (theme) => theme.spacing(6.25),
        },
        title: {
            fontSize: '16px',
            fontWeight: 600,
            wordBreak: 'break-word',
        },
        titleWrapper: {
            flex: '1 1 100%',
            pr: 1,
            pb: 1,
            pt: 2,
            position: 'relative',
        },
        dragIconWrapper: {
            pt: '2px',
            px: 0.5,
            '.MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
            },
            color: isWithDragging ? blue[800] : grey[400],
            [hoverSelector]: {
                color: blue[700],
            },
            [activeSelector]: {
                color: blue[800],
            },
            visibility: isWithDragging ? 'visible' : 'hidden',
        },
        iconWrapper: {
            width: '16px',
            height: '16px',
        },
        actionsContainer: {
            flex: '0 0 content',
            visibility: isWithDragging ? 'visible' : 'hidden',
            '#actionsButton': {
                visibility: isWithoutWidgets || isWithDragging ? 'visible' : 'hidden',
            },
            pr: 3,
            pt: 1,
        },
        dashedBorderCreateWidgetButton: {
            height: '160px',
            width: '320px',
        },
        button: {
            backgroundColor: grey[100],
            [hoverSelector]: {
                backgroundColor: grey[100],
                color: blue[700],
            },
            [activeSelector]: {
                backgroundColor: grey[100],
                color: blue[800],
            },
        },
        icon: {
            color: blue[800],
            width: '24px',
            height: '24px',
        },
    };
});
