import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import {
    CounterWidgetCreateUpdateDTO,
    CounterWidgetCreateUpdateSettings,
    LinkWidgetCreateUpdateDTO,
    WidgetCreateEditFormValues,
    WidgetCreateUpdateDTO,
    WidgetType,
} from '../../../types';

/**
 * Функция трансформирует данные формы создания/редактирования виджета в dto для отправки на бэк
 */
export const getWidgetCreateUpdateDTOFromFormValues = (
    formValues: WidgetCreateEditFormValues,
): WidgetCreateUpdateDTO | undefined => {
    const { titles, widgetType, settings, iconCode, backgroundColor } = formValues;

    const { registry, preset, descriptions, link } = settings;
    const widgetTypeCode = widgetType ? widgetType.code : undefined;

    if (widgetTypeCode) {
        switch (widgetTypeCode) {
            case WidgetType.counter: {
                if (preset && registry) {
                    const { code, group, apiUrlPrefix, linkUrl } = registry;
                    const registryValuesForDTO: CounterWidgetCreateUpdateSettings['registry'] = {
                        moduleCode: group,
                        code,
                        apiUrlPrefix,
                        linkUrl,
                    };
                    const counterWidgetCreateUpdateDTO: CounterWidgetCreateUpdateDTO = {
                        titles: titlesToTitlesDTO(titles),
                        widgetType: WidgetType.counter,
                        settings: {
                            descriptions: titlesToTitlesDTO(descriptions),
                            registry: registryValuesForDTO,
                            presetId: preset.id,
                        },
                        iconCode,
                        viewSettings: { backgroundColor },
                    };

                    return counterWidgetCreateUpdateDTO;
                }
                break;
            }
            case WidgetType.link: {
                const linkWidgetCreateUpdateDTO: LinkWidgetCreateUpdateDTO = {
                    titles: titlesToTitlesDTO(titles),
                    widgetType: WidgetType.link,
                    settings: {
                        descriptions: titlesToTitlesDTO(descriptions),
                        link,
                    },
                    iconCode,
                    viewSettings: { backgroundColor },
                };

                return linkWidgetCreateUpdateDTO;
            }
        }
    }
};
