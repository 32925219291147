import { entities as entitiesCore, permissions as corePermissions } from '@platform/front-core';

export const entities = {
    ...entitiesCore,
    dashboard: 'Dashboard',
    section: 'Section',
    widget: 'Widget',
};

export const permissions = {
    ...corePermissions,
    system: {
        ...corePermissions.system,
        ViewDashboardRegistry: 'ViewDashboardRegistry',
        ViewDashboardList: 'ViewDashboardList',
        CreateDashboard: 'CreateDashboard',
        CreatePublicDashboard: 'CreatePublicDashboard',
        UpdateDashboardPositions: 'UpdateDashboardPositions',
    },
    dashboard: {
        View: 'View',
        Update: 'Update',
        Delete: 'Delete',
        CreateSection: 'CreateSection',
    },
    section: {
        View: 'View',
        Delete: 'Delete',
        Update: 'Update',
        CreateWidget: 'CreateWidget',
        UpdateWidget: 'UpdateWidget',
        DeleteWidget: 'DeleteWidget',
    },
};
