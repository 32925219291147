import { Skeleton } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import React from 'react';

/**
 * @prop {React.ReactNode} component - Компонент, который будет обернут в скелетон
 * @prop {boolean} isLoading - Условие, при котором отображается компонент, обернутый в скелетон
 * @prop {boolean[]} additionalConditions - Дополнительные условия, которые проверяются после прошествия первого условия
 * @prop {SxStyle} skeletonSx - Дополнительные стили для скелетона
 */
export type SkeletifyProps = {
    component: React.ReactNode;
    isLoading: boolean;
    additionalConditions?: boolean[];
    skeletonSx?: SxStyle;
};

/**
 * Скелетифаер! Оборачивает компоненты в скелетон для отображения загрузки.
 * После прошествия загрузки возвращает исходный компонент, не обернутый в скелетон.
 * @param props {@link SkeletifyProps}
 */
export const skeletify = (props: SkeletifyProps): React.ReactNode => {
    const { component, additionalConditions = [], isLoading, skeletonSx = {} } = props;
    return (
        <React.Fragment>
            {isLoading ? (
                <Skeleton sx={skeletonSx}>{component}</Skeleton>
            ) : (
                additionalConditions.every((condition) => condition === true) && component
            )}
        </React.Fragment>
    );
};
