import { Box } from '@mui/material';
import {
    activeSelector,
    blue,
    focusSelector,
    grey,
    hoverSelector,
    makeSxStylesWithProps,
    mergeSxStyles,
} from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { HTMLProps, PropsWithChildren, useState } from 'react';
import { di } from 'react-magnetic-di';
import {
    controlsResizeBtn,
    highlightDuration,
    newWidgetOrSectionHighlightAnimation,
    widgetControlsContainerClassName,
} from '../../../../../../../constants';
import { WidgetModel } from '../../../../../../../models';
import { WidgetPermissions } from '../../../../../../../types';
import { WidgetsGridItemActions as WidgetsGridItemActionsInj } from './WidgetsGridItemActions';
import { WidgetsGridItemContent as WidgetsGridItemContentInj } from './WidgetsGridItemContent';

type GetWidgetsGridSxStylesProps = {
    isWidgetResizing: boolean;
    shouldDisplayWidgetActions: boolean;
};

const getWidgetsGridItemSxStyles = makeSxStylesWithProps((props: GetWidgetsGridSxStylesProps) => {
    const { isWidgetResizing, shouldDisplayWidgetActions } = props;

    return {
        responsiveGridLayoutWrapper: {
            userSelect: 'none',
            width: (theme) => `calc(100% + ${theme.spacing(4)})`,
            marginLeft: (theme) => theme.spacing(-2),
            marginRight: (theme) => theme.spacing(-4),
        },
        widgetWrapper: {
            height: '100%',
            border: '1px solid transparent',
            borderRadius: '8px',
            [`.${widgetControlsContainerClassName}`]: {
                display: isWidgetResizing ? undefined : shouldDisplayWidgetActions ? 'block' : undefined,
            },
            [`&:hover .${widgetControlsContainerClassName}`]: {
                display: isWidgetResizing ? undefined : 'block',
            },
            [`.${controlsResizeBtn}`]: {
                color: grey[900],
                opacity: 0,
            },
            [activeSelector]: {
                borderColor: blue[700],
            },
            [hoverSelector]: {
                [`.${controlsResizeBtn}`]: {
                    opacity: 0.3,
                    [hoverSelector]: {
                        opacity: 0.6,
                    },
                    [activeSelector]: {
                        opacity: 1,
                    },
                },
            },
            [focusSelector]: {
                [`.${controlsResizeBtn}`]: {
                    opacity: 1,
                },
            },
        },
        highlightedWidgetWrapper: {
            animation: `${highlightDuration}ms linear ${newWidgetOrSectionHighlightAnimation}`,
        },
    };
});

type ReactGridLayoutCustomChildComponentProps = PropsWithChildren<
    Pick<HTMLProps<HTMLDivElement>, 'style' | 'className' | 'onMouseDown' | 'onMouseUp' | 'onTouchEnd'>
>;

export type WidgetsGridItemProps = ReactGridLayoutCustomChildComponentProps & {
    newWidgetId?: string;
    widgetIndexInLayout: string;
    shouldHighlightNewWidget: boolean;
    widgetModel?: WidgetModel;
    isWidgetResizing: boolean;
    newWidgetRef: React.MutableRefObject<HTMLDivElement | null>;
    widgetPermissions: WidgetPermissions;
};

export const WidgetsGridItem = observer(
    React.forwardRef<HTMLDivElement, WidgetsGridItemProps>((props, ref): JSX.Element => {
        const [WidgetsGridItemActions] = di([WidgetsGridItemActionsInj], WidgetsGridItem);
        const [WidgetsGridItemContent] = di([WidgetsGridItemContentInj], WidgetsGridItem);

        // Форвардинг этих пропов нужен для работы react-grid-layout
        const { style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...widgetDataRelatedProps } = props;
        const {
            newWidgetId,
            shouldHighlightNewWidget,
            widgetModel,
            isWidgetResizing,
            newWidgetRef,
            widgetPermissions,
        } = widgetDataRelatedProps;

        // При рендере поповеров-меню при нажатии на кнопки настроек отображения нужно
        // чтобы панель настроек (WidgetsGridItemActions) не исчезала, поэтому используется этот флаг
        // Если true, то панель отображается
        // Если нет, то зависит от того есть ли hover на виджете
        const [shouldDisplayWidgetActions, setShouldDisplayWidgetActions] = useState<boolean>(false);

        const isWidgetNew = widgetModel && widgetModel.id === newWidgetId;
        const setNewWidgetRef = (ref: HTMLDivElement | null): void => {
            if (isWidgetNew) {
                newWidgetRef.current = ref;
            }
        };

        const sxStyles = getWidgetsGridItemSxStyles({ isWidgetResizing, shouldDisplayWidgetActions });
        const widgetWrapperSx = mergeSxStyles(
            sxStyles.widgetWrapper,
            shouldHighlightNewWidget && isWidgetNew ? sxStyles.highlightedWidgetWrapper : {},
        );

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onTouchEnd={onTouchEnd}
            >
                <Box sx={widgetWrapperSx} ref={setNewWidgetRef}>
                    <WidgetsGridItemActions
                        widgetPermissions={widgetPermissions}
                        widgetModel={widgetModel}
                        setShouldDisplayWidgetActions={setShouldDisplayWidgetActions}
                    />
                    <WidgetsGridItemContent widgetModel={widgetModel} />
                    <Box className={controlsResizeBtn}>{children}</Box>
                </Box>
            </div>
        );
    }),
);
