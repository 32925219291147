import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../../hooks';
import { SectionModel } from '../../../../../../models';
import { SectionEditDialogModel } from '../../../../../../models/section/SectionEditDialogModel';
import { PermissionsStore } from '../../../../../../stores';
import { DashboardSectionRouteParams } from '../../../../../../types';
import { SectionCreateEditDialog as SectionCreateEditDialogInj } from './SectionCreateEditDialog';

export const SectionEditDialog = observer((): JSX.Element => {
    const [SectionCreateEditDialog] = di([SectionCreateEditDialogInj], SectionEditDialog);
    const { dashboardId, sectionId } = useParams<DashboardSectionRouteParams>();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { sections } = dashboardModel;
    const rootStore = useStore();

    const { sectionConfig } = rootStore.coreRootStore.permissionsStore as PermissionsStore;
    const updateSectionQuery = sectionConfig.update(sectionId);

    const sectionModel = useMemo<SectionModel | undefined>(() => {
        return sections.find((section) => section.id === sectionId);
    }, [sectionId]);

    const sectionEditDialogModel = useMemo<SectionEditDialogModel | undefined>(() => {
        return sectionModel
            ? new SectionEditDialogModel(dashboardId, sectionId, rootStore, sectionModel.load, routes)
            : undefined;
    }, [sectionModel, routes]);

    useEffect(() => {
        sectionEditDialogModel?.loadSectionInfo();
    }, [sectionEditDialogModel]);

    const redirectComponent = dashboardId && <Redirect to={generatePath(routes.dashboard, { dashboardId })} />;

    return (
        <React.Fragment>
            {sectionEditDialogModel && (
                <AuthorizationCheck {...updateSectionQuery}>
                    {(allowed) =>
                        allowed ? (
                            <SectionCreateEditDialog sectionCreateOrEditDialogModel={sectionEditDialogModel} />
                        ) : (
                            redirectComponent
                        )
                    }
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
