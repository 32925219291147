import { Box, Typography } from '@mui/material';
import { Loader, useLocale } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch, useParams } from 'react-router-dom';
import { myDashboardsRoutes } from '../../constants';
import {
    DashboardPageContext,
    DashboardPageContextType,
    DashboardsAuxContext,
    DashboardsAuxContextType,
} from '../../contexts';
import { useStore } from '../../hooks';
import { DashboardModel, DashboardTabsModel } from '../../models';
import { DashboardRouteParams } from '../../types';
import { MyDashboardsEmptyList as MyDashboardsEmptyListInj } from './components/MyDashboardsEmptyList';
import { MyDashboard as MyDashboardInj } from './MyDashboard';

const myDashboardsTitleSx: SxStyle = {
    pt: 4,
    pl: 2,
    pb: 2,
};

const myDashboardsWrapperSx: SxStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

export const MyDashboards = observer((): JSX.Element => {
    const [MyDashboard] = di([MyDashboardInj], MyDashboards);
    const [MyDashboardsEmptyList] = di([MyDashboardsEmptyListInj], MyDashboards);

    const rootStore = useStore();
    const myDashboardTabsModel = useMemo(() => new DashboardTabsModel(rootStore, myDashboardsRoutes), [rootStore]);
    const dashboardPageRoutes: string[] = myDashboardsRoutes.asArray();

    const dashboardsAuxContextValue: DashboardsAuxContextType = {
        routes: myDashboardsRoutes,
    };

    const params = useParams<DashboardRouteParams>();
    const { dashboardId } = params;

    const model = useMemo(
        () => new DashboardModel(dashboardId, rootStore, myDashboardsRoutes, myDashboardTabsModel),
        [dashboardId, rootStore],
    );

    const {
        getTabs,
        tabs,
        isThereAnyTabs,
        isLoading,
        startLoading,
        stopLoading,
        checkViewDashboardsListAuth,
        checkMoveDashboardTabsAuth,
        redirectToFirstTabsPath,
    } = myDashboardTabsModel;

    const dashboardPageContextValue: DashboardPageContextType = {
        dashboardModel: model,
    };

    const [isRedirectedToInitialDashboardOnce, setIsRedirectedToInitialDashboardOnce] = useState<boolean>(false);

    const myDashboardsTitle = useLocale('dashboard.phrases.myDashboards', true, {
        dashboardPluralNominative: useLocale('dashboard.plural.nominative', false)[0],
    })[0];

    useEffect(() => {
        startLoading();
        checkViewDashboardsListAuth().then(async (allowed) => {
            if (!allowed) {
                return stopLoading();
            }
            try {
                const isAllowedToMoveTabs = await checkMoveDashboardTabsAuth();
                await getTabs(isAllowedToMoveTabs);
            } finally {
                stopLoading();
            }
        });
    }, []);

    useEffect(() => {
        // редиректим на стартовый дашборд если в params не указан id дашборда
        // при этом этот эффект запускается при изменении myDashboardTabsModel.tabs
        // так как они подгружаются в отдельном эффекте
        if (!isRedirectedToInitialDashboardOnce && !dashboardId && tabs.length) {
            redirectToFirstTabsPath();
            setIsRedirectedToInitialDashboardOnce(true);
        }
    }, [dashboardId, tabs, isRedirectedToInitialDashboardOnce]);

    if (isLoading) {
        return <Loader fullSize />;
    }

    return (
        <DashboardsAuxContext.Provider value={dashboardsAuxContextValue}>
            <DashboardPageContext.Provider value={dashboardPageContextValue}>
                <Box sx={myDashboardsWrapperSx}>
                    <Typography variant="h3" sx={myDashboardsTitleSx}>
                        {myDashboardsTitle}
                    </Typography>
                    <Switch>
                        <Route path={dashboardPageRoutes}>
                            {isThereAnyTabs ? (
                                <MyDashboard tabsModel={myDashboardTabsModel} />
                            ) : (
                                <MyDashboardsEmptyList />
                            )}
                        </Route>
                    </Switch>
                </Box>
            </DashboardPageContext.Provider>
        </DashboardsAuxContext.Provider>
    );
});
