import { TitlesDTO } from '@platform/front-types';
import { FieldValues } from '@platform/multi-lang-field';
import {
    WidgetBaseDataDTOWithWidgetAttributes,
    WidgetCreateUpdateDTOBase,
    WidgetType,
    WidgetUpdateInfoDTOBase,
} from './common';

/**
 *  Название полей формы создания/редактирования виджета-ссылки (если значение поля формы находится в объекте, то указывается путь до поля)
 */
export enum LinkWidgetCreateEditFormSettingsFields {
    link = 'settings.link',
    descriptions = 'settings.descriptions',
}
/**
 *  Название ключей объекта значения полей формы создания/редактирования виджета-ссылки
 */
export enum LinkWidgetCreateEditFormSettingsValueKeys {
    link = 'link',
    descriptions = 'descriptions',
}

/**
 *  Типы значений полей формы создания/редактирования виджета-ссылки
 */
export type LinkWidgetCreateEditFormSettingsValues = {
    [LinkWidgetCreateEditFormSettingsValueKeys.link]: string;
    [LinkWidgetCreateEditFormSettingsValueKeys.descriptions]: FieldValues[];
};

/**
 *  Тип специфичных для виджета-ссылки настроек, отправляемых на бэк при создании/редактирования виджета-счетчика
 */
export type LinkWidgetCreateUpdateSettings = {
    descriptions: TitlesDTO;
    link: string;
};

/**
 *  Тип, содержащий итоговые данные виджета-ссылки, отправляющегося на бэк при сохранении/редактировании виджета
 */
export type LinkWidgetCreateUpdateDTO = WidgetCreateUpdateDTOBase<WidgetType.link, LinkWidgetCreateUpdateSettings>;

/**
 *  Тип данных, описывающий данные существующего виджета-ссылку используемые для его редактирования
 */
export type LinkWidgetUpdateInfoDTO = WidgetUpdateInfoDTOBase<LinkWidgetCreateUpdateSettings>;

/**
 *  Тип специфичных для виджета-ссылки данных
 */
export type LinkWidgetSpecificAttributes = {
    description: string;
    link: string;
};

/**
 *  Тип данных виджета-ссылки
 */
export type LinkWidgetDTO = WidgetBaseDataDTOWithWidgetAttributes<WidgetType.link, LinkWidgetSpecificAttributes>;
