import {
    Add,
    Alarm,
    Anchor,
    Announcement,
    AttachFile,
    BackHand,
    Beenhere,
    Biotech,
    Block,
    Bolt,
    Bookmark,
    Build,
    Cached,
    CalendarMonth,
    Campaign,
    Cancel,
    CheckCircle,
    Delete,
    Drafts,
    Edit,
    Flag,
    Folder,
    Help,
    Home,
    HourglassTop,
    Hub,
    Lightbulb,
    LocalFireDepartment,
    MenuBook,
    NotificationsActive,
    Pause,
    Payments,
    Person,
    Star,
    SvgIconComponent,
    Work,
} from '@mui/icons-material';
import { SxStyle } from '@platform/front-types';
import { grey } from '@platform/front-ui';
import { WidgetBackgroundColorViewSetting, WidgetBaseIconCode, WidgetFontSizeViewSetting } from '../types';

/**
 * Константы с размерами шрифтов виджета
 */
export const fontButtonFontSizes: WidgetFontSizeViewSetting[] = [
    WidgetFontSizeViewSetting.standard,
    WidgetFontSizeViewSetting.large,
    WidgetFontSizeViewSetting.huge,
];

export const commonStylesForCounterWidgetTitle: SxStyle = {
    fontWeight: 500,
};

export const commonStylesForCounterWidgetCount: SxStyle = {
    color: grey[900],
    fontWeight: 600,
};

export const commonStylesForCounterWidgetDescription: SxStyle = {
    color: grey[700],
    fontWeight: 400,
};

export type StylesForCounterOrLinkWidgetFontSizeSettingType = Record<
    WidgetFontSizeViewSetting,
    {
        title: SxStyle;
        count?: SxStyle;
        description: SxStyle;
    }
>;

/**
 * Объект для получения стилей наименования и описания в зависимости от размеров шрифта
 */
export const stylesForCounterWidgetFontSizeSetting: StylesForCounterOrLinkWidgetFontSizeSettingType = {
    [WidgetFontSizeViewSetting.standard]: {
        title: {
            ...commonStylesForCounterWidgetTitle,
            fontSize: '14px',
            lineHeight: '18px',
        },
        count: {
            ...commonStylesForCounterWidgetCount,
            fontSize: '24px',
            lineHeight: '32px',
        },
        description: {
            ...commonStylesForCounterWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    [WidgetFontSizeViewSetting.large]: {
        title: {
            ...commonStylesForCounterWidgetTitle,
            fontSize: '16px',
            lineHeight: '20px',
        },
        count: {
            ...commonStylesForCounterWidgetCount,
            fontSize: '26px',
            lineHeight: '36px',
        },
        description: {
            ...commonStylesForCounterWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    [WidgetFontSizeViewSetting.huge]: {
        title: {
            ...commonStylesForCounterWidgetTitle,
            fontSize: '18px',
            lineHeight: '24px',
        },
        count: {
            ...commonStylesForCounterWidgetCount,
            fontSize: '28px',
            lineHeight: '40px',
        },
        description: {
            ...commonStylesForCounterWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
};

export const commonStylesForLinkWidgetTitle: SxStyle = {
    fontWeight: 600,
};

export const commonStylesForLinkWidgetDescription: SxStyle = {
    color: grey[700],
    fontWeight: 400,
};

export const stylesForLinkWidgetFontSizeSetting: StylesForCounterOrLinkWidgetFontSizeSettingType = {
    [WidgetFontSizeViewSetting.standard]: {
        title: {
            ...commonStylesForLinkWidgetTitle,
            fontSize: '16px',
            lineHeight: '20px',
        },
        description: {
            ...commonStylesForLinkWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    [WidgetFontSizeViewSetting.large]: {
        title: {
            ...commonStylesForLinkWidgetTitle,
            fontSize: '18px',
            lineHeight: '24px',
        },
        description: {
            ...commonStylesForLinkWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    [WidgetFontSizeViewSetting.huge]: {
        title: {
            ...commonStylesForLinkWidgetTitle,
            fontSize: '20px',
            lineHeight: '28px',
        },
        description: {
            ...commonStylesForLinkWidgetDescription,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
};

export const backgroundColorOrderedPalette: WidgetBackgroundColorViewSetting[] = [
    WidgetBackgroundColorViewSetting.lightRed,
    WidgetBackgroundColorViewSetting.lightOrange,
    WidgetBackgroundColorViewSetting.lightChlorine,
    WidgetBackgroundColorViewSetting.lightGreen,
    WidgetBackgroundColorViewSetting.lightTurquoise,
    WidgetBackgroundColorViewSetting.lightBlue,
    WidgetBackgroundColorViewSetting.lightViolet,
    WidgetBackgroundColorViewSetting.lightPink,

    WidgetBackgroundColorViewSetting.red,
    WidgetBackgroundColorViewSetting.orange,
    WidgetBackgroundColorViewSetting.chlorine,
    WidgetBackgroundColorViewSetting.green,
    WidgetBackgroundColorViewSetting.turquoise,
    WidgetBackgroundColorViewSetting.blue,
    WidgetBackgroundColorViewSetting.violet,
    WidgetBackgroundColorViewSetting.pink,

    WidgetBackgroundColorViewSetting.redGradient,
    WidgetBackgroundColorViewSetting.orangeGradient,
    WidgetBackgroundColorViewSetting.chlorineGradient,
    WidgetBackgroundColorViewSetting.greenGradient,
    WidgetBackgroundColorViewSetting.turquoiseGradient,
    WidgetBackgroundColorViewSetting.blueGradient,
    WidgetBackgroundColorViewSetting.violetGradient,
    WidgetBackgroundColorViewSetting.pinkGradient,
];

/**
 * Объект с css-значениями цвета фона для соответствующих значений настройки отображения цвета фона
 */
export const backgroundColors: Record<WidgetBackgroundColorViewSetting, string> = {
    [WidgetBackgroundColorViewSetting.white]: 'rgb(255,255,255)',

    [WidgetBackgroundColorViewSetting.lightRed]: 'rgba(246, 177, 177, 1)',
    [WidgetBackgroundColorViewSetting.lightOrange]: 'rgba(245, 213, 163, 1)',
    [WidgetBackgroundColorViewSetting.lightChlorine]: 'rgba(224, 235, 173, 1)',
    [WidgetBackgroundColorViewSetting.lightGreen]: 'rgba(159, 223, 159, 1)',
    [WidgetBackgroundColorViewSetting.lightTurquoise]: 'rgba(159, 223, 212, 1)',
    [WidgetBackgroundColorViewSetting.lightBlue]: 'rgba(173, 206, 235, 1)',
    [WidgetBackgroundColorViewSetting.lightViolet]: 'rgba(199, 178, 229, 1)',
    [WidgetBackgroundColorViewSetting.lightPink]: 'rgba(243, 190, 233, 1)',

    [WidgetBackgroundColorViewSetting.red]: 'rgba(240, 117, 117, 1)',
    [WidgetBackgroundColorViewSetting.orange]: 'rgba(246, 183, 85, 1)',
    [WidgetBackgroundColorViewSetting.chlorine]: 'rgba(214, 232, 125, 1)',
    [WidgetBackgroundColorViewSetting.green]: 'rgba(121, 210, 121, 1)',
    [WidgetBackgroundColorViewSetting.turquoise]: 'rgba(92, 214, 194, 1)',
    [WidgetBackgroundColorViewSetting.blue]: 'rgba(90, 163, 226, 1)',
    [WidgetBackgroundColorViewSetting.violet]: 'rgba(170, 133, 224, 1)',
    [WidgetBackgroundColorViewSetting.pink]: 'rgba(245, 163, 228, 1)',

    [WidgetBackgroundColorViewSetting.redGradient]:
        'linear-gradient(180deg, rgba(247, 186, 186, 1), rgba(240, 117, 117, 1))',
    [WidgetBackgroundColorViewSetting.orangeGradient]:
        'linear-gradient(180deg, rgba(250, 214, 158, 1), rgba(246, 183, 85, 1))',
    [WidgetBackgroundColorViewSetting.chlorineGradient]:
        'linear-gradient(180deg, rgba(217, 229, 153, 1), rgba(186, 209, 71, 1))',
    [WidgetBackgroundColorViewSetting.greenGradient]:
        'linear-gradient(180deg, rgba(198, 236, 198, 1), rgba(121, 210, 121, 1))',
    [WidgetBackgroundColorViewSetting.turquoiseGradient]:
        'linear-gradient(180deg, rgba(173, 235, 224, 1), rgba(92, 214, 194, 1))',
    [WidgetBackgroundColorViewSetting.blueGradient]:
        'linear-gradient(180deg, rgba(147, 194, 236, 1), rgba(90, 163, 226, 1))',
    [WidgetBackgroundColorViewSetting.violetGradient]:
        'linear-gradient(180deg, rgba(213, 194, 240, 1), rgba(170, 133, 224, 1))',
    [WidgetBackgroundColorViewSetting.pinkGradient]:
        'linear-gradient(180deg, rgba(250, 209, 242, 1), rgba(245, 163, 228, 1))',
};

/**
 * Объект с svg-иконками для соответствующих названий иконок
 */
export const widgetIcons: Record<WidgetBaseIconCode, SvgIconComponent | null> = {
    [WidgetBaseIconCode.None]: null,
    [WidgetBaseIconCode.Add]: Add,
    [WidgetBaseIconCode.Alarm]: Alarm,
    [WidgetBaseIconCode.Anchor]: Anchor,
    [WidgetBaseIconCode.Announcement]: Announcement,
    [WidgetBaseIconCode.AttachFile]: AttachFile,
    [WidgetBaseIconCode.BackHand]: BackHand,
    [WidgetBaseIconCode.Beenhere]: Beenhere,
    [WidgetBaseIconCode.Biotech]: Biotech,
    [WidgetBaseIconCode.Block]: Block,
    [WidgetBaseIconCode.Bolt]: Bolt,
    [WidgetBaseIconCode.Bookmark]: Bookmark,
    [WidgetBaseIconCode.Build]: Build,
    [WidgetBaseIconCode.Cached]: Cached,
    [WidgetBaseIconCode.CalendarMonth]: CalendarMonth,
    [WidgetBaseIconCode.Campaign]: Campaign,
    [WidgetBaseIconCode.Cancel]: Cancel,
    [WidgetBaseIconCode.CheckCircle]: CheckCircle,
    [WidgetBaseIconCode.Delete]: Delete,
    [WidgetBaseIconCode.Drafts]: Drafts,
    [WidgetBaseIconCode.Edit]: Edit,
    [WidgetBaseIconCode.Flag]: Flag,
    [WidgetBaseIconCode.Folder]: Folder,
    [WidgetBaseIconCode.Help]: Help,
    [WidgetBaseIconCode.Home]: Home,
    [WidgetBaseIconCode.HourglassTop]: HourglassTop,
    [WidgetBaseIconCode.Hub]: Hub,
    [WidgetBaseIconCode.Lightbulb]: Lightbulb,
    [WidgetBaseIconCode.LocalFireDepartment]: LocalFireDepartment,
    [WidgetBaseIconCode.MenuBook]: MenuBook,
    [WidgetBaseIconCode.NotificationsActive]: NotificationsActive,
    [WidgetBaseIconCode.Pause]: Pause,
    [WidgetBaseIconCode.Payments]: Payments,
    [WidgetBaseIconCode.Person]: Person,
    [WidgetBaseIconCode.Star]: Star,
    [WidgetBaseIconCode.Work]: Work,
};

/**
 * Дефолтный цвет фона виджетов
 */
export const defaultWidgetBackgroundColor = WidgetBackgroundColorViewSetting.white;

/**
 * @prop {string} backgroundColor - css-значение цвета фона
 * @prop {WidgetBackgroundColorViewSetting} backgroundColorName - название цвета фона
 */
export type GetBackgroundColorByBackgroundColorViewSettingReturnType = {
    backgroundColor: string;
    backgroundColorName: WidgetBackgroundColorViewSetting;
};

/**
 * Функция для получения css-значения цвета в завимости от значения настройки цвета фона
 * @param {WidgetBackgroundColorViewSetting} backgroundColorViewSetting - значение настройки отображения цвета фона
 */
export const getBackgroundColorByBackgroundColorViewSetting = (
    backgroundColorViewSetting?: WidgetBackgroundColorViewSetting,
): GetBackgroundColorByBackgroundColorViewSettingReturnType => {
    let color;
    if (backgroundColorViewSetting && backgroundColorViewSetting in backgroundColors) {
        color = backgroundColorViewSetting;
    } else {
        color = defaultWidgetBackgroundColor;
    }
    return { backgroundColor: backgroundColors[color], backgroundColorName: color };
};
