import { Box } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { getBackgroundColorByBackgroundColorViewSetting } from '../../../../../../../constants';
import { WidgetModel } from '../../../../../../../models';
import { WidgetType } from '../../../../../../../types';
import { isWidgetTypeCounter, isWidgetTypeLink } from '../../../../../../../utils';
import { CounterWidget as CounterWidgetInj } from './counter-widget';
import { LinkWidget as LinkWidgetInj } from './link-widget';
import { WidgetErrorComponent as WidgetErrorComponentInj } from './WidgetErrorComponent';
import { WidgetSkeleton as WidgetSkeletonInj } from './WidgetSkeleton';

export type WidgetsGridItemContentProps = {
    widgetModel?: WidgetModel;
};

type GetSxStylesProps = {
    backgroundColor: string;
};

const boxShadow = (opacity: number) => {
    return `0px 4px 8px 0px rgba(16, 26, 63, ${opacity})`;
};

const getSxStyles = makeSxStylesWithProps((props: GetSxStylesProps) => {
    const { backgroundColor } = props;

    return {
        contentWrapper: {
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            borderWidth: '1px',
            boxShadow: boxShadow(0.05),
            background: backgroundColor,
            '&:hover': {
                boxShadow: boxShadow(0.1),
            },
            '&:active': {
                boxShadow: boxShadow(0.3),
            },
        },
    };
});

export const WidgetsGridItemContent = observer((props: WidgetsGridItemContentProps): JSX.Element => {
    const [WidgetSkeleton] = di([WidgetSkeletonInj], WidgetsGridItemContent);
    const [WidgetErrorComponent] = di([WidgetErrorComponentInj], WidgetsGridItemContent);
    const [CounterWidget] = di([CounterWidgetInj], WidgetsGridItemContent);
    const [LinkWidget] = di([LinkWidgetInj], WidgetsGridItemContent);

    const { widgetModel } = props;

    let renderedWidget: React.ReactNode;

    const { backgroundColor } = getBackgroundColorByBackgroundColorViewSetting(
        widgetModel?.viewSettings.backgroundColor,
    );
    const sxStyles = getSxStyles({ backgroundColor });

    if (widgetModel) {
        const { widgetType, isLoading, failed } = widgetModel;
        if (isLoading) {
            renderedWidget = <WidgetSkeleton widgetType={widgetType} />;
        } else if (failed) {
            renderedWidget = <WidgetErrorComponent widgetModel={widgetModel} />;
        } else {
            switch (widgetType) {
                case WidgetType.counter: {
                    renderedWidget = isWidgetTypeCounter(widgetModel) ? (
                        <CounterWidget widgetModel={widgetModel} />
                    ) : undefined;
                    break;
                }
                case WidgetType.link: {
                    renderedWidget = isWidgetTypeLink(widgetModel) ? (
                        <LinkWidget widgetModel={widgetModel} />
                    ) : undefined;
                    break;
                }
            }
        }
    }
    return (
        <React.Fragment>
            <Box sx={sxStyles.contentWrapper}>{renderedWidget}</Box>
        </React.Fragment>
    );
});
