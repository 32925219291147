import {
    BreadcrumbsEntity as DefaultBreadcrumbsEntity,
    BreadcrumbsLocation as DefaultBreadcrumbsLocation,
} from '@platform/front-core';

export enum BreadcrumbsCommon {
    dashboard = 'dashboard',
}

export const BreadcrumbsLocation = {
    ...DefaultBreadcrumbsLocation,
    ...BreadcrumbsCommon,
};

export const BreadcrumbsEntity = {
    ...DefaultBreadcrumbsEntity,
    ...BreadcrumbsCommon,
};

export type BreadcrumbsLocation = keyof typeof BreadcrumbsLocation;
export type BreadcrumbsEntity = keyof typeof BreadcrumbsEntity;
