import { Grid, Skeleton } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';

const sxStyles = makeSxStyles({
    mainContainer: {
        pb: 2,
    },
    sectionHeaderWrapper: {
        pb: 3,
    },
    sectionHeader: {
        height: (theme) => theme.spacing(6),
    },
    widgetsGrid: {
        height: (theme) => theme.spacing(20),
    },
});

export const SectionListItemSkeleton = (): JSX.Element => {
    return (
        <Grid container direction="column" wrap="nowrap" sx={sxStyles.mainContainer}>
            <Grid item sx={sxStyles.sectionHeaderWrapper}>
                <Skeleton variant="rectangular" sx={sxStyles.sectionHeader} />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" sx={sxStyles.widgetsGrid} />
            </Grid>
        </Grid>
    );
};
