import { IntlStore } from '@platform/front-core';
import { getRuTitleInitialValue } from '@platform/multi-lang-field';
import {
    WidgetBackgroundColorViewSetting,
    WidgetCreateEditFormCommonFields,
    WidgetCreateEditFormValues,
} from '../../../types';
import { getWidgetTypeSelectOptions } from '../getWidgetTypeSelectOptions';
import { getCounterWidgetCreateEditDialogFormInitialValues } from './getCounterWidgetCreateEditDialogFormInitialValues';
import { getLinkWidgetCreateEditDialogFormInitialValues } from './getLinkWidgetCreateEditDialogFormInitialValues';

/**
 * Функция, генерирующая дефолтные значения полей формы создания/редактирования виджета
 */
export const getDefaultWidgetCreateEditFormInitialValues = (intlStore: IntlStore): WidgetCreateEditFormValues => ({
    [WidgetCreateEditFormCommonFields.titles]: getRuTitleInitialValue(),
    [WidgetCreateEditFormCommonFields.widgetType]: getWidgetTypeSelectOptions(intlStore.intl)[0],
    // нужно чтобы в settings содержались значения всех возможных специфичных полей формы
    settings: {
        ...getCounterWidgetCreateEditDialogFormInitialValues(),
        ...getLinkWidgetCreateEditDialogFormInitialValues(),
    },
    [WidgetCreateEditFormCommonFields.backgroundColor]: WidgetBackgroundColorViewSetting.white,
});
