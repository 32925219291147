import { action, makeObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { RootStore } from '../../stores';
import { DashboardRoutes, WidgetCreateEditFormValues } from '../../types';
import { getWidgetCreateUpdateDTOFromFormValues, getWidgetCreateUpdateFieldsForServerValidation } from '../../utils';
import {
    WidgetCreateEditDialogModel,
    widgetCreateEditDialogModelAbstractObservables,
} from './WidgetCreateEditDialogModel';

export const widgetCreateDialogModelObservables = {
    ...widgetCreateEditDialogModelAbstractObservables,
    reloadSectionData: action.bound,
};

/**
 * Класс, содержащий данные и логику создания виджета
 */
export class WidgetCreateDialogModel extends WidgetCreateEditDialogModel {
    reloadSectionData: (shouldScrollToNewWidgetAndHighlight?: boolean) => void;

    constructor(
        dashboardId: string,
        sectionId: string,
        rootStore: RootStore,
        reloadSectionData: VoidFunction,
        routes: DashboardRoutes,
    ) {
        super(dashboardId, sectionId, rootStore, routes);
        this.reloadSectionData = reloadSectionData;
        makeObservable(this, widgetCreateDialogModelObservables);
    }

    onSubmit(formValues: WidgetCreateEditFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const widgetCreateDTO = getWidgetCreateUpdateDTOFromFormValues(formValues);
        const fieldsForServerValidation = getWidgetCreateUpdateFieldsForServerValidation(formValues.widgetType);
        if (widgetCreateDTO && fieldsForServerValidation) {
            return this.dashboardStore
                .createWidget(widgetCreateDTO, this.sectionId, fieldsForServerValidation)
                .then(() => {
                    this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
                    this.reloadSectionData(true);
                })
                .catch(this.serverErrors.setServerFormErrors);
        }
        return Promise.reject();
    }
}
