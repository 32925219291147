import { Box } from '@mui/material';
import { blue, grey, makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { widgetIcons } from '../../constants';
import { WidgetBackgroundColorViewSetting, WidgetBaseIconCode } from '../../types';

export type IconForWidgetPropType = {
    backgroundColor: WidgetBackgroundColorViewSetting;
    iconCode?: WidgetBaseIconCode;
};

type GetSxStylesProps = {
    backgroundColor: WidgetBackgroundColorViewSetting;
};

const getSxStyles = makeSxStylesWithProps((props: GetSxStylesProps) => {
    const { backgroundColor } = props;
    const background = backgroundColor !== WidgetBackgroundColorViewSetting.white ? grey[0] : grey[100];

    return {
        iconWrapper: {
            mr: (theme) => theme.spacing(1),
            width: '40px',
            height: '40px',
            background: background,
            borderRadius: '50%',
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            color: blue[600],
        },
    };
});

/**
 * Отображение иконки в самом виджете
 * @param {WidgetBackgroundColorViewSetting} backgroundColor
 * @param {iconCode} iconCode - необязательный параметр
 * @see {@link IconForWidgetPropType}
 */
export const IconForWidget = observer((props: IconForWidgetPropType): JSX.Element => {
    const { iconCode, backgroundColor } = props;

    const sxStyles = getSxStyles({ backgroundColor });

    const IconWidget = widgetIcons[iconCode as WidgetBaseIconCode];

    return (
        <React.Fragment>
            {IconWidget && (
                <Box sx={sxStyles.iconWrapper}>
                    <IconWidget sx={sxStyles.icon} />
                </Box>
            )}
        </React.Fragment>
    );
});
