import {
    CounterWidgetCreateEditFormSettingsValueKeys,
    CounterWidgetCreateEditFormSettingsValues,
    RegistrySelectOptionDataItem,
    CounterWidgetCreationInfoSettingsFromBackend,
    WidgetUpdateInfoDTO,
} from '../../../types';
import { getRegistryIdentifier } from '../getRegistryIdentifier';
import { mapRegistrySelectOptionDataItemToCodeTitleGroup } from '../mapRegistrySelectOptionDataItemToCodeTitleGroup';
import { getCounterWidgetCreateEditDialogFormInitialValues } from './getCounterWidgetCreateEditDialogFormInitialValues';

const defaultInitialValues = {
    ...getCounterWidgetCreateEditDialogFormInitialValues(),
    [CounterWidgetCreateEditFormSettingsValueKeys.descriptions]: undefined,
};

/**
 * Функция вычисляет значения полей-селектов настроек виджета-счетчика формы редактирования/создания виджета
 * @param {WidgetUpdateInfoDTO} updateInfo - Сохраненные данные конкретного виджета c бэкенда
 * @param {CounterWidgetCreationInfoSettingsFromBackend} creationSettings - данные создания для виджета счетчика (опции селектов)
 * */
export const getCounterWidgetSettingsSelectFieldValues = (
    updateInfo: WidgetUpdateInfoDTO,
    creationSettings: CounterWidgetCreationInfoSettingsFromBackend,
): Omit<CounterWidgetCreateEditFormSettingsValues, CounterWidgetCreateEditFormSettingsValueKeys.descriptions> => {
    const { registries, presets } = creationSettings;

    const updateInfoSettings = updateInfo.settings;
    if ('registry' in updateInfoSettings && 'presetId' in updateInfoSettings) {
        const { registry: widgetRegistryData, presetId } = updateInfoSettings;
        const registrySelectValue = registries.find(
            (registry: RegistrySelectOptionDataItem) =>
                registry.code === widgetRegistryData.code && registry.moduleCode === widgetRegistryData.moduleCode,
        );
        if (registrySelectValue) {
            const registryKeyForPresets = getRegistryIdentifier(
                registrySelectValue.moduleCode,
                registrySelectValue.code,
            );
            const presetSelectValue = presets?.[registryKeyForPresets]?.find((preset) => preset.id === presetId);
            if (presetSelectValue) {
                return {
                    [CounterWidgetCreateEditFormSettingsValueKeys.registry]:
                        mapRegistrySelectOptionDataItemToCodeTitleGroup(registrySelectValue),
                    [CounterWidgetCreateEditFormSettingsValueKeys.preset]: presetSelectValue,
                };
            }
        }
    }

    return defaultInitialValues;
};
