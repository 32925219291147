import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Grid } from '@mui/material';
import { useFetchAllPermissions, UseFetchAllPermissionsProps } from '@platform/front-core';
import { activeSelector, blue, grey, makeSxStyles } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { di } from 'react-magnetic-di';
import {
    autoScrollToNewSectionDelay,
    highlightDuration,
    reactGridLayoutBreakpoints,
    reactGridLayoutCols,
    reactGridLayoutDraggableHandleSelector,
    reactGridLayoutPropValue,
    reactGridLayoutRowHeight,
    widgetHighlightActivationDelay,
} from '../../../../../../constants';
import { useDashboardPageContext, useStore } from '../../../../../../hooks';
import { SectionModel, WidgetModel } from '../../../../../../models';
import { PermissionsStore } from '../../../../../../stores';
import { ReactGridLayoutBreakpoint, WidgetPermissions } from '../../../../../../types';
import { WidgetsGridItem as WidgetGridItemInj } from './widgets-grid-components/WidgetsGridItem';

const ResponsiveGridLayout = WidthProvider(Responsive);

export type WidgetsGridProps = {
    sectionModel: SectionModel;
};

const sxStyles = makeSxStyles({
    container: {
        '.react-grid-item.react-grid-placeholder': {
            borderRadius: '8px',
            backgroundColor: blue[500],
        },
    },
    responsiveGridLayoutWrapper: {
        userSelect: 'none',
        width: (theme) => `calc(100% + ${theme.spacing(4)})`,
        marginLeft: (theme) => theme.spacing(-2),
        marginRight: (theme) => theme.spacing(-4),
    },
    controlsContainerButtonGroup: {
        backgroundColor: grey[0],
    },
    widgetControlsButton: {
        backgroundColor: grey[0],
    },
    resizeHandleContainer: {
        padding: 0.25,
        position: 'absolute',
        bottom: (theme) => theme.spacing(1),
        right: (theme) => theme.spacing(1),
        width: (theme) => theme.spacing(2),
        height: (theme) => theme.spacing(2),
        cursor: 'se-resize',
        [activeSelector]: {
            opacity: 1,
        },
    },
    resizeHandleArrow: {
        pointerEvents: 'none',
        rotate: '45deg',
        color: grey[900],
    },
    hiddenResizeHandle: {
        display: 'none',
    },
});

export const WidgetsGrid = observer((props: WidgetsGridProps): JSX.Element => {
    const [WidgetGridItem] = di([WidgetGridItemInj], WidgetsGrid);

    const { sectionModel } = props;
    const {
        layouts,
        getWidgetById,
        setLayouts,
        updateWidgetPositions,
        newWidgetId,
        shouldAutoscrollToNewWidget,
        shouldHighlightNewWidget,
        setNewWidgetId,
        setShouldAutoscrollToNewWidget,
        setShouldHighlightNewWidget,
    } = sectionModel;

    const [isWidgetResizing, startResizing, stopResizing] = useFlag();

    const [isMounted, makeMounted] = useFlag();
    const newWidgetRef = useRef<HTMLDivElement | null>(null);
    const { dashboardModel } = useDashboardPageContext();
    const rootStore = useStore();

    const { id: dashboardId } = dashboardModel;
    const { id: sectionId } = sectionModel;

    const { sectionConfig } = rootStore.coreRootStore.permissionsStore as PermissionsStore;

    useEffect(() => {
        makeMounted();
    }, []);

    useEffect(() => {
        if (newWidgetId && shouldAutoscrollToNewWidget && newWidgetRef.current) {
            setTimeout(() => {
                newWidgetRef.current?.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    // при scrollIntoView({ behavior: 'smooth' }) скролл до раздела происходит плавно -> новый раздел во view появлется не сразу
                    // задержка активации подсветки нужна для того чтобы пока происходит скролл не отнималось время анимации
                    setShouldHighlightNewWidget(true);
                }, widgetHighlightActivationDelay);
            }, autoScrollToNewSectionDelay);
        }
    }, [newWidgetId, shouldAutoscrollToNewWidget]);

    useEffect(() => {
        if (shouldAutoscrollToNewWidget && shouldHighlightNewWidget) {
            setTimeout(() => {
                setShouldAutoscrollToNewWidget(false);
                setShouldHighlightNewWidget(false);
                setNewWidgetId(undefined);
            }, highlightDuration);
        }
    }, [shouldAutoscrollToNewWidget, shouldHighlightNewWidget]);

    const allPermissionsQueries: UseFetchAllPermissionsProps = useMemo(() => {
        const updateWidgetQuery = sectionConfig.updateWidget(sectionId);
        const deleteWidgetQuery = sectionConfig.deleteWidget(sectionId);
        const updateSectionQuery = sectionConfig.update(sectionId);
        return {
            allPermissionsQueries: [updateWidgetQuery, deleteWidgetQuery, updateSectionQuery],
        };
    }, [dashboardId, sectionId]);

    const [checkResult, , isPermissionsLoading] = useFetchAllPermissions(allPermissionsQueries);
    const [isAllowedToUpdateWidget, isAllowedToDeleteWidget, isAllowedToUpdateSection] = checkResult;

    const widgetPermissions: WidgetPermissions = {
        isAllowedToUpdateWidget,
        isAllowedToDeleteWidget,
        isAllowedToUpdateSection,
        isPermissionsLoading,
    };

    const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts): void => {
        setLayouts(allLayouts);
        isAllowedToUpdateSection && updateWidgetPositions();
    };

    const mapWidgetsToWidgetsGridItems = (): React.ReactNode[] => {
        return layouts[ReactGridLayoutBreakpoint.lg].map((layoutItem) => {
            const { i: widgetIndexInLayout } = layoutItem;
            const widgetModel: WidgetModel | undefined = getWidgetById(widgetIndexInLayout);

            return (
                <WidgetGridItem
                    key={widgetIndexInLayout}
                    isWidgetResizing={isWidgetResizing}
                    widgetIndexInLayout={widgetIndexInLayout}
                    shouldHighlightNewWidget={shouldHighlightNewWidget}
                    newWidgetRef={newWidgetRef}
                    widgetPermissions={widgetPermissions}
                    newWidgetId={newWidgetId}
                    widgetModel={widgetModel}
                />
            );
        });
    };

    const shouldRenderChangeWidgetPositionButton = isAllowedToUpdateSection;

    // не удалось вынести в отдельную компоненту для передачи ResponsiveGridLayout: даже с forwardRef ломается сетка
    const resizeHandleComponent = shouldRenderChangeWidgetPositionButton ? (
        <Box sx={sxStyles.resizeHandleContainer}>
            <ArrowRightIcon sx={sxStyles.resizeHandleArrow} />
        </Box>
    ) : (
        <Box sx={sxStyles.hiddenResizeHandle} />
    );

    return (
        <Grid container wrap="nowrap" sx={sxStyles.container}>
            <Grid item sx={sxStyles.responsiveGridLayoutWrapper}>
                <ResponsiveGridLayout
                    onLayoutChange={onLayoutChange}
                    layouts={layouts}
                    compactType="vertical"
                    measureBeforeMount={false}
                    useCSSTransforms={isMounted}
                    rowHeight={reactGridLayoutRowHeight}
                    margin={reactGridLayoutPropValue}
                    cols={reactGridLayoutCols}
                    breakpoints={reactGridLayoutBreakpoints}
                    draggableHandle={reactGridLayoutDraggableHandleSelector}
                    resizeHandle={resizeHandleComponent}
                    onResizeStart={startResizing}
                    onResizeStop={stopResizing}
                >
                    {mapWidgetsToWidgetsGridItems()}
                </ResponsiveGridLayout>
            </Grid>
        </Grid>
    );
});
