import { titlesDTOtoTitles } from '@platform/multi-lang-field';
import {
    CounterWidgetCreateEditFormSettingsValueKeys,
    CounterWidgetCreateEditFormSettingsValues,
    CounterWidgetCreationInfoSettingsFromBackend,
    WidgetUpdateInfoDTO,
} from '../../../types';
import { getCounterWidgetSettingsSelectFieldValues } from './getCounterWidgetSettingsSelectFieldValues';

/**
 * Функция, генерирующая значения специфичных для виджета-счетчика полей формы созданияредактирования виджета на основе данных существующего виджета и данных создания виджета-счетчика
 * @param {WidgetUpdateInfoDTO} updateInfo - данные существующего виджета
 * @param {CounterWidgetCreationInfoSettingsFromBackend} creationSettings - данные создания для виджета счетчика (опции селектов)
 */
export const getCounterWidgetSettingsFieldValues = (
    updateInfo: WidgetUpdateInfoDTO,
    creationSettings: CounterWidgetCreationInfoSettingsFromBackend,
): CounterWidgetCreateEditFormSettingsValues => {
    const { descriptions } = updateInfo.settings;

    const counterWidgetSettingsSelectsFieldValues = getCounterWidgetSettingsSelectFieldValues(
        updateInfo,
        creationSettings,
    );

    return {
        ...counterWidgetSettingsSelectsFieldValues,
        [CounterWidgetCreateEditFormSettingsValueKeys.descriptions]: titlesDTOtoTitles(descriptions),
    };
};
