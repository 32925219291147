import { LocalesTree } from '@platform/front-utils';

export const common: LocalesTree = {
    hoursAgo: 'h. ago',
    instructionFileName: 'Instruction.docx',
    instructionSrc: '-',
    lastUpdate: 'Last update:',
    lessThanMinuteAgo: 'less than a minute ago',
    minuteAgo: 'min. ago',
    or: 'or',
    update: 'Update',
    yesterday: 'yesterday',
};
