import { Box, Grid, GridProps } from '@mui/material';
import { blue, grey, hoverSelector, makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { backgroundColors } from '../../../../../../../../constants';

type GetColorCircleSxStylesProps = {
    backgroundColor: string;
    isCurrentColor?: boolean;
};

const getColorCircleSxStyles = makeSxStylesWithProps((props: GetColorCircleSxStylesProps) => {
    const { backgroundColor, isCurrentColor } = props;

    return {
        circle: {
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            position: 'relative',
            cursor: 'pointer',
            border: `2px solid ${!!isCurrentColor ? blue[600] : grey[0]}`,
            [hoverSelector]: {
                borderColor: blue[500],
            },
        },
        borderCircle: {
            boxSizing: 'border-box',
            background: backgroundColor,
            width: '20px',
            height: '20px',
            position: 'absolute',
            top: '2px',
            left: '2px',
            borderRadius: '50%',
            border: backgroundColor === backgroundColors.white ? `1px solid ${grey[300]}` : 'none',
        },
    };
});

export type ColorCircleProps = GridProps & {
    backgroundColor: string;
    isCurrentColor?: boolean;
};

export const ColorCircle = observer((props: ColorCircleProps): JSX.Element => {
    const { backgroundColor, isCurrentColor, ...gridProps } = props;

    const sxStyles = getColorCircleSxStyles({ backgroundColor, isCurrentColor });

    return (
        <Grid container flexWrap="nowrap" alignItems="center" justifyContent="center" role="button" {...gridProps}>
            <Box sx={sxStyles.circle}>
                <Box sx={sxStyles.borderCircle} />
            </Box>
        </Grid>
    );
});
