import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { mergeSxStyles, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { MutableRefObject, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { CustomButton } from '../../../../../components';
import { DashboardSectionContext, DashboardSectionContextType } from '../../../../../contexts';
import { widgetMessages } from '../../../../../customization';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { SectionModel } from '../../../../../models';
import { PermissionsStore } from '../../../../../stores';
import { getSectionListItemSxStyles as getSectionListItemSxStylesInj } from './getSectionListItemSxStyles';
import { SectionListItemMenuButton as SectionListItemMenuButtonInj } from './SectionListItemMenuButton';
import { SectionListItemSkeleton as SectionListItemSkeletonInj } from './SectionListItemSkeleton';
import { WidgetsGrid as WidgetsGridInj } from './widget-components';

export type SectionListItemProps = {
    draggableProvided: DraggableProvided;
    sectionModel: SectionModel;
    theLastListItemRef?: MutableRefObject<HTMLElement | null | undefined>;
    isWithDragging: boolean;
};

export const SectionListItem = observer((props: SectionListItemProps): JSX.Element => {
    const [SectionListItemSkeleton] = di([SectionListItemSkeletonInj], SectionListItem);
    const [SectionListItemMenuButton] = di([SectionListItemMenuButtonInj], SectionListItem);
    const [WidgetsGrid] = di([WidgetsGridInj], SectionListItem);
    const [getSectionListItemSxStyles] = di([getSectionListItemSxStylesInj], SectionListItem);

    const { draggableProvided, sectionModel, theLastListItemRef, isWithDragging } = props;
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id: dashboardId, shouldHighlightTheLastListElement } = dashboardModel;
    const { id: sectionId, title, widgets, isLoading } = sectionModel;

    const { sectionConfig } = useStore().coreRootStore.permissionsStore as PermissionsStore;
    const { update, createWidget } = sectionConfig;
    const createWidgetQuery = createWidget(sectionId);
    const updateSectionQuery = update(sectionId);

    const history = useHistory();

    const [isWithDragIndicatorIcon, setIsWithDragIndicatorIcon] = useState(false);

    const sxStyles = getSectionListItemSxStyles({ isWithDragging, isWithoutWidgets: widgets.length === 0 });

    const setRefsCallback = (ref: HTMLDivElement): void => {
        draggableProvided.innerRef(ref);
        theLastListItemRef && (theLastListItemRef.current = ref);
    };

    const onAddWidgetButtonClick = (): void => {
        history.push(
            generatePath(routes.dashboardSectionCreateWidget, {
                dashboardId,
                sectionId,
            }),
        );
    };

    const listItemSx = mergeSxStyles(
        sxStyles.listItem,
        theLastListItemRef && shouldHighlightTheLastListElement ? sxStyles.sectionTitleHighlighted : {},
    );

    const dashboardSectionContextValue: DashboardSectionContextType = {
        sectionModel,
    };

    const dragIndicatorIcon = (allowed: boolean): JSX.Element => {
        setIsWithDragIndicatorIcon(allowed);
        return allowed ? (
            <Grid item>
                <Box id="dragIndicatorIcon" {...draggableProvided.dragHandleProps} sx={sxStyles.dragIconWrapper}>
                    <DragIndicatorIcon />
                </Box>
            </Grid>
        ) : (
            <React.Fragment />
        );
    };

    return (
        <DashboardSectionContext.Provider value={dashboardSectionContextValue}>
            {isLoading ? (
                <SectionListItemSkeleton />
            ) : (
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={listItemSx}
                    ref={setRefsCallback}
                    {...draggableProvided.draggableProps}
                >
                    <Grid
                        container
                        sx={sxStyles.sectionTitleWithActions}
                        wrap="nowrap"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        <Grid item container sx={sxStyles.titleWrapper} wrap="nowrap">
                            <AuthorizationCheck {...updateSectionQuery}>{dragIndicatorIcon}</AuthorizationCheck>
                            <Typography
                                isWithoutAutoCapitalize
                                sx={sxStyles.title}
                                ml={isWithDragIndicatorIcon ? 0 : 3}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid
                            id="actionsContainer"
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            sx={sxStyles.actionsContainer}
                        >
                            <AuthorizationCheck {...createWidgetQuery}>
                                <Grid id="actionsButton" item>
                                    <CustomButton onClick={onAddWidgetButtonClick} sx={sxStyles.button}>
                                        <FormattedMessage {...widgetMessages.addWidget} />
                                    </CustomButton>
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item>
                                <SectionListItemMenuButton sectionModel={sectionModel} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {!!widgets.length ? (
                        <Grid item paddingX={3}>
                            <WidgetsGrid sectionModel={sectionModel} />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            spacing={1}
                            flexGrow={1}
                        >
                            <Grid item>
                                <ReportIcon sx={sxStyles.icon} />
                            </Grid>
                            <Grid item>
                                <Typography variant="button">
                                    <FormattedMessage {...widgetMessages.noWidgetsPlaceholder} />
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </DashboardSectionContext.Provider>
    );
});
