import { CoreRootStore } from '@platform/front-core';
import { LoadingModel } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { ModulesStore } from '../stores';
import { NavigateModuleInfoDTO } from '../types';

export const loginPageModelObservables = {
    modulesStore: observable,
    linksList: observable,

    load: action.bound,
    setLinksList: action.bound,
};

export class LoginPageModuleLinksListModel extends LoadingModel {
    protected modulesStore: ModulesStore;

    linksList: NavigateModuleInfoDTO[] = [];

    constructor(coreRootStore: CoreRootStore) {
        super();
        makeObservable(this, loginPageModelObservables);
        this.modulesStore = coreRootStore.modulesStore as ModulesStore;
    }

    load(lang: string): void {
        this.startLoading();
        this.modulesStore.getLoginPageModuleLinksList(lang).then(this.setLinksList).finally(this.stopLoading);
    }

    setLinksList(linksList: NavigateModuleInfoDTO[]): void {
        this.linksList = linksList;
    }
}
