import { Divider, Grid, GridProps } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import React from 'react';

type GetCustomDividerForDialogSxStylesProps = {
    spacing: number;
};

const getCustomDividerForDialogSxStyles = makeSxStylesWithProps((props: GetCustomDividerForDialogSxStylesProps) => {
    const { spacing } = props;

    return {
        divider: {
            position: 'absolute',
            left: 0,
            right: 0,
            width: (theme) => `calc(100% + ${theme.spacing(spacing)})`,
        },
    };
});

/**
 * @prop {number} spacing - параметр растяжения Divider
 * @prop {GridProps['sx']} gridItemSx - стили оборачивающего Grid компонента
 */
export type CustomDividerForDialogProps = {
    spacing: number;
    gridItemSx?: GridProps['sx'];
};

/**
 * Кастомный Divider для модальных окон растягивается по всей ширине
 * @param props {@link CustomDividerForDialogProps}
 */
export const CustomDividerForDialog = (props: CustomDividerForDialogProps): JSX.Element => {
    const { spacing, gridItemSx } = props;

    const sxStyles = getCustomDividerForDialogSxStyles({ spacing });

    return (
        <Grid item sx={gridItemSx}>
            <Divider sx={sxStyles.divider} />
        </Grid>
    );
};
