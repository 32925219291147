import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const sectionMessages = defineMessages({
    createDialogTitle: {
        id: 'section.phrases.new',
        values: {
            sectionNominative: <FormattedMessage id="section.single.nominative" />,
        },
    },
    editDialogTitle: {
        id: 'section.phrases.editing',
        values: {
            sectionGenitive: <FormattedMessage id="section.single.genitive" />,
        },
    },
    addSection: {
        id: 'section.phrases.add',
        values: {
            section: <FormattedMessage id="section.single.nominative" />,
        },
    },
    deleteDialogTitle: {
        id: 'section.phrases.deleteDialogTitle',
        values: {
            section: <FormattedMessage id="section.single.nominative" />,
        },
    },
});
