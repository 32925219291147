import { MoreHoriz } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { useFetchAllPermissions, UseFetchAllPermissionsProps } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { ActionMenuItem, MenuButton } from '@platform/front-ui';
import { identity, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, useHistory } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { skeletify } from '../../../../../utils';
import {
    DashboardDeleteDialog as DashboardDeleteDialogInj,
    DashboardEditDialog as DashboardEditDialogInj,
} from '../dashboard-dialogs';

const menuItemContainer: SxStyle = {
    height: (theme) => theme.spacing(4),
    width: (theme) => theme.spacing(4),
};

const iconButtonSx: SxStyle = {
    height: (theme) => theme.spacing(4),
    width: (theme) => theme.spacing(4),
    borderRadius: (theme) => theme.spacing(0.5),
};

export const DashboardPageHeaderMenuButton = observer((): JSX.Element => {
    const [DashboardDeleteDialog] = di([DashboardDeleteDialogInj], DashboardPageHeaderMenuButton);
    const [DashboardEditDialog] = di([DashboardEditDialogInj], DashboardPageHeaderMenuButton);

    const history = useHistory();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id: dashboardId, isLoading } = dashboardModel;
    const { permissionsStore } = useStore().coreRootStore;
    const { dashboardConfig } = permissionsStore as PermissionsStore;
    const { update, delete: getDeleteDashboardQuery } = dashboardConfig;

    const [isDashboardDeletingModalOpen, openDashboardDeletingModal, closeDashboardDeletingModal] = useFlag();

    const openEditDashboardModal = (): void => {
        dashboardId &&
            history.push(
                generatePath(routes.dashboardEdit, {
                    dashboardId,
                }),
            );
    };

    const allPermissionsQueries: UseFetchAllPermissionsProps = useMemo(
        () => ({
            allPermissionsQueries: [update(dashboardId), getDeleteDashboardQuery(dashboardId)],
        }),
        [dashboardId],
    );

    const [checkResult] = useFetchAllPermissions(allPermissionsQueries);
    const [isAllowedToEdit, isAllowedToDelete] = checkResult;
    const isPermissionsLoading: boolean = checkResult.every((status) => status === undefined);
    const isAnyAllowed: boolean = checkResult.some(identity);

    const renderActionItems = (hideMenu: VoidFunction): ReactNode[] => {
        const getMenuClickHandler = (key: 'edit' | 'delete'): VoidFunction => {
            return {
                edit: (): void => {
                    hideMenu();
                    openEditDashboardModal();
                },
                delete: (): void => {
                    hideMenu();
                    openDashboardDeletingModal();
                },
            }[key];
        };

        return [
            isAllowedToEdit && (
                <ActionMenuItem messageId="common.edit" onClick={getMenuClickHandler('edit')} key="edit-button" />
            ),
            isAllowedToDelete && (
                <ActionMenuItem messageId="common.delete" onClick={getMenuClickHandler('delete')} key="delete-button" />
            ),
        ];
    };

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick} sx={iconButtonSx} disabled={isLoading}>
                <MoreHoriz />
            </IconButton>
        );
    };

    const actionsMenu: React.ReactNode = skeletify({
        component: <MenuButton renderMenuItems={renderActionItems} renderButton={renderActionsButton} />,
        additionalConditions: [isAnyAllowed],
        isLoading: isPermissionsLoading,
    });

    return (
        <React.Fragment>
            <Grid item sx={menuItemContainer}>
                {actionsMenu}
            </Grid>
            <Route path={routes.dashboardEdit} exact>
                {isAllowedToEdit ? (
                    <DashboardEditDialog />
                ) : (
                    dashboardId && <Redirect to={generatePath(routes.dashboard, { dashboardId })} />
                )}
            </Route>
            {isDashboardDeletingModalOpen && isAllowedToDelete && (
                <DashboardDeleteDialog onCancel={closeDashboardDeletingModal} />
            )}
        </React.Fragment>
    );
});
