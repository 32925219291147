import { TotLocale } from '@platform/front-types';
import headerLogoEn from '../../resources/images/headerLogo_en.svg';
import headerLogoRu from '../../resources/images/headerLogo_ru.svg';
import { PSFeatures } from '../../types';

const getHeaderLogoLangConfig = (lang: string) => ({
    alt: lang === TotLocale.ru ? 'TOT Старт логотип' : 'TOT Start logo',
    image: lang === TotLocale.ru ? headerLogoRu : headerLogoEn,
    style: {
        height: '44px',
        marginBottom: '-15px',
        marginTop: '-17px',
        width: '131px',
    },
});

const features: PSFeatures = {
    footerLogo: false,
    globalSearch: true,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: getHeaderLogoLangConfig(TotLocale.en),
            ru: getHeaderLogoLangConfig(TotLocale.ru),
        },
    },
    helpIcon: false,
    modularNavigation: true,
    phoneNumber: true,
};

export default features;
