import { Layout } from 'react-grid-layout';
import { defaultYCoordinateForLayout } from '../../constants';
import { WidgetType } from '../../types';

export const getInitialLayoutWidgetConfigForCounterWidget = (widgetIndex: string): Layout => {
    return {
        i: widgetIndex,
        x: 0,
        y: defaultYCoordinateForLayout,
        w: 3,
        h: 2,
        minW: 3,
        minH: 2,
    };
};

export const getInitialLayoutWidgetConfigForLinkWidget = (widgetIndex: string): Layout => {
    return {
        i: widgetIndex,
        x: 0,
        y: defaultYCoordinateForLayout,
        w: 3,
        h: 2,
        minW: 3,
        minH: 2,
    };
};

/**
 * Функция генерирует изначальные настройки положения и размеров виджета в сетке
 */
export const getInitialLayoutWidgetConfig = (
    widgetIndex: string,
    breakpoint: string, // пока не используется, но учел момент что конфигурация может зависеть от брейкпоинта
    widgetType: WidgetType,
): Layout => {
    switch (widgetType) {
        case WidgetType.counter: {
            return getInitialLayoutWidgetConfigForCounterWidget(widgetIndex);
        }
        case WidgetType.link: {
            return getInitialLayoutWidgetConfigForLinkWidget(widgetIndex);
        }
        default: {
            return {
                i: widgetIndex,
                x: 0,
                y: defaultYCoordinateForLayout,
                w: 2,
                h: 2,
                minH: 2,
                minW: 2,
            };
        }
    }
};
