import { useEffect, useState } from 'react';

export type UseSettingsFromBackendConfig<T> = {
    apiUrl: string;
    defaultSettings: T;
    getSettingsFromBackend: (apiUrl: string) => Promise<T>;
};

export type UseSettingsFromBackendReturnValue<T> = [T | undefined, () => void];

/**
 * Хук для получения настроек модуля с бэкенда
 *
 * @param config - объект типа {
 *   apiUrl,
 *   defaultSettings - хук вернет этот объект первым аргументом массиве-returnValue если при получении данных настроек с бэкенда произойдет ошибка,
 *   getSettingsFromBackend - функция, в которой происходит запрос на получение данных
 * }
 * @returns [объект с настройками с бэкенда, callback позволяющий принудительно перезапросить настройки с бэкенда]
 *
 */
export function useSettingsFromBackend<SettingsType>(
    config: UseSettingsFromBackendConfig<SettingsType>,
): UseSettingsFromBackendReturnValue<SettingsType> {
    const { apiUrl, defaultSettings, getSettingsFromBackend } = config;
    const [settingsFromBackend, setSettingsFromBackend] = useState<SettingsType>();

    const executeGettingData = (): void => {
        apiUrl &&
            getSettingsFromBackend(apiUrl)
                .then(setSettingsFromBackend)
                .catch(() => setSettingsFromBackend(defaultSettings));
    };

    useEffect(() => {
        executeGettingData();
    }, [apiUrl]);

    return [settingsFromBackend, executeGettingData];
}
