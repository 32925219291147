import { CoreRootStore, ModulesStore as CoreModulesStore } from '@platform/front-core';
import { action, makeObservable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { NavigateModuleInfoDTO, PSModuleDTO } from '../types';

export const modulesStoreObservables = {
    getLoginPageModuleLinksList: action.bound,
    getHomePageModuleLinksList: action.bound,
};

export class ModulesStore extends CoreModulesStore {
    constructor(coreRootStore: CoreRootStore) {
        super(coreRootStore);
        makeObservable(this, modulesStoreObservables);
    }

    getLoginPageModuleLinksList(lang: string): Promise<NavigateModuleInfoDTO[]> {
        return this.api.client((this.apiConfigs as ApiConfigs).getModuleLinksList(lang)).then((r) => r.data);
    }

    getHomePageModuleLinksList(): Promise<PSModuleDTO[]> {
        return this.api.mainInfoClient((this.apiConfigs as ApiConfigs).loadModulesList()).then((r) => r.data);
    }
}

export const getModulesStore = (): any => {
    const [_ModulesStore] = di([ModulesStore], getModulesStore);
    return _ModulesStore;
};
