import { useFlag } from '@platform/front-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type useCalculateLastUpdateType = {
    whenUpdated: string | null;
    diffMinute: string;
    isWithColor: boolean;
    updateLastTime: VoidFunction;
};

type MomentType = moment.Moment;

const timerUpdate = 60000;
const oneDay = 1;
const tenMinute = 10;
const oneHourse = 60;

const momentOfTime = (): MomentType => moment();

export const useCalculateLastUpdate = (): useCalculateLastUpdateType => {
    const [whenUpdated, setWhenUpdated] = useState('');
    const [diffMinute, setDiffMinute] = useState('');
    const [isWithColor, setColorOrange, setColorGray] = useFlag(false);

    const [timeUpdate, setTimeUpdate] = useState(momentOfTime);
    const [timeNow, setTimeNow] = useState(momentOfTime);

    const { formatMessage } = useIntl();

    const lastUpdateСalculation = (timeNow: MomentType, timeUpdate: MomentType): void => {
        const diffDays = timeNow.diff(timeUpdate, 'days');

        if (timeUpdate.format('DD') !== timeNow.format('DD') && diffDays <= oneDay) {
            const yesterday = formatMessage({ id: 'common.yesterday' });
            setDiffMinute(` ${yesterday}, ${timeUpdate.format('HH:mm')} `);
            setWhenUpdated('');
            setColorOrange();
            return;
        }
        if (diffDays < oneDay) {
            const diffMinute = timeNow.diff(timeUpdate, 'minutes');
            const diffHours = timeNow.diff(timeUpdate, 'hours');
            setWhenUpdated(` ${timeUpdate.format('HH:mm')}, `);
            diffMinute > tenMinute ? setColorOrange() : setColorGray();

            if (diffMinute === 0) {
                const lessThanMinuteAgo = formatMessage({ id: 'common.lessThanMinuteAgo' });
                setDiffMinute(` ${lessThanMinuteAgo}`);
                return;
            } else if (diffMinute >= oneHourse) {
                const hoursAgo = formatMessage({ id: 'common.hoursAgo' });
                setDiffMinute(`${diffHours} ${hoursAgo}`);
                return;
            }

            const minuteAgo = formatMessage({ id: 'common.minuteAgo' });
            setDiffMinute(`${diffMinute} ${minuteAgo}`);
            return;
        }
        setDiffMinute(` ${timeUpdate.format('DD.MM.YYYY')}, ${timeUpdate.format('HH:mm')}`);
        setWhenUpdated('');
        setColorOrange();
        return;
    };

    const updateLastTime = (): void => {
        setTimeUpdate(momentOfTime);
        setTimeNow(momentOfTime);
    };

    useEffect(() => {
        lastUpdateСalculation(timeNow, timeUpdate);
    }, [timeNow]);

    useEffect(() => {
        lastUpdateСalculation(timeNow, timeUpdate);

        const timer = setInterval(() => {
            setTimeNow(momentOfTime);
        }, timerUpdate);

        return () => clearInterval(timer);
    }, [timeUpdate]);

    return { whenUpdated, diffMinute, isWithColor, updateLastTime };
};
