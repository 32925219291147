import { useLocale } from '@platform/front-core';
import { ConfirmationDialog } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useDashboardPageContext } from '../../../../../../../hooks';

export type WidgetDeleteDialogProps = {
    onCancel: VoidFunction;
    widgetId?: string;
    sectionId: string;
};

export const WidgetDeleteDialog = observer((props: WidgetDeleteDialogProps): JSX.Element => {
    const { onCancel, widgetId, sectionId } = props;
    const { dashboardModel } = useDashboardPageContext();
    const { deleteWidget } = dashboardModel;

    if (!widgetId) {
        return <React.Fragment />;
    }

    const title = useLocale('widget.phrases.delete', true, {
        widgetNominative: useLocale('widget.single.nominative', false)[0],
    })[0];

    const message = useLocale('widget.phrases.deleteWidgetMessage', true, {
        widgetNominative: useLocale('widget.single.nominative', false)[0],
    })[0];

    const handleDashboardDeleting = async (): Promise<void> => {
        await deleteWidget(widgetId, sectionId);
        onCancel();
    };

    return (
        <ConfirmationDialog
            onCancel={onCancel}
            title={title}
            message={message}
            onConfirm={handleDashboardDeleting}
            open
            id="widget-delete-dialog"
            keepMounted
        />
    );
});
