import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../../../hooks';
import { WidgetEditDialogModel } from '../../../../../../../models';
import { PermissionsStore } from '../../../../../../../stores';
import { DashboardSectionWidgetRouteParams } from '../../../../../../../types';
import { WidgetCreateEditDialog as WidgetCreateEditDialogInj } from './WidgetCreateEditDialog';

export const WidgetEditDialog = observer((): JSX.Element => {
    const [WidgetCreateEditDialog] = di([WidgetCreateEditDialogInj], WidgetEditDialog);
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { sections } = dashboardModel;

    const { dashboardId, sectionId, widgetId } = useParams<DashboardSectionWidgetRouteParams>();

    const rootStore = useStore();

    const { sectionConfig } = rootStore.coreRootStore.permissionsStore as PermissionsStore;
    const updateWidgetQuery = sectionConfig.updateWidget(sectionId);

    const widgetEditDialogModel: WidgetEditDialogModel | undefined = useMemo(() => {
        const sectionModelBySectionId = sections.find((sectionModel) => sectionModel.id === sectionId);

        if (!sectionModelBySectionId) {
            return;
        }
        const { widgets } = sectionModelBySectionId;
        const currentWidget = widgets.find((widget) => widget.id === widgetId);
        if (!currentWidget) {
            return;
        }

        return new WidgetEditDialogModel(dashboardId, sectionId, widgetId, rootStore, currentWidget.load, routes);
    }, [dashboardId, sectionId, widgetId, rootStore, routes]);

    useEffect(() => {
        if (widgetEditDialogModel) {
            widgetEditDialogModel.updateFormInitialValuesFromCurrentWidgetData();
        }
    }, [widgetEditDialogModel]);

    return (
        <React.Fragment>
            {widgetEditDialogModel && (
                <AuthorizationCheck {...updateWidgetQuery}>
                    {(allowed) =>
                        allowed ? (
                            <WidgetCreateEditDialog widgetCreateOrEditDialogModel={widgetEditDialogModel} />
                        ) : (
                            dashboardId && <Redirect to={generatePath(routes.dashboard, { dashboardId })} />
                        )
                    }
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
