import { createLocalesWithoutCases, LocalesTree, ObjectLocales } from '@platform/front-utils';
import { WidgetBackgroundColorViewSetting, WidgetFontSizeViewSetting } from '../../../../types';

export const widget: LocalesTree & ObjectLocales = {
    ...createLocalesWithoutCases('widget', 'widgets'),
    decor: 'Decor',
    icon: 'Icon',
    invalidLink: 'Enter a correct URL. For example: https://example.com/index.html',
    linkPlaceholder: 'Link',
    phrases: {
        add: 'Add {widget}',
        counterWidgetErrorDescription:
            'The registry preset has been deleted or is not accessible. You can edit the {widgetNominative}, delete it, or contact the system administrator',
        delete: 'Delete {widgetNominative}',
        deleteWidgetMessage: 'Do you really want to delete this {widgetNominative}?',
        descriptionPlaceholder: 'Description of {widgetGenitive}',
        editing: 'Edit {widgetGenitive}',
        new: 'New {widgetNominative}',
        noWidgetsPlaceholder: 'No {widgetGenitive} created',
        titlesPlaceholder: 'Title of {widgetGenitive}',
        typePlaceholder: 'Type of {widgetGenitive}',
    },
    presetPlaceholder: 'Preset',
    registryPlaceholder: 'Registry',
    widgetBackgroundColor: {
        buttonTooltip: 'Background color',
        [WidgetBackgroundColorViewSetting.white]: 'White',

        [WidgetBackgroundColorViewSetting.lightRed]: 'Light red',
        [WidgetBackgroundColorViewSetting.lightOrange]: 'Light orange',
        [WidgetBackgroundColorViewSetting.lightChlorine]: 'Light chlorine',
        [WidgetBackgroundColorViewSetting.lightGreen]: 'Light green',
        [WidgetBackgroundColorViewSetting.lightTurquoise]: 'Light turquoise',
        [WidgetBackgroundColorViewSetting.lightBlue]: 'Light blue',
        [WidgetBackgroundColorViewSetting.lightViolet]: 'Light violet',
        [WidgetBackgroundColorViewSetting.lightPink]: 'Light pink',

        [WidgetBackgroundColorViewSetting.red]: 'Red',
        [WidgetBackgroundColorViewSetting.orange]: 'Orange',
        [WidgetBackgroundColorViewSetting.chlorine]: 'Chlorine',
        [WidgetBackgroundColorViewSetting.green]: 'Green',
        [WidgetBackgroundColorViewSetting.turquoise]: 'Turquoise',
        [WidgetBackgroundColorViewSetting.blue]: 'Blue',
        [WidgetBackgroundColorViewSetting.violet]: 'Violet',
        [WidgetBackgroundColorViewSetting.pink]: 'Pink',

        [WidgetBackgroundColorViewSetting.redGradient]: 'Red gradient',
        [WidgetBackgroundColorViewSetting.orangeGradient]: 'Orange gradient',
        [WidgetBackgroundColorViewSetting.chlorineGradient]: 'Chlorine gradient',
        [WidgetBackgroundColorViewSetting.greenGradient]: 'Green gradient',
        [WidgetBackgroundColorViewSetting.turquoiseGradient]: 'Turquoise gradient',
        [WidgetBackgroundColorViewSetting.blueGradient]: 'Blue gradient',
        [WidgetBackgroundColorViewSetting.violetGradient]: 'Violet gradient',
        [WidgetBackgroundColorViewSetting.pinkGradient]: 'Pink gradient',
    },
    widgetFontSize: {
        buttonTooltip: 'Font size',
        [WidgetFontSizeViewSetting.standard]: 'Standard',
        [WidgetFontSizeViewSetting.large]: 'Large',
        [WidgetFontSizeViewSetting.huge]: 'Huge',
    },
    widgetType: {
        counter: 'Counter',
        link: 'Link',
    },
};
