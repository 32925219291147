import { LoadingModel } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { DashboardStore, PermissionsStore, RootStore } from '../../stores';
import { DashboardDTO, DashboardRoutesWithAuxHandlers, SectionDTO } from '../../types';
import { SectionModel } from '../section';
import { DashboardTabsModel } from './dashboard-tabs';

export const dashboardModelObservables = {
    dashboardStore: observable,
    dashboardTabsModel: observable,
    id: observable,
    rootStore: observable,
    routes: observable,
    sections: observable,
    shouldAutoscrollToTheLastListElement: observable,
    shouldHighlightTheLastListElement: observable,
    title: observable,

    delete: action.bound,
    deleteWidget: action.bound,
    filterSectionsByAuthorization: action.bound,
    load: action.bound,
    setMainFields: action.bound,
    setSections: action.bound,
    setSectionsAfterPositionChange: action.bound,
    setShouldAutoscrollToTheLastListElement: action.bound,
    setShouldHighlightTheLastListElement: action.bound,
    silentLoad: action.bound,
};

export class DashboardModel extends LoadingModel {
    protected rootStore: RootStore;
    protected dashboardStore: DashboardStore;
    dashboardTabsModel?: DashboardTabsModel;

    id: string;
    title = '';
    sections: SectionModel[] = [];
    shouldAutoscrollToTheLastListElement = false;
    shouldHighlightTheLastListElement = false;

    routes: DashboardRoutesWithAuxHandlers;

    constructor(
        id: string,
        rootStore: RootStore,
        routes: DashboardRoutesWithAuxHandlers,
        dashboardTabsModel?: DashboardTabsModel,
    ) {
        super();
        this.id = id;
        this.rootStore = rootStore;
        this.dashboardStore = rootStore.dashboardStore;
        this.routes = routes;
        dashboardTabsModel && (this.dashboardTabsModel = dashboardTabsModel);
        makeObservable(this, dashboardModelObservables);
    }

    load(shouldAutoscroll = false): Promise<void> {
        this.startLoading();
        return this.silentLoad(shouldAutoscroll).finally(this.stopLoading);
    }

    async silentLoad(shouldAutoscroll = false): Promise<void> {
        const dto = await this.dashboardStore.getDashboard(this.id);
        const newDto: DashboardDTO = {
            ...dto,
            sections: await this.filterSectionsByAuthorization(dto.sections),
        };
        this.setMainFields(newDto);
        this.setShouldAutoscrollToTheLastListElement(shouldAutoscroll);
    }

    delete(): Promise<void> {
        return this.dashboardStore.deleteDashboard(this.id);
    }

    deleteWidget(widgetId: string, sectionId: string): Promise<void> {
        return this.dashboardStore.deleteWidget(widgetId, sectionId).then(() => {
            const currentSection: SectionModel | undefined = this.sections.find((section) => section.id === sectionId);
            if (!currentSection) {
                return;
            }
            currentSection.load();
        });
    }

    setMainFields(dto: DashboardDTO): void {
        this.title = dto.title;
        this.setSections(dto.sections);
    }

    setSectionsAfterPositionChange(sectionsInNewOrder: SectionModel[]): void {
        const currentSections = this.sections;
        this.sections = sectionsInNewOrder.map((section) => {
            const sectionModelBySectionId = currentSections.find((sectionModel) => section.id === sectionModel.id);
            if (sectionModelBySectionId) {
                return sectionModelBySectionId;
            } else {
                return section;
            }
        });
    }

    async filterSectionsByAuthorization(sections: SectionDTO[]): Promise<SectionDTO[]> {
        const { coreRootStore } = this.rootStore;
        const { permissionsStore, authorizationStore } = coreRootStore;
        const { sectionConfig } = permissionsStore as PermissionsStore;
        const allowedSectionsDTOs = [];
        for (const section of sections) {
            const viewSectionQuery = sectionConfig.view(section.id);
            const allowed = await authorizationStore.check(viewSectionQuery);
            allowed && allowedSectionsDTOs.push(section);
        }
        return allowedSectionsDTOs;
    }

    setSections(sections: SectionDTO[]): void {
        this.sections = sections.map((sectionDTO) => new SectionModel(sectionDTO, this.rootStore));
    }

    setShouldAutoscrollToTheLastListElement(shouldScroll: boolean): void {
        this.shouldAutoscrollToTheLastListElement = shouldScroll;
    }

    setShouldHighlightTheLastListElement(shouldHighlight: boolean): void {
        this.shouldHighlightTheLastListElement = shouldHighlight;
    }
}
