import { Grid, TabsProps } from '@mui/material';
import { AuthorizationCheck, DndRoutedTabs, DndRouteSetting } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useHistory } from 'react-router-dom';
import { CustomButton as CustomButtonInj } from '../../../components/buttons';
import { dashboardMessages } from '../../../customization';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../hooks';
import { DashboardTabsModel } from '../../../models';
import { PermissionsStore } from '../../../stores/permissions-store/PermissionsStore';
import { DashboardCreateDialog as DashboardCreateDialogInj } from '../../dashboards';

export const commonTabsProps: TabsProps = {
    variant: 'scrollable',
    scrollButtons: 'auto',
};

/**
 * @prop {string} dashboardId - Идентификатор дашборда
 * @see {@link DndRouteSetting}
 */
export type DndRouteSettingWithId = DndRouteSetting & {
    dashboardId: string;
};

export type MyDashboardsPageTabsProps = {
    model: DashboardTabsModel;
};

const customButtonSx: SxStyle = {
    minWidth: 'max-content',
};

export const MyDashboardsPageTabs = observer((props: MyDashboardsPageTabsProps): JSX.Element => {
    const [DashboardCreateDialog] = di([DashboardCreateDialogInj], MyDashboardsPageTabs);
    const [CustomButton] = di([CustomButtonInj], MyDashboardsPageTabs);
    const { model } = props;
    const { tabs, reorderList, isThereAnyTabs } = model;

    const { intlStore, permissionsStore } = useStore().coreRootStore;
    const { formatMessageFromDefineMessage } = intlStore;

    const createDashboardButton = formatMessageFromDefineMessage(dashboardMessages.createDashboard);

    const { routes } = useDashboardsAuxContext();
    const history = useHistory();

    const { dashboardModel } = useDashboardPageContext();
    const { id: dashboardId } = dashboardModel;

    const createDashboard = (): void => {
        history.push(generatePath(routes.myDashboardsCreate, { dashboardId }));
    };

    if (!isThereAnyTabs) {
        return <React.Fragment />;
    }

    const { systemConfig } = permissionsStore as PermissionsStore;
    const createDashboardQuery = systemConfig.createDashboard();

    return (
        <Grid container flexWrap="nowrap">
            <DndRoutedTabs
                tabsProps={commonTabsProps}
                tabList={tabs}
                reorder={reorderList}
                direction="horizontal"
                isWithDynamicEllipsisBox={true}
            />
            <AuthorizationCheck {...createDashboardQuery}>
                <Route path={routes.myDashboardsCreate} exact>
                    <DashboardCreateDialog />
                </Route>
                <CustomButton sx={customButtonSx} onClick={createDashboard}>
                    {createDashboardButton}
                </CustomButton>
            </AuthorizationCheck>
        </Grid>
    );
});
