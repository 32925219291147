import debounce from 'lodash.debounce';
import { OnResizeCallback, useResizeObserver } from '@platform/front-utils';
import React, { RefObject, useCallback, useState } from 'react';

const extractNumberFromCSSValueInPixels = (strValue: string): number => {
    if (strValue.indexOf('px') === strValue.length - 2) {
        return Number(strValue.replace('px', ''));
    } else {
        return NaN;
    }
};

/**
 * @prop {number} debounceDelay? - Задержка вызова функции которая производит расчет в ms
 * @prop {RefObject<Element>} elementContainingLineHeightRef - Элемент из стилей которого извлекается свойство 'line-height'
 */
export type UseCalculateLinesCount = {
    debounceDelay?: number;
    elementContainingLineHeightRef?: RefObject<Element>;
};

/**
 * Хук, вычисляющий максимально вмещающееся в блок количество строк
 *
 * ВАЖНО!
 *
 * 1) Элемент, из которого извлекается свойство `line-height` должен иметь значение этого свойства в пикселях
 *
 * 2) Хук реагирует только на изменение размера elementRef, изменение `line-height` не вызывает пересчет
 * количества строк (если такая фича понадобится, то потенциально можно будет использовать
 * MutationObserver + html-атрибут связанный со значением `line-height`)
 *
 * @param {UseCalculateLinesCount} params - Необязательный аргумент, содержащий параметры для хука
 *
 * @returns [
 *      elementRef - ref, который нужно выставить в элемент за изменением размера которого будет следить ResizeObserver,
 *      linesCount - число или undefined, количество отображаемых строк
 * ]
 */
export const useCalculateLinesCount = <T extends HTMLElement>(
    params?: UseCalculateLinesCount,
): [React.RefObject<T>, number | undefined] => {
    const { debounceDelay = 150, elementContainingLineHeightRef } = params ?? {};

    const [linesCount, setLinesCount] = useState<number | undefined>(undefined);

    const resizeCallback = (entry: ResizeObserverEntry): void => {
        try {
            const { contentRect, target } = entry; // entry = elementRef.current

            // Определяем из какого DOM-элемента извлекаем `line-height`
            const elementToGetLineHeightFrom = elementContainingLineHeightRef?.current ?? target;

            // Извлекаем значение свойства из elementToGetLineHeightFrom и извлекаем из него значение в виде Number
            const lineHeight = elementToGetLineHeightFrom
                ? window.getComputedStyle(elementToGetLineHeightFrom)?.getPropertyValue('line-height')
                : undefined;
            const numLineHeight = lineHeight ? extractNumberFromCSSValueInPixels(lineHeight) : undefined;

            if (numLineHeight) {
                // Округляем вниз чтобы не было лишнего текста
                const lines = Math.floor(contentRect.height / numLineHeight);
                setLinesCount(lines);
            } else {
                setLinesCount(undefined);
            }
        } catch (e) {
            console.error(e);
            setLinesCount(undefined);
        }
    };

    const debouncedResize = useCallback<OnResizeCallback>(
        debounce(resizeCallback, debounceDelay, { leading: true, trailing: true }),
        [debounceDelay, elementContainingLineHeightRef?.current],
    );

    const [elementRef] = useResizeObserver<T>(debouncedResize);

    return [elementRef, linesCount];
};
