import { Link } from '@mui/material';
import { coreClientRoute, emailSearch, ForgotPasswordLinkComponentProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../hooks';

export type LoginPageCustomForgotPasswordLinkProps = ForgotPasswordLinkComponentProps;

export const LoginPageCustomForgotPasswordLink = observer(
    (props: LoginPageCustomForgotPasswordLinkProps): JSX.Element => {
        const { email } = props;
        const { popFrontUrl } = useStore().settingsFromBackend;

        let href;
        if (popFrontUrl) {
            const params = `?${emailSearch}=${email}`;
            href = new URL(params, popFrontUrl + coreClientRoute.recoveryPassword).href;
        }

        return (
            <React.Fragment>
                {href && (
                    <Link href={href} underline="none">
                        <FormattedMessage id="authentication.forgotPassword" />
                    </Link>
                )}
            </React.Fragment>
        );
    },
);
