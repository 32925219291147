import { ApiStore, CoreRootStore } from '@platform/front-core';
import { CodeTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs, ApiConfigs } from '../apiConfigs';

export const userStoreObservables = {
    api: observable,
    apiConfigs: observable,

    userRoleList: action.bound,
};

/**
 * Стор с апи для работы с данными пользователя.
 * */
export class UserStore {
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;

    constructor(coreRootStore: CoreRootStore) {
        makeObservable(this, userStoreObservables);
        this.api = coreRootStore.api;
        this.apiConfigs = apiConfigs;
    }

    /**
     * Запросить роли пользователя
     * @returns Промис с массивом ролей пользователя
     */
    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(this.apiConfigs.userRoleList).then((r) => r.data);
    }
}

export const getUserStore = (): any => {
    const [_UserStore] = di([UserStore], getUserStore);
    return _UserStore;
};
