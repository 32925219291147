import { createLocalesWithoutCases, LocalesTree } from '@platform/front-utils';

export const section: LocalesTree = {
    ...createLocalesWithoutCases('section', 'sections'),
    phrases: {
        add: 'Add {section}',
        deleteDialogText: 'The {section} will be deleted with all its widgets',
        deleteDialogTitle: 'Delete {section}',
        editing: 'Edit {sectionGenitive}',
        new: 'New {sectionNominative}',
        titlesPlaceholder: 'Title of {sectionGenitive}',
    },
};
