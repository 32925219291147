import { Warning } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useLocale } from '@platform/front-core';
import { grey, makeSxStylesWithProps, orange } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { stylesForCounterWidgetFontSizeSetting } from '../../../../../../../../constants/widgetStyles';
import { useCalculateLinesCount } from '../../../../../../../../hooks';
import { WidgetModel } from '../../../../../../../../models';
import { WidgetFontSizeViewSetting } from '../../../../../../../../types';

type GetCounterWidgetErrorComponentSxStylesProps = {
    fontSize: WidgetFontSizeViewSetting;
    titleLines: number | undefined;
    descriptionLines: number | undefined;
};

const getCounterWidgetErrorComponentSxStyles = makeSxStylesWithProps(
    (props: GetCounterWidgetErrorComponentSxStylesProps) => {
        const { titleLines, descriptionLines, fontSize } = props;
        return {
            contentContainer: {
                position: 'relative',
                padding: 3,
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                flex: '1 1 auto',
                userSelect: 'none',
            },
            box: {
                height: (theme) => `calc(38% - ${theme.spacing(1)})`,
                display: 'flex',
            },
            titleContainerWrapper: {
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                position: 'relative',
            },
            titleContainer: {
                maxHeight: '100%',
                height: '100%',
                overflow: 'hidden',
            },
            titleTypography: {
                ...stylesForCounterWidgetFontSizeSetting[fontSize].title,

                color: grey[900],
                wordBreak: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: titleLines,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            },
            descriptionContainerWrapper: {
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                flex: '1 1 auto',
                overflow: 'hidden',
                height: (theme) => `calc(50% - ${theme.spacing(1)})`,
            },
            descriptionContainer: {
                maxHeight: '100%',
                overflow: 'hidden',
            },
            descriptionTypography: {
                ...stylesForCounterWidgetFontSizeSetting[fontSize].description,
                wordBreak: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: descriptionLines,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            },
            icon: {
                color: orange[400],
                width: '24px',
                height: '24px',
            },
        };
    },
);

/**
 * @prop {WidgetModel} widgetModel - модель виджета
 */
export type CounterWidgetErrorComponentProps = {
    widgetModel: WidgetModel;
};

/**
 * Компонента отображения ошибки виджета-счетчика
 * @param props {@link CounterWidgetErrorComponentProps}
 */
export const CounterWidgetErrorComponent = observer((props: CounterWidgetErrorComponentProps): JSX.Element => {
    const { widgetModel } = props;
    const { title, viewSettings } = widgetModel;
    const { fontSize } = viewSettings;

    const errorDescription = useLocale('widget.phrases.counterWidgetErrorDescription', true, {
        widgetNominative: useLocale('widget.single.nominative', false)[0],
    })[0];

    const titleTypographyRef = useRef<HTMLDivElement>(null);
    const [titleContainerRef, titleLines] = useCalculateLinesCount<HTMLDivElement>({
        elementContainingLineHeightRef: titleTypographyRef,
    });

    const descriptionTypographyRef = useRef<HTMLDivElement>(null);
    const [descriptionContainerWrapperRef, descriptionLines] = useCalculateLinesCount<HTMLDivElement>({
        elementContainingLineHeightRef: descriptionTypographyRef,
    });

    const sxStyles = getCounterWidgetErrorComponentSxStyles({ titleLines, descriptionLines, fontSize });

    return (
        <Grid item container sx={sxStyles.contentContainer} justifyContent="center">
            <Grid item width="100%" textAlign="center">
                <Warning sx={sxStyles.icon} />
            </Grid>
            <Box sx={sxStyles.box}>
                <Grid item container sx={sxStyles.titleContainerWrapper}>
                    <Grid item sx={sxStyles.titleContainer} ref={titleContainerRef}>
                        <Typography
                            sx={sxStyles.titleTypography}
                            title={title}
                            ref={titleTypographyRef}
                            textAlign="center"
                        >
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                item
                container
                sx={sxStyles.descriptionContainerWrapper}
                ref={descriptionContainerWrapperRef}
                alignSelf="center"
            >
                <Grid item sx={sxStyles.descriptionContainer}>
                    <Typography
                        sx={sxStyles.descriptionTypography}
                        title={title}
                        ref={descriptionTypographyRef}
                        textAlign="center"
                    >
                        {errorDescription}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
});
