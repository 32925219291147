import { LoadingModel } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { defaultWidgetBackgroundColor } from '../../constants';
import { DashboardStore, RootStore } from '../../stores';
import {
    WidgetBackgroundColorViewSetting,
    WidgetBaseDataDTO,
    WidgetBaseDataDTOViewSettings,
    WidgetBaseIconCode,
    WidgetDTO,
    WidgetFontSizeViewSetting,
    WidgetType,
    WidgetTypeSpecificAttributes,
} from '../../types';
import { getDefaultViewSettingsByWidgetType } from '../../utils';

export const widgetModelObservables = {
    dashboardStore: observable,
    sectionId: observable,

    id: observable,
    widgetType: observable,
    title: observable,
    widgetAttributes: observable,
    viewSettings: observable,
    currentColor: observable,

    failed: observable,

    load: action.bound,
    updateWidgetFontSizeViewSettings: action.bound,
    updateWidgetBackgroundViewSetting: action.bound,
    setViewSettingsInLocalStorage: action.bound,
    getViewSettingsFromLocalStorage: action.bound,
    setMainFields: action.bound,
    setCurrentColor: action.bound,
};

export class WidgetModel extends LoadingModel {
    protected dashboardStore: DashboardStore;
    sectionId: string;

    id: string;
    widgetType: WidgetType;
    title = '';
    widgetAttributes?: WidgetTypeSpecificAttributes;
    viewSettings: WidgetBaseDataDTOViewSettings;
    iconCode?: WidgetBaseIconCode;
    currentColor = defaultWidgetBackgroundColor;

    /**
     * Статус ошибки генерации значения для отображения в виджете (например, если пресет виджета-счетчика был удален, то failed будет true
     */
    failed = false;

    constructor(sectionId: string, widgetBaseData: WidgetBaseDataDTO, rootStore: RootStore) {
        super();
        this.sectionId = sectionId;
        this.dashboardStore = rootStore.dashboardStore;

        const { id, title, widgetType, viewSettings } = widgetBaseData;
        this.id = id;
        this.widgetType = widgetType;
        this.title = title;
        this.viewSettings = {
            ...getDefaultViewSettingsByWidgetType(widgetType),
            ...viewSettings,
        };
        this.setCurrentColor(this.viewSettings.backgroundColor);
        makeObservable(this, widgetModelObservables);
    }

    load(): void {
        this.startLoading();
        this.dashboardStore
            .getWidgetInfo(this.sectionId, this.id)
            .then((widgetDTO) => {
                this.setMainFields(widgetDTO);
                this.setCurrentColor(this.viewSettings.backgroundColor);
            })
            .finally(() => {
                this.stopLoading();
            });
    }

    updateWidgetFontSizeViewSettings(fontSize: WidgetFontSizeViewSetting): Promise<void> {
        return this.dashboardStore.updateWidgetViewSettings(this.id, this.sectionId, { fontSize });
    }

    updateWidgetBackgroundViewSetting(backgroundColor: WidgetBackgroundColorViewSetting): Promise<void> {
        return this.dashboardStore.updateWidgetViewSettings(this.id, this.sectionId, { backgroundColor });
    }

    //метод остается для мока бэка
    setViewSettingsInLocalStorage(viewSettings: Partial<WidgetBaseDataDTOViewSettings>): void {
        try {
            const dataFromLocalStorage = window.localStorage.getItem('widgetSettings');
            if (dataFromLocalStorage) {
                const parsedData = JSON.parse(dataFromLocalStorage);
                if (this.id in parsedData) {
                    parsedData[this.id] = {
                        ...parsedData[this.id],
                        ...viewSettings,
                    };
                } else {
                    parsedData[this.id] = viewSettings;
                }
                window.localStorage.setItem('widgetSettings', JSON.stringify(parsedData));
            } else {
                const obj = {
                    [this.id]: {
                        ...viewSettings,
                    },
                };
                window.localStorage.setItem('widgetSettings', JSON.stringify(obj));
            }
        } catch (e) {
            console.error(e);
        }
    }

    //метод остается для мока бэка
    getViewSettingsFromLocalStorage(): Partial<WidgetBaseDataDTOViewSettings> {
        try {
            const viewSettingsFromLocalStorage = window.localStorage.getItem('widgetSettings');
            if (viewSettingsFromLocalStorage) {
                const parsedViewSettings = JSON.parse(viewSettingsFromLocalStorage);
                return (parsedViewSettings[this.id] ?? {}) as Partial<WidgetBaseDataDTOViewSettings>;
            } else {
                return {};
            }
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    setMainFields(dto: WidgetDTO): void {
        const { title, widgetType, widgetAttributes, viewSettings, iconCode } = dto;
        this.widgetType = widgetType;
        this.title = title;
        this.widgetAttributes = widgetAttributes;
        this.viewSettings = {
            ...this.viewSettings,
            ...viewSettings,
        };
        this.iconCode = iconCode;
        if (dto?.failed !== undefined) {
            this.failed = dto.failed;
        }
    }

    setCurrentColor(currentColor: WidgetBackgroundColorViewSetting): void {
        this.currentColor = currentColor;
    }
}
