import { TitlesDTO } from '@platform/front-types';
import { CodeTitleGroup } from '@platform/front-utils';
import { FieldValues } from '@platform/multi-lang-field';
import { SectionDTO } from './section';
import { WidgetBaseIconCode } from './widget';

export enum DashboardCreateEditFields {
    titles = 'titles',
    roles = 'roles',
    publishAsDraft = 'publishAsDraft',
    isInitial = 'isInitial',
}

/**
 * DTO с данными ДЛЯ создания дашборда
 * */
export type DashboardCreationInfoDTO = {
    roles: CodeTitleGroup[];
};

/**
 * DTO с данными нового дашборда
 * */
export type DashboardCreateUpdateDTO = {
    titles: TitlesDTO;
    roles: CodeTitleGroup[];
    publishAsDraft: boolean;
    isInitial: boolean;
    iconCode?: WidgetBaseIconCode;
};

export type DashboardDTO = {
    id: string;
    title: string;
    roles: CodeTitleGroup[];
    sections: SectionDTO[];
};

export type DashboardCreateFormValues = {
    titles: FieldValues[];
    roles: CodeTitleGroup[];
    publishAsDraft: boolean;
    isInitial: boolean;
    iconCode?: WidgetBaseIconCode;
};

export type DashboardRouteParams = {
    dashboardId: string;
};

export type DashboardSectionRouteParams = DashboardRouteParams & {
    sectionId: string;
};

export type DashboardSectionWidgetRouteParams = DashboardSectionRouteParams & {
    widgetId: string;
};

export enum DashboardPageParentRouteName {
    myDashboards = 'my-dashboards',
    allDashboards = 'dashboards',
}

export type DashboardRoutes = {
    dashboard: string;
    dashboards: string;
    dashboardCreate: string;
    myDashboardsCreate: string;
    dashboardEdit: string;
    dashboardCreateSection: string;
    dashboardEditSection: string;
    dashboardSectionCreateWidget: string;
    dashboardSectionEditWidget: string;
    dashboardRedirectAfterDelete: string;
};

export enum DashboardPagesRoutesFiltering {
    singleDashboard = 'singleDashboard',
}

/**
 * @prop {() => string[]} asArray - Функция, возвращающая роуты в виде массива.
 * @prop {(type: DashboardPagesRoutesFiltering) => string[]} getRoutesByFilter - Функция, возвращающая отфильтрованный по какому-либо критерию массив роутов.
 * @see {@link DashboardRoutes}
 */
export type DashboardRoutesWithAuxHandlers = DashboardRoutes & {
    asArray: () => string[];
    getRoutesByFilter: (type: DashboardPagesRoutesFiltering) => string[];
};

export type DashboardTabDTO = {
    dashboardId: string;
    title: string;
    isInitial: boolean;
};
