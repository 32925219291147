import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export const WidgetCreateEditDialogContentSkeleton = (): JSX.Element => {
    return (
        <React.Fragment>
            <Grid item>
                <Skeleton height={80} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={80} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="100%" />
            </Grid>
        </React.Fragment>
    );
};
