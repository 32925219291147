import { keyframes } from '@mui/material';
import { blue } from '@platform/front-ui';

export const newWidgetOrSectionHighlightAnimation = keyframes`
    from {
        border: 1px solid ${blue[600]};
    }
    90% {
        border: 1px solid ${blue[100]};
    }
    to {
        border-color: transparent;
    }
`;
