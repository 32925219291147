import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const widgetMessages = defineMessages({
    addWidget: {
        id: 'widget.phrases.add',
        values: {
            widget: <FormattedMessage id="widget.single.nominative" />,
        },
    },
    createDialogTitle: {
        id: 'widget.phrases.new',
        values: {
            widgetNominative: <FormattedMessage id="widget.single.nominative" />,
        },
    },
    editDialogTitle: {
        id: 'widget.phrases.editing',
        values: {
            widgetGenitive: <FormattedMessage id="widget.single.genitive" />,
        },
    },
    titlesPlaceholder: {
        id: 'widget.phrases.titlesPlaceholder',
        values: {
            widgetGenitive: <FormattedMessage id="widget.single.genitive" />,
        },
    },
    descriptionPlaceholder: {
        id: 'widget.phrases.descriptionPlaceholder',
        values: {
            widgetGenitive: <FormattedMessage id="widget.single.genitive" />,
        },
    },
    typePlaceholder: {
        id: 'widget.phrases.typePlaceholder',
        values: {
            widgetGenitive: <FormattedMessage id="widget.single.genitive" />,
        },
    },
    registryPlaceholder: {
        id: 'widget.registryPlaceholder',
    },
    presetPlaceholder: {
        id: 'widget.presetPlaceholder',
    },
    linkPlaceholder: {
        id: 'widget.linkPlaceholder',
    },
    noWidgetsPlaceholder: {
        id: 'widget.phrases.noWidgetsPlaceholder',
        values: {
            widgetGenitive: <FormattedMessage id="widget.plural.genitive" />,
        },
    },
    errorWidgetDescription: {
        id: 'widget.phrases.counterWidgetErrorDescription',
        values: {
            widgetNominative: <FormattedMessage id="widget.single.nominative" />,
        },
    },
});
