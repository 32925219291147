import { IntlStore } from '@platform/front-core';
import { ServerErrorsModel } from '@platform/front-utils';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { DashboardStore, RootStore } from '../../stores';
import {
    DashboardRoutes,
    WidgetCreateEditAllPossibleFields,
    WidgetCreateEditFormValues,
    WidgetCreationInfoSettings,
    WidgetType,
} from '../../types';
import { getDefaultWidgetCreateEditFormInitialValues, transformWidgetCreationInfoForFrontend } from '../../utils';

export const widgetCreateEditDialogModelObservables = {
    dashboardStore: observable,
    intlStore: observable,
    history: observable,

    dashboardId: observable,
    sectionId: observable,
    serverErrors: observable,

    formInitialValues: observable,
    isFormInitialValuesLoading: observable,

    selectedWidgetType: observable,
    settingsForSelectedWidgetType: observable,
    isSettingsForSelectedWidgetTypeLoading: observable,

    routes: observable,
    widgetTypesThatRequireLoadingCreationInfo: observable,

    onCancel: action.bound,
    loadWidgetCreationInfoSettings: action.bound,
    setFormInitialValues: action.bound,
    setIsFormInitialValuesLoading: action.bound,
    setSettingsForSelectedWidgetType: action.bound,
    setIsSettingsForSelectedWidgetTypeLoading: action.bound,
    setSelectedWidgetType: action.bound,
};

export const widgetCreateEditDialogModelAbstractObservables = {
    onSubmit: action.bound,
};

/**
 * Класс, содержащий общие данные и логику диалога создания/редактирования виджета
 */
export abstract class WidgetCreateEditDialogModel {
    abstract onSubmit(formValues: WidgetCreateEditFormValues): Promise<void>;

    dashboardStore: DashboardStore;
    intlStore: IntlStore;
    history: History;

    dashboardId: string;
    sectionId: string;
    serverErrors = new ServerErrorsModel<keyof WidgetCreateEditAllPossibleFields>();

    formInitialValues: WidgetCreateEditFormValues;
    isFormInitialValuesLoading = false;

    selectedWidgetType: WidgetType | null = null;
    settingsForSelectedWidgetType: WidgetCreationInfoSettings | null = null;
    isSettingsForSelectedWidgetTypeLoading = false;

    routes: DashboardRoutes;
    widgetTypesThatRequireLoadingCreationInfo = [WidgetType.counter];

    constructor(dashboardId: string, sectionId: string, rootStore: RootStore, routes: DashboardRoutes) {
        const { coreRootStore, dashboardStore } = rootStore;
        this.dashboardStore = dashboardStore;
        this.history = coreRootStore.history;
        this.intlStore = coreRootStore.intlStore;
        this.dashboardId = dashboardId;
        this.sectionId = sectionId;
        this.routes = routes;
        this.formInitialValues = getDefaultWidgetCreateEditFormInitialValues(this.intlStore);
        makeObservable(this, widgetCreateEditDialogModelObservables);
    }

    onCancel(): void {
        this.dashboardId && this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
    }

    loadWidgetCreationInfoSettings(): void {
        if (
            this.selectedWidgetType &&
            this.widgetTypesThatRequireLoadingCreationInfo.includes(this.selectedWidgetType)
        ) {
            this.setIsSettingsForSelectedWidgetTypeLoading(true);
            this.dashboardStore
                .getWidgetCreationInfoSettingsByWidgetType(this.sectionId, this.selectedWidgetType)
                .then((dto) => {
                    const transformedWidgetCreationSettings = transformWidgetCreationInfoForFrontend(
                        this.selectedWidgetType,
                        dto,
                    );
                    this.setSettingsForSelectedWidgetType(transformedWidgetCreationSettings);
                })
                .finally(() => this.setIsSettingsForSelectedWidgetTypeLoading(false));
        } else if (!this.selectedWidgetType) {
            this.setSettingsForSelectedWidgetType(null);
        }
    }

    setFormInitialValues(initialValues: WidgetCreateEditFormValues): void {
        this.formInitialValues = initialValues;
    }

    setIsFormInitialValuesLoading(isLoading: boolean): void {
        this.isFormInitialValuesLoading = isLoading;
    }

    setSettingsForSelectedWidgetType(settingsForSelectedWidgetType: WidgetCreationInfoSettings | null): void {
        this.settingsForSelectedWidgetType = settingsForSelectedWidgetType;
    }

    setIsSettingsForSelectedWidgetTypeLoading(isLoading: boolean): void {
        this.isSettingsForSelectedWidgetTypeLoading = isLoading;
    }

    setSelectedWidgetType(widgetType: WidgetType | null): void {
        this.selectedWidgetType = widgetType;
    }
}
