import { LoadingModel } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { Layout, Layouts } from 'react-grid-layout';
import { DashboardStore, RootStore } from '../../stores';
import { ReactGridLayoutBreakpoint, SectionDTO, WidgetBaseDataDTO } from '../../types';
import { getDefaultLayouts, getInitialLayoutWidgetConfig } from '../../utils';
import { WidgetModel } from '../widget';

export const sectionModelObservables = {
    rootStore: observable,
    dashboardStore: observable,

    id: observable,
    title: observable,
    widgets: observable,
    layouts: observable,

    newWidgetId: observable,
    shouldAutoscrollToNewWidget: observable,
    shouldHighlightNewWidget: observable,

    load: action.bound,
    handleNewWidgetIdUpdate: action.bound,
    getWidgetById: action.bound,
    getLayoutsSyncedWithSectionWidgets: action.bound,
    addMissingWidgetsToLayouts: action.bound,
    removeDeletedWidgetsFromLayouts: action.bound,
    updateWidgetPositions: action.bound,
    deleteSection: action.bound,
    setLayouts: action.bound,
    setMainFields: action.bound,
    setWidgets: action.bound,
    setShouldAutoscrollToNewWidget: action.bound,
    setShouldHighlightNewWidget: action.bound,
    setNewWidgetId: action.bound,
};

export class SectionModel extends LoadingModel {
    protected rootStore: RootStore;
    protected dashboardStore: DashboardStore;

    id: string;
    title = '';
    widgets: WidgetModel[] = [];
    layouts: Layouts = getDefaultLayouts();

    newWidgetId?: string;
    shouldAutoscrollToNewWidget = false;
    shouldHighlightNewWidget = false;

    constructor(sectionDTO: SectionDTO, rootStore: RootStore) {
        super();
        this.id = sectionDTO.id;
        this.rootStore = rootStore;
        this.dashboardStore = rootStore.dashboardStore;
        this.setMainFields(sectionDTO);
        this.stopLoading();
        makeObservable(this, sectionModelObservables);
    }

    load(shouldScrollToNewWidgetAndHighlight = false): void {
        this.startLoading();
        this.dashboardStore
            .getSectionInfo(this.id)
            .then((sectionDTO: SectionDTO) => {
                if (shouldScrollToNewWidgetAndHighlight) {
                    this.handleNewWidgetIdUpdate(sectionDTO.widgets);
                }
                this.setMainFields(sectionDTO);
            })
            .finally(() => {
                this.stopLoading();
            });
    }

    handleNewWidgetIdUpdate(newWidgets: WidgetBaseDataDTO[]): void {
        const widgetIdsBeforeReload = new Set(this.widgets.map((widgetModel) => widgetModel.id));
        const newWidgetIds = newWidgets
            .map((widgetBase: WidgetBaseDataDTO) => widgetBase.id)
            .filter((widgetId) => !widgetIdsBeforeReload.has(widgetId));
        const newWidgetId = newWidgetIds[newWidgetIds.length - 1];
        this.setNewWidgetId(newWidgetId);
        this.setShouldAutoscrollToNewWidget(!!newWidgetId);
    }

    getWidgetById(widgetId: string): WidgetModel | undefined {
        return this.widgets.find((widget: WidgetModel) => widget.id === widgetId);
    }

    /**
     * Метод, синхронизирующий текущее состояние данных виджетов с layouts
     */
    getLayoutsSyncedWithSectionWidgets(layouts: Layouts): Layouts {
        const layoutsCopy: Layouts = { ...getDefaultLayouts(), ...layouts };
        const widgetIdsFromLayouts = new Set(
            Object.values(ReactGridLayoutBreakpoint).flatMap((breakpoint) => {
                return layoutsCopy[breakpoint].map((layoutItem: Layout) => layoutItem.i);
            }),
        );
        const layoutsWithoutDeletedWidgets = this.removeDeletedWidgetsFromLayouts(layoutsCopy, widgetIdsFromLayouts);
        const syncedLayouts = this.addMissingWidgetsToLayouts(layoutsWithoutDeletedWidgets, widgetIdsFromLayouts);
        return syncedLayouts;
    }

    /**
     * Метод, добавляющий отсутствующие в layouts данные размеров и местоположения виджетов (Layout из react-grid),
     * предварительно синхронизируя layouts с виджетами, отсеевая layouts, для которых нет виджетов.
     *
     * Кейс: имеем пустой раздел -> создаем раздел -> с бэка приходят данные нового виджета, но данных о его местоположении нет
     * Для того чтобы новый виджет появился в сетке добавляем в layouts для каждого брейкпоинта объект Layout
     * (это происходит для каждого виджета, Layout которого отсутствует в layouts)
     */
    addMissingWidgetsToLayouts(layouts: Layouts, widgetIdsFromLayouts: Set<string>): Layouts {
        try {
            const layoutsCopy: Layouts = JSON.parse(JSON.stringify(layouts));
            const missingWidgetsInLayout = this.widgets.filter((widget) => !widgetIdsFromLayouts.has(widget.id));
            missingWidgetsInLayout.forEach((widget) => {
                const { id, widgetType } = widget;
                Object.values(ReactGridLayoutBreakpoint).forEach((breakpoint) => {
                    layoutsCopy[breakpoint].push(getInitialLayoutWidgetConfig(id, breakpoint, widgetType));
                });
            });
            return layoutsCopy;
        } catch (error) {
            console.error(error);
            return layouts;
        }
    }

    /**
     * Метод, удаляющий присутствующие в layouts данные размеров и местоположения виджетов (Layout из react-grid), которые были удалены (т.е отстутствуют в this.widgets)
     *
     * Кейс: есть виджеты 1,2,3. Данные о каждом виджете присутствуют в layouts -> удаляем виджет 2 -> после перезапроса данных
     * раздела в layouts присутствуют данные виджета 3, поэтому их нужно удалить
     */
    removeDeletedWidgetsFromLayouts(layouts: Layouts, widgetIdsFromLayouts: Set<string>): Layouts {
        try {
            const layoutsCopy: Layouts = JSON.parse(JSON.stringify(layouts));
            const widgetIdsFromSectionData = new Set(this.widgets.map((widget) => widget.id));
            const deletedWidgetIdsThatArePresentInLayouts = new Set(
                Array.from(widgetIdsFromLayouts.values()).filter(
                    (widgetIdFromLayout) => !widgetIdsFromSectionData.has(widgetIdFromLayout),
                ),
            );
            Object.values(ReactGridLayoutBreakpoint).forEach((breakpoint) => {
                layoutsCopy[breakpoint] = layoutsCopy[breakpoint].filter((layoutItem) => {
                    return !deletedWidgetIdsThatArePresentInLayouts.has(layoutItem.i);
                });
            });
            return layoutsCopy;
        } catch (error) {
            console.error(error);
            return layouts;
        }
    }

    updateWidgetPositions(): void {
        this.dashboardStore.updateWidgetPositions(this.id, this.layouts);
    }

    deleteSection(): Promise<void> {
        return this.dashboardStore.deleteSection(this.id);
    }

    setMainFields(dto: SectionDTO): void {
        const { title, widgets, layouts } = dto;
        this.title = title;
        this.setWidgets(widgets);
        this.setLayouts(this.getLayoutsSyncedWithSectionWidgets(layouts ?? getDefaultLayouts()));
    }

    setLayouts(layouts: Layouts): void {
        this.layouts = layouts;
    }

    setWidgets(widgetsBaseData: WidgetBaseDataDTO[]): void {
        this.widgets = widgetsBaseData.map((widgetBaseData): WidgetModel => {
            const widgetModel = new WidgetModel(this.id, widgetBaseData, this.rootStore);
            widgetModel.load();
            return widgetModel;
        });
    }

    setNewWidgetId(newWidgetId?: string): void {
        this.newWidgetId = newWidgetId;
    }

    setShouldAutoscrollToNewWidget(shouldAutoscroll: boolean): void {
        this.shouldAutoscrollToNewWidget = shouldAutoscroll;
    }

    setShouldHighlightNewWidget(shouldHighlight: boolean): void {
        this.shouldHighlightNewWidget = shouldHighlight;
    }
}
