import { TitlesDTO } from '@platform/front-types';
import { CodeTitleGroup, IdTitle, IdTitleNull } from '@platform/front-utils';
import { FieldValues } from '@platform/multi-lang-field';
import {
    WidgetBaseDataDTOWithWidgetAttributes,
    WidgetCreateUpdateDTOBase,
    WidgetType,
    WidgetUpdateInfoDTOBase,
} from './common';

/**
 *  Название полей формы создания/редактирования виджета-счетчика (если значение поля формы находится в объекте, то указывается путь до поля)
 */
export enum CounterWidgetCreateEditFormSettingsFields {
    registry = 'settings.registry',
    preset = 'settings.preset',
    descriptions = 'settings.descriptions',
}
/**
 *  Название ключей объекта значения полей формы создания/редактирования виджета-счетчика
 */
export enum CounterWidgetCreateEditFormSettingsValueKeys {
    registry = 'registry',
    preset = 'preset',
    descriptions = 'descriptions',
}

/**
 * Тип данных для описания данных реестра, который приходит с бэка для последующего преобразования в опцию селекта "Реестр" при создании/редактировании виджета-счетчика
 */
export type RegistrySelectOptionDataItem = {
    code: string;
    title: string;
    moduleCode: string;
    moduleTitle: string;
    apiUrlPrefix: string;
    linkUrl: string;
};

export type CodeTitleGroupWithRegistryUrlData = CodeTitleGroup &
    Pick<RegistrySelectOptionDataItem, 'apiUrlPrefix' | 'linkUrl'>;

/**
 *  Тип, содержащий итоговые данные виджета-счетчика, отправляющегося на бэк при сохранении/редактировании виджета
 */
export type CounterWidgetCreateUpdateDTO = WidgetCreateUpdateDTOBase<
    WidgetType.counter,
    CounterWidgetCreateUpdateSettings
>;

/**
 *  Тип данных, описывающий данные существующего виджета-счетчика используемые для его редактирования
 */
export type CounterWidgetUpdateInfoDTO = WidgetUpdateInfoDTOBase<CounterWidgetCreateUpdateSettings>;

/**
 *  Тип данных, описывающий данные присылаемые бэком для генерации опций для селектов "Реестр" и "Пресет" формы создания/редактирования виджета-счетчика
 */
export type CounterWidgetCreationInfoSettingsFromBackend = {
    registries: RegistrySelectOptionDataItem[];
    presets: Partial<Record<string, IdTitle[]>>;
};

/**
 *  Тип данных, описывающий данные типа WidgetCreationInfoSettingsForTypeCounterDTO, но преобразованный в формат для использования компонентам формы на фронте
 */
export type CounterWidgetCreationInfoSettings = {
    registries: CodeTitleGroupWithRegistryUrlData[];
    presets: Partial<Record<string, IdTitle[]>>;
};

/**
 *  Тип данных специфичных для виджета-счетчика полей формы создания/редактирования
 */
export type CounterWidgetCreateEditFormSettingsValues = {
    [CounterWidgetCreateEditFormSettingsValueKeys.registry]: CodeTitleGroupWithRegistryUrlData | null;
    [CounterWidgetCreateEditFormSettingsValueKeys.preset]: IdTitleNull;
    [CounterWidgetCreateEditFormSettingsValueKeys.descriptions]: FieldValues[];
};

/**
 *  Тип специфичных для виджета-счетчика настроек, отправляемых на бэк при создании/редактирования виджета-счетчика
 */
export type CounterWidgetCreateUpdateSettings = {
    registry: Pick<RegistrySelectOptionDataItem, 'code' | 'moduleCode' | 'apiUrlPrefix' | 'linkUrl'>;
    presetId: string;
    descriptions: TitlesDTO;
};

/**
 *  Тип специфичных для виджета-счетчика данных
 */
export type CounterWidgetSpecificAttributes = {
    count: number;
    url: string;
    description: string;
};

/**
 *  Тип данных счетчика-виджета
 */
export type CounterWidgetDTO = WidgetBaseDataDTOWithWidgetAttributes<
    WidgetType.counter,
    CounterWidgetSpecificAttributes
>;
