import { MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useFetchAllPermissions, UseFetchAllPermissionsProps, useLocale } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { ActionMenuItem, ConfirmationDialog, grey, MenuButton } from '@platform/front-ui';
import { identity, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { sectionMessages } from '../../../../../customization';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { SectionModel } from '../../../../../models';
import { PermissionsStore } from '../../../../../stores';
import { skeletify } from '../../../../../utils';

const iconButtonSx: SxStyle = {
    backgroundColor: grey[100],
    pt: (theme) => theme.spacing(1),
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
    borderRadius: (theme) => theme.spacing(0.5),
};

export type SectionListItemMenuButtonProps = {
    sectionModel: SectionModel;
};

export const SectionListItemMenuButton = observer((props: SectionListItemMenuButtonProps): JSX.Element => {
    const { sectionModel } = props;
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id: dashboardId, load: reloadDashboardData } = dashboardModel;
    const { id: sectionId, deleteSection } = sectionModel;
    const { permissionsStore, intlStore } = useStore().coreRootStore;
    const { formatMessageFromDefineMessage } = intlStore;
    const { sectionConfig } = permissionsStore as PermissionsStore;
    const { update: getUpdateSectionQuery, delete: getDeleteSectionQuery } = sectionConfig;
    const history = useHistory();

    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useFlag();

    const onDeleteSectionConfirm = (): Promise<void> => {
        return deleteSection()
            .then(() => {
                reloadDashboardData();
            })
            .finally(() => {
                closeDeleteDialog();
            });
    };

    const allPermissionsQueries: UseFetchAllPermissionsProps = useMemo(
        () => ({
            allPermissionsQueries: [getUpdateSectionQuery(sectionId), getDeleteSectionQuery(sectionId)],
        }),
        [sectionId],
    );

    const [checkResult] = useFetchAllPermissions(allPermissionsQueries);
    const [isAllowedToEdit, isAllowedToDelete] = checkResult;
    const isPermissionsLoading: boolean = checkResult.every((status) => status === undefined);
    const isAnyAllowed: boolean = checkResult.some(identity);

    const renderActionItems = (hideMenu: VoidFunction): ReactNode[] => {
        const onEditSectionClick = (): void => {
            hideMenu();
            dashboardId &&
                sectionId &&
                history.push(generatePath(routes.dashboardEditSection, { dashboardId, sectionId }));
        };

        const onDeleteSectionClick = (): void => {
            hideMenu();
            openDeleteDialog();
        };

        return [
            isAllowedToEdit && (
                <ActionMenuItem messageId="common.edit" onClick={onEditSectionClick} key="edit-button" />
            ),
            isAllowedToDelete && (
                <ActionMenuItem messageId="common.delete" onClick={onDeleteSectionClick} key="delete-button" />
            ),
        ];
    };

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick} sx={iconButtonSx}>
                <MoreHoriz />
            </IconButton>
        );
    };

    const actionsMenu: React.ReactNode = skeletify({
        component: <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems} />,
        additionalConditions: [isAnyAllowed],
        isLoading: isPermissionsLoading,
    });

    const deleteConfirmationText = useLocale('section.phrases.deleteDialogText', true, {
        section: useLocale('section.single.nominative', false)[0],
    })[0];

    return (
        <React.Fragment>
            <ConfirmationDialog
                keepMounted
                id="delete-section-dialog"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={onDeleteSectionConfirm}
                title={formatMessageFromDefineMessage(sectionMessages.deleteDialogTitle)}
                message={deleteConfirmationText}
            />
            {actionsMenu}
        </React.Fragment>
    );
});
