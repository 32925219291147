import { getRuTitleInitialValue } from '@platform/multi-lang-field';
import { LinkWidgetCreateEditFormSettingsValueKeys, LinkWidgetCreateEditFormSettingsValues } from '../../../types';

/**
 * Функция, генерирующая значения специфичных для виджета-ссылки полей формы создания/редактирования виджета
 */
export const getLinkWidgetCreateEditDialogFormInitialValues = (): LinkWidgetCreateEditFormSettingsValues => ({
    [LinkWidgetCreateEditFormSettingsValueKeys.descriptions]: getRuTitleInitialValue(),
    [LinkWidgetCreateEditFormSettingsValueKeys.link]: '',
});
