import { AppHeaderLinkData, HeaderLinksStore as HeaderLinksStoreInj } from '@platform/front-core';
import { AuthorizationCheckQuery } from '@platform/front-types';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';
import { dashboardMessages } from '../customization';
import { DashboardPageParentRouteName } from '../types';
import { PermissionsStore } from './permissions-store';

export const getPSHeaderMainLinks = (permissionStore: PermissionsStore): AppHeaderLinkData[] => [
    {
        key: DashboardPageParentRouteName.myDashboards,
        to: clientRoute.myDashboards.dashboards,
        messageDescriptor: dashboardMessages.myDashboardsTitle,
        query: permissionStore.systemConfig.viewDashboardList() as AuthorizationCheckQuery,
        disableLinkPath: {
            path: clientRoute.myDashboards.dashboards,
            exact: false,
        },
    },
    {
        key: DashboardPageParentRouteName.allDashboards,
        to: clientRoute.allDashboards.dashboards,
        messageDescriptor: dashboardMessages.registryTitle,
        query: permissionStore.systemConfig.viewDashboardRegistry() as AuthorizationCheckQuery,
    },
];

export class HeaderLinksStore extends HeaderLinksStoreInj {
    get mainLinks(): AppHeaderLinkData[] {
        return getPSHeaderMainLinks(this.permissionsStore as PermissionsStore);
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};
