import { Grid } from '@mui/material';
import { grey, makeSxStylesWithProps, orange, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

export type LastUpdateTimeProps = {
    whenUpdated: string | null;
    diffMinute: string;
    isWithColor: boolean;
};

type StylesSxProps = {
    isWithColor: boolean;
};

const getStylesSx = makeSxStylesWithProps((props: StylesSxProps) => {
    const { isWithColor } = props;

    const color = isWithColor ? orange[400] : 'inherit';
    const defaultTextStyle = { display: 'inline', fontSize: '14px' };

    return {
        wrapper: {
            width: '100%',
            color: grey[500],
            fontSize: '14px',
            paddingRight: 0.5,
            alignSelf: 'center',
        },
        typography: {
            ...defaultTextStyle,
            color: grey[500],
        },
        typographyBold: {
            ...defaultTextStyle,
            color,
            fontWeight: 500,
        },
    };
});

export const LastUpdateTime = observer((props: LastUpdateTimeProps): JSX.Element => {
    const { whenUpdated, diffMinute, isWithColor } = props;

    const sxStyles = getStylesSx({ isWithColor });
    const isWithWhenUpdated = whenUpdated?.length !== 0;

    return (
        <Grid item sx={sxStyles.wrapper}>
            <FormattedMessage id="common.lastUpdate" />
            {isWithWhenUpdated && <Typography sx={sxStyles.typography}>{whenUpdated}</Typography>}
            <Typography sx={sxStyles.typographyBold}>{diffMinute}</Typography>
        </Grid>
    );
});
