import { CodeTitleNull } from '@platform/front-types';
import React from 'react';
import { di } from 'react-magnetic-di';
import { WidgetCreateEditDialogModel } from '../../../../../../../models';
import { WidgetType } from '../../../../../../../types';
import {
    CounterWidgetDialogFormFields as CounterWidgetDialogFormFieldsInj,
    LinkWidgetDialogFormFields as LinkWidgetDialogFormFieldsInj,
} from './components';

/**
 * Описание функции
 * @param {CodeTitleNull} type - тип виджета
 * @param {T extends WidgetCreateEditDialogModel} widgetCreateOrEditDialogModel - модель диалогового окна (создание или редактирование)
 */
export const getWidgetTypeSpecificFieldsByType = <T extends WidgetCreateEditDialogModel>(
    type: CodeTitleNull,
    widgetCreateOrEditDialogModel: T,
): JSX.Element => {
    const [CounterWidgetDialogFormFields] = di([CounterWidgetDialogFormFieldsInj], getWidgetTypeSpecificFieldsByType);
    const [LinkWidgetDialogFormFields] = di([LinkWidgetDialogFormFieldsInj], getWidgetTypeSpecificFieldsByType);

    switch (type?.code) {
        case WidgetType.counter: {
            return <CounterWidgetDialogFormFields widgetCreateOrEditDialogModel={widgetCreateOrEditDialogModel} />;
        }
        case WidgetType.link: {
            return <LinkWidgetDialogFormFields widgetCreateOrEditDialogModel={widgetCreateOrEditDialogModel} />;
        }
        default: {
            return <React.Fragment />;
        }
    }
};
