import { Grid, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { getBackgroundColorByBackgroundColorViewSetting } from '../../../../../../../../constants';
import { WidgetBackgroundColorViewSetting } from '../../../../../../../../types';
import { ColorCircle as ColorCircleInj } from './ColorCircle';

export type CircleProps = {
    colorSetting: WidgetBackgroundColorViewSetting;
    handleClick: VoidFunction;
    currentColor: WidgetBackgroundColorViewSetting;
};

export const Circle = observer((props: CircleProps): JSX.Element => {
    const [ColorCircle] = di([ColorCircleInj], Circle);
    const { colorSetting, handleClick, currentColor } = props;
    const { formatMessage } = useIntl();

    const colorConfig = getBackgroundColorByBackgroundColorViewSetting(colorSetting);
    const { backgroundColor, backgroundColorName } = colorConfig;
    const tooltipText = formatMessage({ id: `widget.widgetBackgroundColor.${backgroundColorName}` });
    const isCurrentColor = currentColor === colorSetting;

    return (
        <Tooltip placement="bottom" title={tooltipText} followCursor>
            <Grid item onClick={handleClick} role="menuitem" tabIndex={0}>
                <ColorCircle backgroundColor={backgroundColor} isCurrentColor={isCurrentColor} />
            </Grid>
        </Tooltip>
    );
});
