import UpdateIcon from '@mui/icons-material/Update';
import { ButtonProps, Grid, Skeleton } from '@mui/material';
import { ObjectHeaderLayout, ObjectHeaderLayoutProps, useFetchAllPermissions } from '@platform/front-core';
import { EllipsisBox, makeSxStyles, Typography } from '@platform/front-ui';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import { WidgetModel } from 'models';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    CreateSectionButton as CreateSectionButtonInj,
    CustomButton as CustomButtonInj,
    ServerBreadcrumbs as ServerBreadcrumbsInj,
} from '../../../../../components';
import { LastUpdateTime } from '../../../../../components/last-update-time/LastUpdateTime';
import { useCalculateLastUpdate, useDashboardPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { BreadcrumbsEntity } from '../../../../../types';
import { DashboardPageHeaderMenuButton as DashboardPageHeaderMenuButtonInj } from './DashboardPageHeaderMenuButton';

const sxStyles = makeSxStyles({
    title: {
        fontSize: '24px',
        mb: '12px',
        lineHeight: '30px',
    },
});

export const DashboardPageHeader = observer((): JSX.Element => {
    const [DashboardPageHeaderMenuButton] = di([DashboardPageHeaderMenuButtonInj], DashboardPageHeader);
    const [CreateSectionButton] = di([CreateSectionButtonInj], DashboardPageHeader);
    const [CustomButton] = di([CustomButtonInj], DashboardPageHeader);
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], DashboardPageHeader);
    const { dashboardConfig } = useStore().coreRootStore.permissionsStore as PermissionsStore;
    const { dashboardModel } = useDashboardPageContext();
    const { id: dashboardId, title, isLoading, sections, load } = dashboardModel;

    const allPermissionsQueries = useMemo(() => {
        return {
            allPermissionsQueries: [dashboardConfig.createSection(dashboardId)],
        };
    }, [dashboardId]);

    const [checkResults] = useFetchAllPermissions(allPermissionsQueries);
    const [isAllowedToCreateSection] = checkResults;

    const { whenUpdated, diffMinute, isWithColor, updateLastTime } = useCalculateLastUpdate();

    const onUpdateSectionButtonClick = (): Promise<void> => {
        return load().then(updateLastTime);
    };

    const widgets: WidgetModel[] = Object.values(sections).flatMap((section) => section.widgets);

    useEffect(() => {
        updateLastTime();
    }, [sections.length, widgets.length, dashboardModel.title]);

    const [isSubmitDisabled, submitEndIcon, submitHandler] = useAntiDoubleClick(onUpdateSectionButtonClick);

    const updateSectionButtonConfig: ButtonProps = {
        onClick: submitHandler,
        endIcon: submitEndIcon,
        disabled: isSubmitDisabled,
        startIcon: <UpdateIcon />,
    };

    const breadcrumbs: ObjectHeaderLayoutProps['breadcrumbs'] = (
        <ServerBreadcrumbs entityTitle={title} entityId={dashboardId} entityType={BreadcrumbsEntity.dashboard} />
    );

    const titleBlockAndMenu: ObjectHeaderLayoutProps['titleBlockAndMenu'] = {
        titleBlock: (
            <Typography variant="subtitle2" isWithoutAutoCapitalize sx={sxStyles.title}>
                {isLoading ? (
                    <Skeleton width={400} height={20} />
                ) : (
                    <EllipsisBox text={title} isWithAnchorElTooltip={true} />
                )}
            </Typography>
        ),
    };

    const leftBottomBlock: ObjectHeaderLayoutProps['leftBottomBlock'] = {
        element: (
            <Grid container maxWidth="100%" justifyContent="space-between">
                <Grid item />
                <Grid item>
                    <Grid container item spacing={0.75} wrap="nowrap">
                        <Grid item display="flex" flexDirection="row">
                            <LastUpdateTime
                                whenUpdated={whenUpdated}
                                diffMinute={diffMinute}
                                isWithColor={isWithColor}
                            />
                            <CustomButton {...updateSectionButtonConfig}>
                                <FormattedMessage id="common.update" />
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            {sections.length && isAllowedToCreateSection ? <CreateSectionButton /> : undefined}
                        </Grid>
                        <Grid item>
                            <DashboardPageHeaderMenuButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ),
    };

    return (
        <ObjectHeaderLayout
            breadcrumbs={breadcrumbs}
            titleBlockAndMenu={titleBlockAndMenu}
            leftBottomBlock={leftBottomBlock}
        />
    );
});
