import { recordToLang } from '@platform/front-utils';
import { multiLangFieldRu } from '@platform/multi-lang-field';
import { ttable_ru } from '@platform/ttable';
import { common } from './common';
import { console } from './console';
import { dashboard } from './dashboard';
import { feature } from './feature';
import { headerLinks } from './headerLinks';
import { section } from './section';
import { startPage } from './startPage';
import { user } from './user';
import { widget } from './widget';

export const localesRu = recordToLang(
    {
        common,
        console,
        dashboard,
        feature,
        headerLinks,
        multiLangField: multiLangFieldRu,
        section,
        startPage,
        ttable: ttable_ru,
        user,
        widget,
    },
    false,
);
