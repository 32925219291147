import { Grid } from '@mui/material';
import { ServerErrorHelper, useLocale } from '@platform/front-core';
import { AutocompleteField } from '@platform/front-ui';
import { getLabelForCodeTitleGroupOption } from '@platform/front-utils';
import { MultiLangField } from '@platform/multi-lang-field';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { widgetMessages } from '../../../../../../../../customization';
import { useStore } from '../../../../../../../../hooks';
import { WidgetCreateEditDialogModel } from '../../../../../../../../models';
import {
    CounterWidgetCreateEditFormSettingsFields,
    CounterWidgetCreateEditFormSettingsValueKeys,
    WidgetCreateEditAllPossibleFields,
    WidgetCreateEditFormValues,
} from '../../../../../../../../types';
import {
    getCounterWidgetCreateEditDialogFormInitialValues,
    getRegistryIdentifier,
} from '../../../../../../../../utils';

/**
 * @param widgetCreateOrEditDialogModel {WidgetCreateEditDialogModel} - модель, содержащая данные и логику диалога создания/редактирования виджета
 */
export type WidgetTypeCounterFormFieldsProps = {
    widgetCreateOrEditDialogModel: WidgetCreateEditDialogModel;
};

const emptyOptionsList: any[] = [];

/**
 * Поля формы диалога создания/редактирования виджета-счетчика
 */
export const CounterWidgetDialogFormFields = observer((props: WidgetTypeCounterFormFieldsProps): JSX.Element => {
    const { widgetCreateOrEditDialogModel } = props;
    const { serverErrors, settingsForSelectedWidgetType, isSettingsForSelectedWidgetTypeLoading } =
        widgetCreateOrEditDialogModel;
    const { serverFormErrors } = serverErrors;
    const { values, setFieldValue } = useFormikContext<WidgetCreateEditFormValues>();
    const { settings: settingsValues } = values;
    const { registry: registryValue } = settingsValues;
    const { coreRootStore } = useStore();
    const { formatMessageFromDefineMessage } = coreRootStore.intlStore;
    const { registry, preset, descriptions } = CounterWidgetCreateEditFormSettingsFields;
    const {
        registry: registryValueKey,
        preset: presetValueKey,
        descriptions: descriptionsValueKey,
    } = CounterWidgetCreateEditFormSettingsValueKeys;

    const registryIdentifier = registryValue
        ? getRegistryIdentifier(registryValue.group, registryValue.code)
        : undefined;

    useEffect(() => {
        const defaultValues = getCounterWidgetCreateEditDialogFormInitialValues();
        return () => {
            setFieldValue(registry, defaultValues[registryValueKey]);
            setFieldValue(preset, defaultValues[presetValueKey]);
            setFieldValue(descriptions, defaultValues[descriptionsValueKey]);
        };
    }, []);

    const registriesOptions = settingsForSelectedWidgetType
        ? settingsForSelectedWidgetType?.registries
        : emptyOptionsList;

    const presetList =
        registryIdentifier && settingsForSelectedWidgetType
            ? settingsForSelectedWidgetType?.presets?.[registryIdentifier] || emptyOptionsList
            : emptyOptionsList;

    const descriptionPlaceholder = useLocale('widget.phrases.descriptionPlaceholder', true, {
        widgetGenitive: useLocale('widget.single.genitive', false)[0],
    })[0];

    const onRegistryAutocompleteChange = (): void => {
        setFieldValue(preset, null);
    };

    return (
        <React.Fragment>
            <Grid item>
                <MultiLangField
                    fieldName={descriptions}
                    ruEngLangs={true}
                    label={descriptionPlaceholder}
                    isRequired={false}
                />
                <ServerErrorHelper
                    serverError={serverFormErrors[descriptions as unknown as keyof WidgetCreateEditAllPossibleFields]}
                />
            </Grid>
            <Grid item>
                <AutocompleteField
                    fieldName={registry}
                    label={formatMessageFromDefineMessage(widgetMessages.registryPlaceholder)}
                    options={registriesOptions}
                    loading={isSettingsForSelectedWidgetTypeLoading}
                    getLabel={getLabelForCodeTitleGroupOption}
                    serverError={serverFormErrors[registry as unknown as keyof WidgetCreateEditAllPossibleFields]}
                    onChange={onRegistryAutocompleteChange}
                    required
                />
            </Grid>
            <Grid item>
                <AutocompleteField
                    fieldName={preset}
                    label={formatMessageFromDefineMessage(widgetMessages.presetPlaceholder)}
                    options={presetList}
                    loading={isSettingsForSelectedWidgetTypeLoading}
                    serverError={serverFormErrors[preset as unknown as keyof WidgetCreateEditAllPossibleFields]}
                    required
                />
            </Grid>
        </React.Fragment>
    );
});
