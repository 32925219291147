import { ApiConfigs as CoreApiConfigs } from '@platform/front-core';
import { AxiosRequestConfig } from 'axios';
import { Layouts } from 'react-grid-layout';
import {
    DashboardCreateUpdateDTO,
    SectionCreateUpdateDTO,
    WidgetBaseDataDTOViewSettings,
    WidgetCreateUpdateDTO,
    WidgetType,
} from './types';

export class ApiConfigs extends CoreApiConfigs {
    getModuleLinksList: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            method: 'GET',
            url: '/modules',
            params: {
                lang,
            },
        };
    };

    getDashboard: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/dashboard/${id}`,
            method: 'GET',
        };
    };

    getDashboardUpdateInfo: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/dashboard/${id}/updateInfo`,
            method: 'GET',
        };
    };

    getDashboardCreationInfo: AxiosRequestConfig = {
        url: '/dashboard/creationInfo',
        method: 'GET',
    };

    createDashboard: (data: DashboardCreateUpdateDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/dashboard',
            method: 'POST',
            data,
        };
    };

    updateDashboard: (id: string, data: DashboardCreateUpdateDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/dashboard/${id}`,
            method: 'PUT',
            data,
        };
    };

    deleteDashboard: (dashboardId: string) => AxiosRequestConfig = (dashboardId) => {
        return {
            url: `/dashboard/${dashboardId}`,
            method: 'DELETE',
        };
    };

    getSectionCreationInfo: (dashboardId: string) => AxiosRequestConfig = (dashboardId) => {
        return {
            url: `/dashboard/${dashboardId}/section/creationInfo`,
            method: 'GET',
        };
    };

    createSection: (dashboardId: string, data: SectionCreateUpdateDTO) => AxiosRequestConfig = (dashboardId, data) => {
        return {
            url: `/dashboard/${dashboardId}/section`,
            method: 'POST',
            data,
        };
    };

    getSectionInfo: (sectionId: string) => AxiosRequestConfig = (sectionId) => {
        return {
            url: `/section/${sectionId}`,
            method: 'GET',
        };
    };

    getSectionUpdateInfo: (sectionId: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/section/${id}/updateInfo`,
            method: 'GET',
        };
    };

    updateSection: (sectionId: string, data: SectionCreateUpdateDTO) => AxiosRequestConfig = (sectionId, data) => {
        return {
            url: `/section/${sectionId}`,
            method: 'PUT',
            data,
        };
    };

    changeSectionPosition: (sectionId: string, newPosition: number) => AxiosRequestConfig = (
        sectionId,
        newPosition,
    ) => {
        return {
            url: '/section/position',
            method: 'PUT',
            params: {
                id: sectionId,
                position: newPosition,
            },
        };
    };

    deleteSection: (sectionId: string) => AxiosRequestConfig = (sectionId) => {
        return {
            url: `/section/${sectionId}`,
            method: 'DELETE',
        };
    };

    getDashboardCreationInfoSettingsByWidgetType: (sectionId: string, widgetType: WidgetType) => AxiosRequestConfig = (
        sectionId,
        widgetType: WidgetType,
    ) => {
        return {
            url: `/section/${sectionId}/widget/creationInfo/settings`,
            method: 'GET',
            params: {
                widgetType,
            },
        };
    };

    getWidgetUpdateInfo: (sectionId: string, widgetId: string) => AxiosRequestConfig = (sectionId, widgetId) => {
        return {
            url: `/section/${sectionId}/widget/${widgetId}/updateInfo`,
            method: 'GET',
        };
    };

    createWidget: (sectionId: string, data: WidgetCreateUpdateDTO) => AxiosRequestConfig = (sectionId, data) => {
        return {
            url: `/section/${sectionId}/widget`,
            method: 'POST',
            data,
        };
    };

    updateWidget: (widgetId: string, sectionId: string, data: WidgetCreateUpdateDTO) => AxiosRequestConfig = (
        widgetId,
        sectionId,
        data,
    ) => {
        return {
            url: `/section/${sectionId}/widget/${widgetId}`,
            method: 'PUT',
            data,
        };
    };

    updateWidgetViewSettings: (
        widgetId: string,
        sectionId: string,
        data: Partial<WidgetBaseDataDTOViewSettings>,
    ) => AxiosRequestConfig = (widgetId, sectionId, data) => {
        return {
            url: `/section/${sectionId}/widget/${widgetId}/viewSettings`,
            method: 'PUT',
            data,
        };
    };

    deleteWidget: (widgetId: string, sectionId: string) => AxiosRequestConfig = (widgetId, sectionId) => {
        return {
            url: `/section/${sectionId}/widget/${widgetId}`,
            method: 'DELETE',
        };
    };

    //пока не используется
    updateWidgetPositions: (sectionId: string, data: Layouts) => AxiosRequestConfig = (sectionId, data) => {
        return {
            url: `/section/layouts`,
            method: 'PUT',
            params: {
                id: sectionId,
            },
            data,
        };
    };

    getWidgetInfo: (sectionId: string, widgetId: string) => AxiosRequestConfig = (sectionId, widgetId) => {
        return {
            url: `/section/${sectionId}/widget/${widgetId}`,
            method: 'GET',
        };
    };

    syncDashboardsTabsPosition: (arrayOfDashboardIdsInOrder: string[]) => AxiosRequestConfig = (dashboardsInOrder) => {
        return {
            url: '/dashboard/positions',
            method: 'PUT',
            data: dashboardsInOrder,
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: '/roles',
        method: 'GET',
    };

    getDashboardTabs: AxiosRequestConfig = {
        url: '/dashboards',
        method: 'GET',
    };
}

export const apiConfigs = new ApiConfigs();
