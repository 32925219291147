import { CircularProgress, Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';

const sxStyles = makeSxStyles({
    wrapper: {
        height: '100%',
    },
});

/**
 * Лоадер для неизвестного типа виджета (fallback-компонента)
 */
export const UnknownWidgetTypeLoader = (): JSX.Element => {
    return (
        <Grid container alignItems="center" justifyContent="center" sx={sxStyles.wrapper}>
            <Grid item>
                <CircularProgress color="secondary" />
            </Grid>
        </Grid>
    );
};
