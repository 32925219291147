import { IntlStore } from '@platform/front-core';
import { CodeTitleGroup, ServerErrorsModel } from '@platform/front-utils';
import { getRuTitleInitialValue } from '@platform/multi-lang-field';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { DashboardStore, RootStore } from '../../stores';
import { DashboardCreateEditFields, DashboardCreateFormValues, DashboardRoutes } from '../../types';

export const dashboardCreateEditDialogModelObservables = {
    dashboardStore: observable,
    intlStore: observable,
    history: observable,
    serverErrors: observable,

    userGroupsList: observable,
    isUserGroupsListLoading: observable,

    formInitialValues: observable,
    isFormInitialValuesLoading: observable,

    routes: observable,

    loadUserGroupsList: action.bound,
    setUserGroupList: action.bound,
    setIsUserGroupsListLoading: action.bound,
    setIsFormInitialValuesLoading: action.bound,
};

export const dashboardCreateEditDialogModelAbstractObservables = {
    onSubmit: action.bound,
    onCancel: action.bound,
};

export const getDashboardCreateEditDialogDefaultFormInitialValues = (): DashboardCreateFormValues => ({
    titles: getRuTitleInitialValue(),
    roles: [],
    publishAsDraft: false,
    isInitial: false,
});

/**
 * Класс, содержащий общие данные и логику диалога создания/редактирования дашборда
 */
export abstract class DashboardCreateEditDialogModel {
    abstract onSubmit(formValues: DashboardCreateFormValues): Promise<void>;
    abstract onCancel(): void;

    protected dashboardStore: DashboardStore;
    protected intlStore: IntlStore;
    protected history: History;

    serverErrors = new ServerErrorsModel<DashboardCreateEditFields>();

    userGroupsList: CodeTitleGroup[] = [];
    isUserGroupsListLoading = false;

    formInitialValues: DashboardCreateFormValues = getDashboardCreateEditDialogDefaultFormInitialValues();
    isFormInitialValuesLoading = false;

    routes: DashboardRoutes;

    constructor(rootStore: RootStore, routes: DashboardRoutes) {
        const { coreRootStore, dashboardStore } = rootStore;
        this.dashboardStore = dashboardStore;
        this.history = coreRootStore.history;
        this.intlStore = coreRootStore.intlStore;
        this.routes = routes;
        makeObservable(this, dashboardCreateEditDialogModelObservables);
    }

    loadUserGroupsList(): void {
        this.setIsUserGroupsListLoading(true);
        this.dashboardStore
            .getDashboardCreationInfo()
            .then((dto) => {
                this.setUserGroupList(dto.roles);
            })
            .finally(() => this.setIsUserGroupsListLoading(false));
    }

    setUserGroupList(userGroupsList: CodeTitleGroup[]): void {
        this.userGroupsList = userGroupsList;
    }

    setIsUserGroupsListLoading(isLoading: boolean): void {
        this.isUserGroupsListLoading = isLoading;
    }

    setIsFormInitialValuesLoading(isLoading: boolean): void {
        this.isFormInitialValuesLoading = isLoading;
    }
}
