import {
    CoreRootStore,
    CurrentUserStore as CurrentUserStoreCore,
    RedirectByRoleType,
    RolePriority,
} from '@platform/front-core';
import { makeObservable, override } from 'mobx';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';

export const loginRedirectByRole: RedirectByRoleType = {
    User: clientRoute.myDashboards.dashboards,
};

export const rolePriorities: RolePriority = {
    User: 0,
};

export const currentUserStoreObservables = {
    getMainRoute: override,
    getRolePriorities: override,
    getLoginRedirectByRole: override,
};

export class CurrentUserStore extends CurrentUserStoreCore {
    constructor(rootStore: CoreRootStore) {
        super(rootStore);
        makeObservable(this, currentUserStoreObservables);
    }

    getMainRoute(): string {
        return clientRoute.myDashboards.dashboards;
    }

    getRolePriorities(): RolePriority {
        return rolePriorities;
    }

    getLoginRedirectByRole(): RedirectByRoleType {
        return loginRedirectByRole;
    }
}

export const getCurrentUserStore = (): any => {
    const [_CurrentUserStore] = di([CurrentUserStore], getCurrentUserStore);
    return _CurrentUserStore;
};
