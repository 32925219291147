import { Alert, Box, Divider, Grid } from '@mui/material';
import { AuthorizationCheck, ServerErrorHelper, useLocale } from '@platform/front-core';
import {
    AutocompleteField,
    FieldWithServerError,
    FullScreenDialog,
    FullScreenForm,
    FullScreenFormProps,
    makeSxStyles,
    Typography,
} from '@platform/front-ui';
import { getLabelForCodeTitleGroupOption, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { MultiLangField, titlesYupSchema } from '@platform/multi-lang-field';
import { Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import { dashboardMessages } from '../../../customization';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../hooks';
import { DashboardCreateEditDialogModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { DashboardCreateEditFields, DashboardCreateFormValues, DashboardRouteParams } from '../../../types';
import { DashboardCreateEditDialogContentSkeleton as DashboardCreateEditDialogContentSkeletonInj } from './DashboardCreateEditDialogContentSkeleton';

const sxStyles = makeSxStyles({
    container: {
        direction: 'column',
        spacing: (theme) => theme.spacing(2),
    },
    publishAsDraftHelper: {
        pl: 4,
    },
    isInitialInfo: {
        ml: 2,
    },
    titleWrapper: {
        width: '100%',
    },
    bindingWrapper: {
        pb: 2,
        width: '100%',
    },
    autocompleteFieldRoles: {
        minWidth: '300px',
        maxWidth: '100%',
        width: 'max-content',
    },
    divider: {
        mt: (theme) => theme.spacing(2),
        ml: '-16px',
        width: (theme) => `calc(100% + ${theme.spacing(4)})`,
    },
});

export type DashboardCreateEditDialogProps<T> = {
    dashboardCreateOrEditDialogModel: T;
    isCreateDashboard?: boolean;
};

export const DashboardCreateEditDialog = observer(
    <T extends DashboardCreateEditDialogModel>(props: DashboardCreateEditDialogProps<T>): JSX.Element => {
        const [DashboardCreateEditDialogContentSkeleton] = di(
            [DashboardCreateEditDialogContentSkeletonInj],
            DashboardCreateEditDialog,
        );
        const { dashboardCreateOrEditDialogModel, isCreateDashboard } = props;
        const history = useHistory();
        const { dashboardId } = useParams<DashboardRouteParams>();
        const rootStore = useStore();
        const { headerHeightStore, intlStore, permissionsStore } = rootStore.coreRootStore;
        const { visibleHeaderHeight } = headerHeightStore;
        const { systemConfig } = permissionsStore as PermissionsStore;
        const createPublicDashboard = systemConfig.createPublicDashboard();

        const { titles, roles, publishAsDraft, isInitial } = DashboardCreateEditFields;
        const {
            serverErrors,
            userGroupsList,
            loadUserGroupsList,
            isUserGroupsListLoading,
            onSubmit,
            formInitialValues,
            isFormInitialValuesLoading,
        } = dashboardCreateOrEditDialogModel;
        const { serverFormErrors } = serverErrors;

        const { formatMessageFromDefineMessage } = intlStore;
        const { routes } = useDashboardsAuxContext();
        const location = useLocation();
        const { pathname } = location;
        const { dashboardModel } = useDashboardPageContext();

        const isCreating =
            matchPath(pathname, routes.myDashboardsCreate) || matchPath(pathname, routes.dashboardCreate);
        const dialogTitle = formatMessageFromDefineMessage(
            isCreating ? dashboardMessages.createDialogTitle : dashboardMessages.editDialogTitle,
        );

        useEffect(() => {
            loadUserGroupsList();
        }, [dashboardCreateOrEditDialogModel]);

        const onSubmitHandler = (value: DashboardCreateFormValues): void => {
            onSubmit(value).then(() => dashboardModel.dashboardTabsModel?.getTabs());
        };

        const [isSubmitDisabled, submitEndIcon, submitHandler] = useAntiDoubleClick(onSubmitHandler);

        const submitButtonAdditionalProps: FullScreenFormProps['submitBtnAdditionalProps'] = {
            endIcon: submitEndIcon,
            disabled: isSubmitDisabled,
        };

        const { Yup } = useYup();
        const validationSchema = Yup.object({
            titles: titlesYupSchema(Yup, true).min(1),
            roles: Yup.array(),
            publishAsDraft: Yup.boolean(),
            isInitial: Yup.boolean(),
        });

        const titlesPlaceholderMessage = useLocale('dashboard.phrases.titlesPlaceholder', true, {
            dashboardGenitive: useLocale('dashboard.single.genitive', false)[0],
        })[0];

        const rolesLabel = useLocale('user.phrases.userGroups', true, {
            user: useLocale('user.plural.accusative', false)[0],
        })[0];

        const publishAsDraftLabel = {
            label: <FormattedMessage id="dashboard.publishAsDraft" />,
        };

        const publishAsDraftHelperText = useLocale('dashboard.phrases.publishAsDraftHelper', true, {
            dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
        })[0];
        const publishAsDraftHelper = <Box sx={sxStyles.publishAsDraftHelper}>{publishAsDraftHelperText}</Box>;

        const isInitialLabel = {
            label: <FormattedMessage id="dashboard.makeInitial" />,
        };

        const getIsInitialHelper = (
            publishAsDraftValue: boolean,
            isInitialValue: boolean,
        ): {
            isWithoutHelperTextProp?: boolean;
            helperText: React.ReactNode;
        } => {
            if (publishAsDraftValue) {
                const draftDashboardCannotBeInitialHelperText = useLocale(
                    'dashboard.phrases.draftDashboardCannotBeInitial',
                    true,
                    {
                        dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
                    },
                )[0];

                return {
                    isWithoutHelperTextProp: true,
                    helperText: <Box sx={sxStyles.publishAsDraftHelper}>{draftDashboardCannotBeInitialHelperText}</Box>,
                };
            }
            if (isInitialValue) {
                const makeInitialCreateDashboardInfoHelperText = useLocale(
                    'dashboard.phrases.makeInitialCreateDashboardInfo',
                    true,
                    {
                        dashboardGenitive: useLocale('dashboard.single.genitive', false)[0],
                        dashboardNominative: useLocale('dashboard.single.nominative', false)[0],
                    },
                )[0];

                return {
                    isWithoutHelperTextProp: true,
                    helperText: (
                        <Alert sx={sxStyles.isInitialInfo} severity="info">
                            {makeInitialCreateDashboardInfoHelperText}
                        </Alert>
                    ),
                };
            }

            return {
                isWithoutHelperTextProp: !!serverFormErrors[isInitial],
                helperText: undefined,
            };
        };

        const onCancel = (): void => {
            if (dashboardId) {
                history.push(generatePath(routes.dashboard, { dashboardId }));
                return;
            }
            history.push(generatePath(routes.dashboards));
        };

        return (
            <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} onCancel={onCancel}>
                <AuthorizationCheck {...createPublicDashboard}>
                    {(allowed) => {
                        return (
                            <Formik
                                initialValues={formInitialValues}
                                validationSchema={validationSchema}
                                onSubmit={submitHandler}
                                enableReinitialize={true}
                            >
                                {({ values, setFieldValue }) => {
                                    const { isInitial: isInitialValue, publishAsDraft: publishAsDraftValue } = values;

                                    useEffect(() => {
                                        if (allowed && isCreateDashboard) {
                                            setFieldValue(publishAsDraft, true);
                                        }
                                    }, [allowed, isCreateDashboard]);

                                    if (isInitialValue && publishAsDraftValue) {
                                        setFieldValue(isInitial, false);
                                    }

                                    return (
                                        <FullScreenForm
                                            dialogTitle={dialogTitle}
                                            onCancel={onCancel}
                                            submitBtnAdditionalProps={submitButtonAdditionalProps}
                                        >
                                            <Grid container direction="column" spacing={2}>
                                                {isFormInitialValuesLoading ? (
                                                    <DashboardCreateEditDialogContentSkeleton />
                                                ) : (
                                                    <React.Fragment>
                                                        <Grid sx={sxStyles.titleWrapper} item>
                                                            <Typography variant="subtitle2" py={1}>
                                                                <FormattedMessage id="dashboard.name" />
                                                            </Typography>
                                                            <MultiLangField
                                                                fieldName={titles}
                                                                ruEngLangs={true}
                                                                label={titlesPlaceholderMessage}
                                                            />
                                                            <ServerErrorHelper serverError={serverFormErrors[titles]} />
                                                        </Grid>
                                                        {allowed && (
                                                            <React.Fragment>
                                                                <Divider sx={sxStyles.divider} />
                                                                <Grid item sx={sxStyles.bindingWrapper}>
                                                                    <Typography variant="subtitle2" py={2}>
                                                                        <FormattedMessage id="dashboard.binding" />
                                                                    </Typography>
                                                                    <AutocompleteField
                                                                        sx={sxStyles.autocompleteFieldRoles}
                                                                        fieldName={roles}
                                                                        label={rolesLabel}
                                                                        options={userGroupsList}
                                                                        multiple={true}
                                                                        serverError={serverFormErrors[roles]}
                                                                        getLabel={getLabelForCodeTitleGroupOption}
                                                                        loading={isUserGroupsListLoading}
                                                                    />
                                                                </Grid>
                                                                <Divider sx={sxStyles.divider} />
                                                                <Grid item>
                                                                    <FieldWithServerError
                                                                        type="checkbox"
                                                                        name={publishAsDraft}
                                                                        component={CheckboxWithLabel}
                                                                        Label={publishAsDraftLabel}
                                                                        isWithoutHelperTextProp={true}
                                                                        serverError={serverFormErrors[publishAsDraft]}
                                                                        helperText={publishAsDraftHelper}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FieldWithServerError
                                                                        type="checkbox"
                                                                        name={isInitial}
                                                                        component={CheckboxWithLabel}
                                                                        disabled={publishAsDraftValue}
                                                                        Label={isInitialLabel}
                                                                        serverError={serverFormErrors[isInitial]}
                                                                        {...getIsInitialHelper(
                                                                            publishAsDraftValue,
                                                                            isInitialValue,
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </FullScreenForm>
                                    );
                                }}
                            </Formik>
                        );
                    }}
                </AuthorizationCheck>
            </FullScreenDialog>
        );
    },
);
