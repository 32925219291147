import { Box } from '@mui/material';
import { blue, grey, makeSxStylesWithProps } from '@platform/front-ui';
import React from 'react';
import { backgroundColors } from '../../constants';

export type WidgetCheckedIconProps = {
    backgroundColor: string;
    isChecked: boolean;
};

const getWidgetCheckedIconSxStyles = makeSxStylesWithProps((props: WidgetCheckedIconProps) => {
    const { backgroundColor, isChecked } = props;
    return {
        box: {
            minWidth: '32px',
            height: '32px',
            borderRadius: '4px',
            background: backgroundColor,
            border: isChecked
                ? `2px solid ${blue[500]}`
                : backgroundColor === backgroundColors.white
                ? `1px solid ${grey[300]}`
                : `2px solid ${grey[0]}`,
            'input:hover ~ &': {
                border: `2px solid ${blue[500]}`,
            },
        },
    };
});

export const WidgetCheckedIcon = (props: WidgetCheckedIconProps): JSX.Element => {
    const { backgroundColor, isChecked } = props;
    const sxStyles = getWidgetCheckedIconSxStyles({ backgroundColor, isChecked });

    return <Box sx={sxStyles.box} />;
};
