import { CodeTitleNull } from '@platform/front-types';
import { CodeTitle } from '@platform/front-utils';
import { IntlShape } from 'react-intl';
import { WidgetType } from '../../types';

/**
 * Функция, генерирующая локализованные опции селекта "Тип виджета"
 */
export const getWidgetTypeSelectOptions = (intl: IntlShape): CodeTitle[] => {
    try {
        return Object.values(WidgetType).map((code) => {
            return {
                code,
                title: intl.formatMessage(
                    { id: `widget.widgetType.${code}` },
                    {
                        // todo: костыль - надо порефакторить
                        t: (chunks) => {
                            const messageIdAndTranslationSeparatedBySpaceSymbol = chunks?.[0];
                            return messageIdAndTranslationSeparatedBySpaceSymbol?.split(' ')?.[1] || '';
                        },
                    },
                ),
            };
        });
    } catch (e: any) {
        console.error('Failed to generate widget `type` field select options:', e);
        return [];
    }
};
