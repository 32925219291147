import { useYup } from '@platform/front-utils';
import { titlesYupSchema } from '@platform/multi-lang-field';
import { checkValidUrlRegexp } from '../../../../../../../constants';
import {
    CounterWidgetCreateEditFormSettingsValueKeys,
    LinkWidgetCreateEditFormSettingsValueKeys,
    WidgetType,
} from '../../../../../../../types';

/**
 * Схема валидации для формы создания/редактирования виджета
 */
export const getWidgetCreateEditDialogValidationSchema = (
    Yup: ReturnType<typeof useYup>['Yup'],
    params: {
        widgetType: WidgetType | null;
        linkValidationMessage: string;
    },
): ReturnType<typeof Yup.object> => {
    let widgetSpecificFieldsValidationSchema;
    const { widgetType, linkValidationMessage } = params;

    switch (widgetType) {
        case WidgetType.counter: {
            widgetSpecificFieldsValidationSchema = {
                settings: Yup.object({
                    [CounterWidgetCreateEditFormSettingsValueKeys.descriptions]: titlesYupSchema(Yup, false).min(1),
                    [CounterWidgetCreateEditFormSettingsValueKeys.registry]: Yup.object().required().nullable(),
                    [CounterWidgetCreateEditFormSettingsValueKeys.preset]: Yup.object().required().nullable(),
                }),
            };
            break;
        }
        case WidgetType.link: {
            widgetSpecificFieldsValidationSchema = {
                settings: Yup.object({
                    [LinkWidgetCreateEditFormSettingsValueKeys.descriptions]: titlesYupSchema(Yup, false).min(1),
                    [LinkWidgetCreateEditFormSettingsValueKeys.link]: Yup.string()
                        .required()
                        .matches(checkValidUrlRegexp, linkValidationMessage),
                }),
            };
            break;
        }
        default: {
            widgetSpecificFieldsValidationSchema = {};
        }
    }

    return Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
        widgetType: Yup.object().nullable().required(),
        ...widgetSpecificFieldsValidationSchema,
        backgroundColor: Yup.string(),
    });
};
