import { defaultWidgetBackgroundColor } from '../../constants';
import { WidgetBaseDataDTOViewSettings, WidgetFontSizeViewSetting, WidgetType } from '../../types';

/**
 * Функция для получения дефолтных настроек отображения виджета
 * @param {WidgetType} widgetType - тип виджета
 */
export const getDefaultViewSettingsByWidgetType = (widgetType: WidgetType): WidgetBaseDataDTOViewSettings => {
    switch (widgetType) {
        case WidgetType.counter: {
            return {
                fontSize: WidgetFontSizeViewSetting.standard,
                backgroundColor: defaultWidgetBackgroundColor,
            };
        }
        default: {
            return {
                fontSize: WidgetFontSizeViewSetting.standard,
                backgroundColor: defaultWidgetBackgroundColor,
            };
        }
    }
};
