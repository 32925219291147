import { Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { blue, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { DashedBorderButton as DashedBorderButtonInj } from '../../../../../components';
import { sectionMessages } from '../../../../../customization';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';

const sxStyles = makeSxStyles({
    addSectionButtonWrapper: {
        mb: 1,
    },
    addSectionButton: {
        py: (theme) => theme.spacing(1.25),
        color: blue[600],
        height: '160px',
    },
});

export const DashboardPageNoSections = observer((): JSX.Element => {
    const [DashedBorderButton] = di([DashedBorderButtonInj], DashboardPageNoSections);

    const history = useHistory();
    const { permissionsStore } = useStore().coreRootStore;
    const { dashboardConfig } = permissionsStore as PermissionsStore;
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { id: dashboardId } = dashboardModel;
    const createSectionQuery = dashboardConfig.createSection(dashboardId);

    const onCreateSectionButtonClick = (): void => {
        history.push(generatePath(routes.dashboardCreateSection, { dashboardId }));
    };

    return (
        <Grid container direction="column" wrap="nowrap" height="100%">
            <AuthorizationCheck {...createSectionQuery}>
                <Grid item sx={sxStyles.addSectionButtonWrapper}>
                    <DashedBorderButton onClick={onCreateSectionButtonClick} sx={sxStyles.addSectionButton} fullWidth>
                        <FormattedMessage {...sectionMessages.addSection} />
                    </DashedBorderButton>
                </Grid>
            </AuthorizationCheck>
        </Grid>
    );
});
