import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate';
import { apiConfigs } from './apiConfigs'; // setimmediate нужно для работы с react-scripts@5.0.0
import { env, features, history, palette, locales } from './initRootProps';
import { Root } from './Root';

ReactDOM.render(
    <React.StrictMode>
        <Root
            env={env}
            features={features}
            history={history}
            palette={palette}
            apiConfigs={apiConfigs}
            locales={locales}
        />
    </React.StrictMode>,
    document.getElementById('root'),
);
