import { Grid, Skeleton } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';

const sxStyles = makeSxStyles({
    mainContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        p: 3,
    },
    countWrapper: {
        width: '90%',
        height: (theme) => theme.spacing(7),
    },
    count: {
        width: '100%',
        height: '100%',
    },
    textWrapper: {
        height: (theme) => theme.spacing(5),
    },
    text: {
        width: '100%',
        height: '100%',
    },
});

export const CounterWidgetSkeleton = (): JSX.Element => {
    return (
        <Grid container sx={sxStyles.mainContainer}>
            <Grid item sx={sxStyles.countWrapper}>
                <Skeleton variant="text" sx={sxStyles.count} />
            </Grid>
            <Grid item sx={sxStyles.textWrapper}>
                <Skeleton variant="text" sx={sxStyles.text} />
            </Grid>
        </Grid>
    );
};
