import { Header as CoreHeader } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../../hooks';

export const Header = observer((): JSX.Element => {
    const { coreRootStore, settingsFromBackend } = useStore();
    const { currentUserStore, headerLinksStore } = coreRootStore;
    const userUuid = currentUserStore.person.uuid;
    const { popFrontUrl } = settingsFromBackend;

    useEffect(() => {
        popFrontUrl &&
            userUuid &&
            headerLinksStore.setProfileCustomLink({
                isToOuterResource: true,
                url: `${popFrontUrl}/persons/${userUuid}`,
            });
    }, [popFrontUrl, userUuid]);

    return <CoreHeader />;
});
