import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { sectionMessages } from '../../customization';
import { useDashboardPageContext, useDashboardsAuxContext } from '../../hooks';
import { CustomButton as CustomButtonInj } from './CustomButton';

export const CreateSectionButton = observer((): JSX.Element => {
    const [CustomButton] = di([CustomButtonInj], CreateSectionButton);
    const { dashboardModel } = useDashboardPageContext();
    const { id: dashboardId } = dashboardModel;

    const { routes } = useDashboardsAuxContext();

    const history = useHistory();

    const onCreateSectionButtonClick = (): void => {
        dashboardId && history.push(generatePath(routes.dashboardCreateSection, { dashboardId }));
    };

    return (
        <CustomButton onClick={onCreateSectionButtonClick}>
            <FormattedMessage {...sectionMessages.addSection} />
        </CustomButton>
    );
});
