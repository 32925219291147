import { ResponsiveProps } from 'react-grid-layout';
import { ReactGridLayoutBreakpoint } from '../types';

/**
 * Брейкпоинты в пикселях для сетки виджетов
 */
export const reactGridLayoutBreakpoints: ResponsiveProps['breakpoints'] = {
    [ReactGridLayoutBreakpoint.lg]: 1200,
    [ReactGridLayoutBreakpoint.md]: 996,
    [ReactGridLayoutBreakpoint.sm]: 768,
};

/**
 * Маппинг количества столбцов в сетке по брейпоинтам
 */
export const reactGridLayoutCols: ResponsiveProps['cols'] = {
    [ReactGridLayoutBreakpoint.lg]: 12,
    [ReactGridLayoutBreakpoint.md]: 9,
    [ReactGridLayoutBreakpoint.sm]: 6,
};

export const reactGridLayoutRowHeight = 72;
export const reactGridLayoutDraggableHandleClassName = 'react-grid-layout-draggable-handle-class';
export const widgetControlsContainerClassName = 'widget-controls-container';
export const reactGridLayoutDraggableHandleSelector = `.${reactGridLayoutDraggableHandleClassName}`;
export const reactGridLayoutPropValue = [16, 16] as [number, number];
export const controlsResizeBtn = 'controls-resize-btn';

/**
 * Значение поля ReactGridLayout.Layout['y'] из библиотеки react-grid-layout. Это очень большое число чтобы новый виджет вставился в самый низ сетки виджетов
 */
export const defaultYCoordinateForLayout = 1e99;
