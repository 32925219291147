import {
    getAccountPermissionsConfig as getAccountPermissionsConfigCore,
    getSystemPermissionsConfig as getSystemPermissionsConfigCore,
    makePermissionsConfigGetter,
} from '@platform/front-core';
import { PermissionEntities, Permissions } from '../../types';

export const getAccountPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        ...getAccountPermissionsConfigCore(entities, permissions),
        viewProfile: () => ({
            isWithoutCheck: true,
        }),
    }),
);

export type AccountPermissions = ReturnType<typeof getAccountPermissionsConfig>;

export const getSystemPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        ...getSystemPermissionsConfigCore(entities, permissions),
        viewDashboardRegistry: () => ({
            entityCode: entities.system,
            permCode: permissions.system.ViewDashboardRegistry,
        }),
        updateDashboardPositions: () => ({
            entityCode: entities.system,
            permCode: permissions.system.UpdateDashboardPositions,
        }),
        viewDashboardList: () => ({
            entityCode: entities.system,
            permCode: permissions.system.ViewDashboardList,
        }),
        createDashboard: () => ({
            entityCode: entities.system,
            permCode: permissions.system.CreateDashboard,
        }),
        createPublicDashboard: () => ({
            entityCode: entities.system,
            permCode: permissions.system.CreatePublicDashboard,
        }),
    }),
);

export type SystemPermissions = ReturnType<typeof getSystemPermissionsConfig>;

export const getDashboardPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        view: (id?: string) => ({
            entityCode: entities.dashboard,
            permCode: permissions.dashboard.View,
            entityId: id,
        }),
        delete: (id?: string) => ({
            entityCode: entities.dashboard,
            permCode: permissions.dashboard.Delete,
            entityId: id,
        }),
        update: (id?: string) => ({
            entityCode: entities.dashboard,
            permCode: permissions.dashboard.Update,
            entityId: id,
        }),
        createSection: (id?: string) => ({
            entityCode: entities.dashboard,
            permCode: permissions.dashboard.CreateSection,
            entityId: id,
        }),
    }),
);

export type DashboardPermissions = ReturnType<typeof getDashboardPermissionsConfig>;

export const getSectionPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        view: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.View,
            entityId: sectionId,
        }),
        delete: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.Delete,
            entityId: sectionId,
        }),
        update: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.Update,
            entityId: sectionId,
        }),
        createWidget: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.CreateWidget,
            entityId: sectionId,
        }),
        updateWidget: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.UpdateWidget,
            entityId: sectionId,
        }),
        deleteWidget: (sectionId?: string) => ({
            entityCode: entities.section,
            permCode: permissions.section.DeleteWidget,
            entityId: sectionId,
        }),
    }),
);

export type SectionPermissions = ReturnType<typeof getSectionPermissionsConfig>;
