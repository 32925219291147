import { PromiseVoidFunction } from '@platform/front-utils';
import { titlesDTOtoTitles, titlesToTitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { dashboardMessages } from '../../customization';
import { RootStore } from '../../stores';
import { DashboardCreateFormValues, DashboardCreateUpdateDTO, DashboardRoutes } from '../../types';
import {
    DashboardCreateEditDialogModel,
    dashboardCreateEditDialogModelAbstractObservables,
} from './DashboardCreateEditDialogModel';

export const dashboardEditDialogModelObservables = {
    ...dashboardCreateEditDialogModelAbstractObservables,
    dashboardId: observable,
    reloadDashboardData: action.bound,
    loadCurrentDashboardInfo: action.bound,
    setFormInitialValues: action.bound,
};

/**
 * Класс, содержащий данные и логику для диалога редактирования дашборда
 */
export class DashboardEditDialogModel extends DashboardCreateEditDialogModel {
    dialogTitle: string;
    dashboardId = '';
    reloadDashboardData: PromiseVoidFunction;

    constructor(
        rootStore: RootStore,
        dashboardId: string,
        reloadDashboardModel: PromiseVoidFunction,
        routes: DashboardRoutes,
    ) {
        super(rootStore, routes);
        this.dashboardId = dashboardId;
        this.dialogTitle = this.intlStore.formatMessageFromDefineMessage(dashboardMessages.editDialogTitle);
        this.reloadDashboardData = reloadDashboardModel;
        makeObservable(this, dashboardEditDialogModelObservables);
    }

    onSubmit(formValues: DashboardCreateFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const { titles, roles, publishAsDraft, isInitial, iconCode } = formValues;

        const dto: DashboardCreateUpdateDTO = {
            titles: titlesToTitlesDTO(titles),
            roles,
            publishAsDraft,
            isInitial,
            iconCode,
        };

        return this.dashboardStore
            .updateDashboard(this.dashboardId, dto)
            .then(() => {
                if (publishAsDraft) {
                    this.history.push(
                        generatePath(clientRoute.allDashboards.dashboard, { dashboardId: this.dashboardId }),
                    );
                    return;
                }
                this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
                this.reloadDashboardData();
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    onCancel(): void {
        this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
    }

    loadCurrentDashboardInfo(): void {
        this.setIsFormInitialValuesLoading(true);
        this.dashboardStore
            .getDashboardUpdateInfo(this.dashboardId)
            .then(this.setFormInitialValues)
            .finally(() => this.setIsFormInitialValuesLoading(false));
    }

    setFormInitialValues(dto: DashboardCreateUpdateDTO): void {
        const { titles, ...restValues } = dto;
        this.formInitialValues = {
            titles: titlesDTOtoTitles(titles),
            ...restValues,
        };
    }
}
