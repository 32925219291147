import { Grid, Skeleton } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';

const sxStyles = makeSxStyles({
    skeletonContainer: {
        overflow: 'hidden',
    },
    largeSkeleton: {
        flex: '1 1 100%',
    },
});

export const DashboardPageContentSkeleton = (): JSX.Element => {
    return (
        <Grid container direction="column" wrap="nowrap" spacing={1} sx={sxStyles.skeletonContainer}>
            <Grid item>
                <Skeleton height={48} width="100%" variant="rectangular" />
            </Grid>
            <Grid item sx={sxStyles.largeSkeleton}>
                <Skeleton width="100%" height="100%" variant="rectangular" />
            </Grid>
            <Grid item>
                <Skeleton height={48} width="100%" variant="rectangular" />
            </Grid>
        </Grid>
    );
};
