import { getRuTitleInitialValue } from '@platform/multi-lang-field';
import {
    CounterWidgetCreateEditFormSettingsValueKeys,
    CounterWidgetCreateEditFormSettingsValues,
} from '../../../types';

/**
 * Возвращает дефолтные значения специфичных полей формы создания/редактирования виджета-счетчика
 */
export const getCounterWidgetCreateEditDialogFormInitialValues = (): CounterWidgetCreateEditFormSettingsValues => ({
    [CounterWidgetCreateEditFormSettingsValueKeys.registry]: null,
    [CounterWidgetCreateEditFormSettingsValueKeys.preset]: null,
    [CounterWidgetCreateEditFormSettingsValueKeys.descriptions]: getRuTitleInitialValue(),
});
