/**
 * Задержка в мс перед скроллом к новому созданному разделу (мс)
 */
export const autoScrollToNewSectionDelay = 1500;

/**
 * Время анимации подсветки нового раздела/виджета (мс)
 */
export const highlightDuration = 3000;

export const sectionHighlightActivationDelay = 800;

export const widgetHighlightActivationDelay = 400;

/**
 * Задержка в мс при закрытии меню опций настроек отображения виджета (нужно чтобы избежать моргания блока действий над виджетом)
 */
export const widgetViewSettingsActionsBlockDisappearDelay = 300;
