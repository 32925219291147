import { AuthorizationCheck, useLocale } from '@platform/front-core';
import { ErrorBoundary } from '@platform/front-utils';
import { TTable, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { RegistryCode } from '../../../../types';
import { ttableDefaultPaths } from '../../../../constants';

const dashboardRegistryTableVisibilitySettings: TTableVisibilitySettings = {
    toolbar: {
        isWithFilters: false,
        isWithPresets: true,
        isWithDownload: false,
        isWithAddCustomColumns: false,
        isWithCollapseRowMode: false,
    },
};

export const DashboardRegistryTable = observer((): JSX.Element => {
    const { locale } = useIntl();
    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api, permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const registryCheckQuery = systemConfig.viewDashboardRegistry();

    const tableTitle = useLocale('dashboard.registryTitle', true)[0];

    return (
        <AuthorizationCheck {...registryCheckQuery} isWithRedirect>
            <ErrorBoundary>
                <TTable
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={RegistryCode.dashboard}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    fetchUserRoleList={userStore.userRoleList}
                    lang={locale}
                    visibleSettings={dashboardRegistryTableVisibilitySettings}
                    isAdmin
                    defaultPath={ttableDefaultPaths.dashboards}
                />
            </ErrorBoundary>
        </AuthorizationCheck>
    );
});
