import { Box, Button, Container, Grid } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route, useHistory } from 'react-router-dom';
import { dashboardMessages } from '../../../customization';
import { useDashboardsAuxContext, useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import {
    DashboardCreateDialog as DashboardCreateDialogInj,
    DashboardRegistryTable as DashboardRegistryTableInj,
} from './components';

export const DashboardRegistry = observer((): JSX.Element => {
    const [DashboardRegistryTable] = di([DashboardRegistryTableInj], DashboardRegistry);
    const [DashboardCreateDialog] = di([DashboardCreateDialogInj], DashboardRegistry);

    const { coreRootStore } = useStore();
    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const createDashboardQuery = systemConfig.createDashboard();

    const { routes } = useDashboardsAuxContext();

    const history = useHistory();
    const openDialog = (): void => {
        history.push(routes.dashboardCreate);
    };

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="subtitle2" component="h1">
                                    <FormattedMessage {...dashboardMessages.registryTitle} />
                                </Typography>
                            </Grid>
                            <AuthorizationCheck {...createDashboardQuery}>
                                <Grid item>
                                    <Route path={routes.dashboardCreate} exact>
                                        <DashboardCreateDialog />
                                    </Route>
                                    <Button onClick={openDialog}>
                                        <FormattedMessage id="common.create" />
                                    </Button>
                                </Grid>
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <DashboardRegistryTable />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
