import { createLocalesWithoutCases, LocalesTree, ObjectLocales } from '@platform/front-utils';

export const dashboard: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('dashboard', 'dashboards'),
    binding: 'Binding',
    makeInitial: 'Make a start dashboard',
    name: 'Name',
    phrases: {
        create: 'Creation of {dashboardGenitive}',
        createDashboard: 'Create {dashboardNominative}',
        delete: 'Delete {dashboardNominative}',
        deleteDialogText: 'The {dashboardNominative} will be deleted with all its sections and widgets',
        draftDashboardCannotBeInitial: '{dashboardNominative} published as Draft can not be set as start',
        edit: 'Editing of {dashboardGenitive}',
        makeInitialCreateDashboardInfo:
            'The {dashboardGenitive} tab on the start page will be pinned to the left. There can be only one start {dashboardNominative} - if another {dashboardNominative} was the start one, then this one will become the start one after saving.',
        myDashboards: 'My {dashboardPluralNominative}',
        noMyDashboards: 'No {dashboardPluralGenitive}',
        noMyDashboardsSubtitle:
            'Create your first {dashboardNominative} or navigate to another module from the top left navigation menu',
        publishAsDraftHelper: "When a {dashboardNominative} is published as Draft it's not visible to users",
        registryTitle: '{dashboardPluralGenitive} registry',
        titlesPlaceholder: 'Title of {dashboardGenitive}',
    },
    publishAsDraft: 'Publish as draft',
};
