import { Grid } from '@mui/material';
import { ServerErrorHelper, useLocale } from '@platform/front-core';
import { FieldWithServerError } from '@platform/front-ui';
import { MultiLangField } from '@platform/multi-lang-field';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { WidgetCreateEditDialogModel } from '../../../../../../../../models';
import {
    LinkWidgetCreateEditFormSettingsFields,
    LinkWidgetCreateEditFormSettingsValueKeys,
    WidgetCreateEditAllPossibleFields,
    WidgetCreateEditFormValues,
} from '../../../../../../../../types';
import { getLinkWidgetCreateEditDialogFormInitialValues } from '../../../../../../../../utils';

/**
 * @param widgetCreateOrEditDialogModel {WidgetCreateEditDialogModel} - модель, содержащая данные и логику диалога создания/редактирования виджета
 */
export type LinkWidgetFormFieldsProps = {
    widgetCreateOrEditDialogModel: WidgetCreateEditDialogModel;
};

/**
 * Поля формы диалога создания/редактирования виджета-ссылки
 */
export const LinkWidgetDialogFormFields = observer((props: LinkWidgetFormFieldsProps): JSX.Element => {
    const { widgetCreateOrEditDialogModel } = props;
    const { serverErrors } = widgetCreateOrEditDialogModel;
    const { serverFormErrors } = serverErrors;
    const { setFieldValue } = useFormikContext<WidgetCreateEditFormValues>();
    const { link, descriptions } = LinkWidgetCreateEditFormSettingsFields;
    const { link: linkValueKey, descriptions: descriptionsValueKey } = LinkWidgetCreateEditFormSettingsValueKeys;
    const { formatMessage } = useIntl();

    const descriptionPlaceholder = useLocale('widget.phrases.descriptionPlaceholder', true, {
        widgetGenitive: useLocale('widget.single.genitive', false)[0],
    })[0];

    useEffect(() => {
        return () => {
            const defaultValues = getLinkWidgetCreateEditDialogFormInitialValues();
            setFieldValue(link, defaultValues[linkValueKey]);
            setFieldValue(descriptions, defaultValues[descriptionsValueKey]);
        };
    }, [getLinkWidgetCreateEditDialogFormInitialValues]);

    return (
        <React.Fragment>
            <Grid item>
                <MultiLangField
                    fieldName={descriptions}
                    ruEngLangs={true}
                    label={descriptionPlaceholder}
                    isRequired={false}
                />
                <ServerErrorHelper
                    serverError={serverFormErrors[descriptions as unknown as keyof WidgetCreateEditAllPossibleFields]}
                />
            </Grid>
            <Grid item>
                <FieldWithServerError
                    name={link}
                    label={formatMessage({ id: 'widget.linkPlaceholder' })}
                    serverError={serverFormErrors[link as unknown as keyof WidgetCreateEditAllPossibleFields]}
                    required
                />
            </Grid>
        </React.Fragment>
    );
});
