import { FormControlLabel, Radio, Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { WidgetCheckedIcon as WidgetCheckedIconInj } from '../../../../../../../../components';
import { getBackgroundColorByBackgroundColorViewSetting } from '../../../../../../../../constants';
import { WidgetBackgroundColorViewSetting } from '../../../../../../../../types';

export const getWidgetColorItem = (
    colorSetting: WidgetBackgroundColorViewSetting,
    currentBackgroundColor: WidgetBackgroundColorViewSetting,
): JSX.Element => {
    const [WidgetCheckedIcon] = di([WidgetCheckedIconInj], getWidgetColorItem);

    const { backgroundColor, backgroundColorName } = getBackgroundColorByBackgroundColorViewSetting(colorSetting);

    const { formatMessage } = useIntl();
    const tooltipText = formatMessage({ id: `widget.widgetBackgroundColor.${backgroundColorName}` });

    const isChecked = colorSetting === currentBackgroundColor;

    const checkedIcon: JSX.Element = <WidgetCheckedIcon backgroundColor={backgroundColor} isChecked={isChecked} />;

    const control: JSX.Element = (
        <Tooltip placement="bottom" title={tooltipText} followCursor>
            <Radio key={colorSetting} icon={checkedIcon} checkedIcon={checkedIcon} />
        </Tooltip>
    );

    return <FormControlLabel key={colorSetting} value={colorSetting} label="" control={control} />;
};
