import { MoreHoriz } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, ButtonGroup, Grid, IconButton, Typography } from '@mui/material';
import {
    ActionMenuItem,
    activeSelector,
    focusSelector,
    grey,
    hoverSelector,
    makeSxStyles,
    MenuButton,
} from '@platform/front-ui';
import { atLeastOneTruthy, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router';
import { CustomDividerForDialog as CustomDividerForDialogInj } from '../../../../../../../components/divider';
import {
    reactGridLayoutDraggableHandleClassName,
    widgetControlsContainerClassName,
} from '../../../../../../../constants';
import { useDashboardPageContext, useDashboardsAuxContext } from '../../../../../../../hooks';
import { useDashboardSectionContext } from '../../../../../../../hooks/contexts/useDashboardSectionContext';
import { WidgetModel } from '../../../../../../../models';
import { WidgetPermissions } from '../../../../../../../types';
import { skeletify } from '../../../../../../../utils';
import { WidgetDeleteDialog as WidgetDeleteDialogInj } from '../widget-dialogs';
import { WidgetBackgroundColorButton as WidgetBackgroundColorButtonInj } from './widget-background-color-button';
import { FontButton as FontButtonInj } from './widget-font-button';

export type WidgetGridItemActionsProps = {
    widgetModel?: WidgetModel;
    setShouldDisplayWidgetActions: React.Dispatch<React.SetStateAction<boolean>>;
    widgetPermissions: WidgetPermissions;
};

const sxStyles = makeSxStyles({
    controlsContainer: {
        position: 'absolute',
        width: 0,
        top: (theme) => theme.spacing(3),
        display: 'none',
        zIndex: 1,
    },
    controlsContainerButtonGroup: {
        width: '100%',
    },
    widgetControlsDragIcon: {
        opacity: 0.3,
        position: 'relative',
        top: (theme) => theme.spacing(0.3),
        left: (theme) => theme.spacing(1),
        width: 16,
        height: 16,
        color: grey[900],
        cursor: 'grab',
        [hoverSelector]: {
            opacity: 0.6,
        },
        [activeSelector]: {
            opacity: 1,
            cursor: 'grabbing',
        },
        [focusSelector]: {
            opacity: 1,
            cursor: 'grabbing',
        },
    },
    widgetControlsMenuButton: {
        color: grey[900],
        position: 'fixed',
        left: (theme) => `calc(100% - ${theme.spacing(6)})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        borderRadius: (theme) => theme.spacing(0.5),
        opacity: 0.3,
        overflow: 'hidden',
        backgroundColor: grey[0],
        [hoverSelector]: {
            opacity: 0.6,
        },
        [activeSelector]: {
            opacity: 0.8,
        },
    },
    typography: {
        color: grey[500],
    },
    gridItem: {
        mb: 1,
    },
});

export const WidgetsGridItemActions = observer((props: WidgetGridItemActionsProps): JSX.Element => {
    const [WidgetDeleteDialog] = di([WidgetDeleteDialogInj], WidgetsGridItemActions);
    const [WidgetBackgroundColorButton] = di([WidgetBackgroundColorButtonInj], WidgetsGridItemActions);
    const [FontButton] = di([FontButtonInj], WidgetsGridItemActions);
    const [CustomDividerForDialog] = di([CustomDividerForDialogInj], WidgetsGridItemActions);

    const { widgetModel, widgetPermissions, setShouldDisplayWidgetActions } = props;
    const { isAllowedToDeleteWidget, isAllowedToUpdateWidget, isAllowedToUpdateSection, isPermissionsLoading } =
        widgetPermissions;

    const { dashboardModel } = useDashboardPageContext();
    const { sectionModel } = useDashboardSectionContext();
    const { routes } = useDashboardsAuxContext();
    const history = useHistory();
    const [isWidgetDeletingModalOpen, openWidgetDeletingModal, closeWidgetDeletingModal] = useFlag();

    const { id: dashboardId } = dashboardModel;
    const { id: sectionId } = sectionModel;
    const widgetId = widgetModel?.id;

    const shouldRenderWidgetBackgroundColor = widgetModel && isAllowedToUpdateSection && isAllowedToUpdateWidget;
    const shouldRenderFontButton = widgetModel && isAllowedToUpdateWidget;

    const onEditWidgetButtonClick = (widgetId: string): void => {
        history.push(
            generatePath(routes.dashboardSectionEditWidget, {
                dashboardId,
                sectionId,
                widgetId,
            }),
        );
    };

    const renderWidgetMenuButtonClick = (onClick: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element => {
        const onButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
            setShouldDisplayWidgetActions(true);
            onClick(event);
        };
        return (
            <IconButton onClick={onButtonClick} sx={sxStyles.widgetControlsMenuButton}>
                <MoreHoriz />
            </IconButton>
        );
    };

    const renderWidgetMenuButtonItems = (hideMenu: VoidFunction): ReactNode[] => {
        const onEditDashboardClick = (): void => {
            hideMenu();
            setShouldDisplayWidgetActions(false);
            widgetId && onEditWidgetButtonClick(widgetId);
        };
        const onDeleteDashboardClick = (): void => {
            hideMenu();
            setShouldDisplayWidgetActions(false);
            openWidgetDeletingModal();
        };
        return [
            <Grid item container key="edit-decor-buttons" flexDirection="column" paddingX={2} spacing={1}>
                {shouldRenderWidgetBackgroundColor && (
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="body1" sx={sxStyles.typography}>
                                <FormattedMessage id="widget.widgetBackgroundColor.buttonTooltip" />
                            </Typography>
                        </Grid>

                        <WidgetBackgroundColorButton key="backgroundColor" widgetModel={widgetModel} />

                        <CustomDividerForDialog spacing={4} />
                    </React.Fragment>
                )}
                {shouldRenderFontButton && (
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="body1" sx={sxStyles.typography}>
                                <FormattedMessage id="widget.widgetFontSize.buttonTooltip" />
                            </Typography>
                        </Grid>

                        <FontButton key="fontSize" widgetModel={widgetModel} />

                        <CustomDividerForDialog spacing={4} gridItemSx={sxStyles.gridItem} />
                    </React.Fragment>
                )}
            </Grid>,
            isAllowedToUpdateWidget && (
                <ActionMenuItem messageId="common.edit" onClick={onEditDashboardClick} key="edit-button" />
            ),
            isAllowedToDeleteWidget && (
                <ActionMenuItem messageId="common.delete" onClick={onDeleteDashboardClick} key="delete-button" />
            ),
        ];
    };

    const controlsContainerSx = isPermissionsLoading ? undefined : sxStyles.controlsContainer;
    const controlsContainerClassName = isPermissionsLoading ? undefined : widgetControlsContainerClassName;
    const onControlsContainerMouseLeave = (): void => {
        setShouldDisplayWidgetActions(false);
    };

    const shouldRenderWidgetDeleteDialog = isAllowedToDeleteWidget && isWidgetDeletingModalOpen;
    const shouldRenderChangeWidgetPositionButton = isAllowedToUpdateSection;

    const content: ReactNode = (
        <Box
            sx={controlsContainerSx}
            className={controlsContainerClassName}
            onMouseLeave={onControlsContainerMouseLeave}
        >
            {shouldRenderWidgetDeleteDialog && (
                <WidgetDeleteDialog onCancel={closeWidgetDeletingModal} widgetId={widgetId} sectionId={sectionId} />
            )}
            <ButtonGroup sx={sxStyles.controlsContainerButtonGroup} variant="text" size="small" color="secondary">
                {shouldRenderChangeWidgetPositionButton && (
                    <Box className={reactGridLayoutDraggableHandleClassName}>
                        <DragIndicatorIcon sx={sxStyles.widgetControlsDragIcon} />
                    </Box>
                )}
                {atLeastOneTruthy(isAllowedToDeleteWidget, isAllowedToUpdateWidget) && (
                    <MenuButton
                        renderButton={renderWidgetMenuButtonClick}
                        renderMenuItems={renderWidgetMenuButtonItems}
                    />
                )}
            </ButtonGroup>
        </Box>
    );

    return (
        <React.Fragment>
            {skeletify({
                component: content,
                additionalConditions: [
                    atLeastOneTruthy(isAllowedToDeleteWidget, isAllowedToUpdateWidget, isAllowedToUpdateSection),
                ],
                isLoading: isPermissionsLoading,
                skeletonSx: { ...sxStyles.controlsContainer, display: 'block' },
            })}
        </React.Fragment>
    );
});
