import { CodeTitleNull, PromiseVoidFunction } from '@platform/front-utils';
import { titlesDTOtoTitles } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router';
import { RootStore } from '../../stores';
import {
    CounterWidgetCreateEditFormSettingsFields,
    DashboardRoutes,
    LinkWidgetCreateEditFormSettingsValueKeys,
    WidgetBaseDataDTOViewSettings,
    WidgetCreateEditFormCommonFields,
    WidgetCreateEditFormValues,
    WidgetType,
    WidgetUpdateInfoDTO,
} from '../../types';
import {
    getCounterWidgetSettingsFieldValues,
    getDefaultWidgetCreateEditFormInitialValues,
    getWidgetCreateUpdateDTOFromFormValues,
    getWidgetTypeSelectOptions,
    transformWidgetCreationInfoForFrontend,
} from '../../utils';
import {
    WidgetCreateEditDialogModel,
    widgetCreateEditDialogModelAbstractObservables,
} from './WidgetCreateEditDialogModel';

export const widgetEditDialogModelObservables = {
    ...widgetCreateEditDialogModelAbstractObservables,
    widgetId: observable,
    reloadWidgetModel: action.bound,

    loadCurrentWidgetInfo: action.bound,
    updateFormInitialValuesFromCurrentWidgetData: action.bound,
    updateFormInitialValuesForCounterWidget: action.bound,
    updateFormInitialValuesForLinkWidget: action.bound,
    getWidgetTypeFormValue: action.bound,

    onDelete: action.bound,
};

/**
 * Класс, содержащий данные и логику редактирования виджета
 */
export class WidgetEditDialogModel extends WidgetCreateEditDialogModel {
    widgetId: string;
    reloadWidgetModel: PromiseVoidFunction | VoidFunction;

    constructor(
        dashboardId: string,
        sectionId: string,
        widgetId: string,
        rootStore: RootStore,
        reloadWidgetModel: PromiseVoidFunction | VoidFunction,
        routes: DashboardRoutes,
    ) {
        super(dashboardId, sectionId, rootStore, routes);
        this.widgetId = widgetId;
        this.reloadWidgetModel = reloadWidgetModel;
        makeObservable(this, widgetEditDialogModelObservables);
    }

    async loadCurrentWidgetInfo(): Promise<WidgetUpdateInfoDTO> {
        return this.dashboardStore.getWidgetUpdateInfo(this.sectionId, this.widgetId);
    }

    async updateFormInitialValuesFromCurrentWidgetData(): Promise<void> {
        try {
            this.setIsFormInitialValuesLoading(true);
            const updateInfoDTO: WidgetUpdateInfoDTO = await this.loadCurrentWidgetInfo();
            const { widgetType } = updateInfoDTO;

            switch (widgetType.code) {
                case WidgetType.counter: {
                    await this.updateFormInitialValuesForCounterWidget(updateInfoDTO);
                    break;
                }
                case WidgetType.link: {
                    this.updateFormInitialValuesForLinkWidget(updateInfoDTO);
                    break;
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setIsFormInitialValuesLoading(false);
        }
    }

    async updateFormInitialValuesForCounterWidget(updateInfoDTO: WidgetUpdateInfoDTO): Promise<void> {
        try {
            this.setIsFormInitialValuesLoading(true);
            const creationSettings = await this.dashboardStore.getWidgetCreationInfoSettingsByWidgetType(
                this.sectionId,
                WidgetType.counter,
            );
            const transformedCreationSettings = transformWidgetCreationInfoForFrontend(
                WidgetType.counter,
                creationSettings,
            );
            this.setSettingsForSelectedWidgetType(transformedCreationSettings);
            const widgetTypeCounterSettingsFieldsValues = getCounterWidgetSettingsFieldValues(
                updateInfoDTO,
                creationSettings,
            );
            this.setFormInitialValues({
                [WidgetCreateEditFormCommonFields.titles]: titlesDTOtoTitles(updateInfoDTO.titles),
                [WidgetCreateEditFormCommonFields.widgetType]: this.getWidgetTypeFormValue(WidgetType.counter),
                settings: {
                    ...getDefaultWidgetCreateEditFormInitialValues(this.intlStore).settings,
                    ...widgetTypeCounterSettingsFieldsValues,
                },
                iconCode: updateInfoDTO.iconCode,
                [WidgetCreateEditFormCommonFields.backgroundColor]: updateInfoDTO.viewSettings.backgroundColor,
            });
        } catch (e) {
            console.error(e);
        } finally {
            this.setIsFormInitialValuesLoading(false);
        }
    }

    updateFormInitialValuesForLinkWidget(updateInfoDTO: WidgetUpdateInfoDTO): void {
        const { titles, settings, iconCode } = updateInfoDTO;
        if ('descriptions' in settings && 'link' in settings) {
            this.setFormInitialValues({
                [WidgetCreateEditFormCommonFields.titles]: titlesDTOtoTitles(titles),
                [WidgetCreateEditFormCommonFields.widgetType]: this.getWidgetTypeFormValue(WidgetType.link),
                settings: {
                    ...getDefaultWidgetCreateEditFormInitialValues(this.intlStore).settings,
                    [LinkWidgetCreateEditFormSettingsValueKeys.descriptions]: titlesDTOtoTitles(settings.descriptions),
                    [LinkWidgetCreateEditFormSettingsValueKeys.link]: settings.link,
                },
                iconCode: iconCode,
                [WidgetCreateEditFormCommonFields.backgroundColor]: updateInfoDTO.viewSettings.backgroundColor,
            });
        }
    }

    getWidgetTypeFormValue(widgetType: WidgetType): CodeTitleNull {
        return (
            getWidgetTypeSelectOptions(this.intlStore.intl).find(
                (widgetTypeOption) => widgetTypeOption?.code === widgetType,
            ) || null
        );
    }

    onSubmit(formValues: WidgetCreateEditFormValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const widgetUpdateDTO = getWidgetCreateUpdateDTOFromFormValues(formValues);
        return !widgetUpdateDTO
            ? Promise.reject()
            : this.dashboardStore
                  .updateWidget(
                      this.widgetId,
                      this.sectionId,
                      widgetUpdateDTO,
                      Object.values({
                          ...WidgetCreateEditFormCommonFields,
                          ...CounterWidgetCreateEditFormSettingsFields,
                      }),
                  )
                  .then(() => {
                      this.history.push(generatePath(this.routes.dashboard, { dashboardId: this.dashboardId }));
                      this.reloadWidgetModel();
                  })
                  .catch(this.serverErrors.setServerFormErrors);
    }

    onDelete(): Promise<void> {
        return this.dashboardStore.deleteWidget(this.widgetId, this.sectionId);
    }
}
