import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { onDragEndCreator } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import {
    autoScrollToNewSectionDelay,
    highlightDuration,
    sectionHighlightActivationDelay,
} from '../../../../../constants';
import { SectionModel } from '../../../../../models';
import { SectionListItem as SectionListItemInj } from './SectionListItem';
import {
    WidgetCreateDialog as WidgetCreateDialogInj,
    WidgetEditDialog as WidgetEditDialogInj,
} from './widget-components';

const sxStyles = makeSxStyles({
    sectionList: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        pr: 1,
        pl: 1,
    },
});

export const SectionList = observer((): JSX.Element => {
    const [SectionListItem] = di([SectionListItemInj], SectionList);
    const [WidgetEditDialog] = di([WidgetEditDialogInj], SectionList);
    const [WidgetCreateDialog] = di([WidgetCreateDialogInj], SectionList);

    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const {
        id: dashboardId,
        sections,
        setSectionsAfterPositionChange,
        setShouldAutoscrollToTheLastListElement,
        shouldAutoscrollToTheLastListElement,
        shouldHighlightTheLastListElement,
        setShouldHighlightTheLastListElement,
    } = dashboardModel;
    const { changeSectionPosition } = useStore().dashboardStore;

    const theLastListItemRef = useRef<HTMLDivElement | null>();
    const sectionListRef = useRef<HTMLDivElement | null>();

    useEffect(() => {
        if (shouldAutoscrollToTheLastListElement && theLastListItemRef.current) {
            if (sectionListRef.current) {
                const { scrollHeight, clientHeight } = sectionListRef.current;
                const isListHasVerticalScroll = scrollHeight > clientHeight;
                if (isListHasVerticalScroll) {
                    setTimeout(() => {
                        theLastListItemRef.current?.scrollIntoView({ behavior: 'smooth' });
                        setTimeout(() => {
                            // при scrollIntoView({ behavior: 'smooth' }) скролл до раздела происходит плавно -> новый раздел во view появлется не сразу
                            // задержка активации подсветки нужна для того чтобы пока происходит скролл не отнималось время анимации
                            setShouldHighlightTheLastListElement(true);
                        }, sectionHighlightActivationDelay);
                    }, autoScrollToNewSectionDelay);
                } else {
                    setShouldHighlightTheLastListElement(true);
                }
            }
        }
    }, [shouldAutoscrollToTheLastListElement]);

    useEffect(() => {
        if (shouldAutoscrollToTheLastListElement && shouldHighlightTheLastListElement) {
            setTimeout(() => {
                setShouldHighlightTheLastListElement(false);
                setShouldAutoscrollToTheLastListElement(false);
            }, highlightDuration);
        }
    }, [shouldAutoscrollToTheLastListElement, shouldHighlightTheLastListElement]);

    const onDragEnd = onDragEndCreator(
        (sectionId: string, newPosition: number): void => {
            changeSectionPosition(dashboardId, sectionId, newPosition);
        },
        sections,
        setSectionsAfterPositionChange,
    );

    const mapSectionsDataToSectionList = (sections: SectionModel[]): React.ReactNode[] => {
        const lastIndex = sections.length - 1;
        return sections.map((sectionModel, i) => {
            const sectionId = sectionModel.id;

            const renderDraggableProvided = (
                draggableProvided: DraggableProvided,
                snapshot: DraggableStateSnapshot,
            ): JSX.Element => {
                const isLastElementInList = i === lastIndex;

                return (
                    <Grid item>
                        <SectionListItem
                            draggableProvided={draggableProvided}
                            sectionModel={sectionModel}
                            theLastListItemRef={isLastElementInList ? theLastListItemRef : undefined}
                            key={sectionId}
                            isWithDragging={snapshot.isDragging}
                        />
                    </Grid>
                );
            };

            return (
                <Draggable draggableId={sectionId} key={sectionId} index={i}>
                    {renderDraggableProvided}
                </Draggable>
            );
        });
    };

    return (
        <React.Fragment>
            <Route path={routes.dashboardSectionCreateWidget}>
                <WidgetCreateDialog />
            </Route>
            <Route path={routes.dashboardSectionEditWidget}>
                <WidgetEditDialog />
            </Route>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sectionList">
                    {(droppableProvided) => {
                        const setSectionListRefs = (elementRef: HTMLDivElement | null): void => {
                            droppableProvided.innerRef(elementRef);
                            sectionListRef.current = elementRef;
                        };
                        return (
                            <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                sx={sxStyles.sectionList}
                                ref={setSectionListRefs}
                                {...droppableProvided.droppableProps}
                            >
                                {mapSectionsDataToSectionList(sections)}
                                {droppableProvided.placeholder}
                            </Grid>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    );
});
