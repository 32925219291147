import { Typography } from '@mui/material';
import { ReorderingFunction } from '@platform/front-core';
import { DynamicEllipsisBox } from '@platform/front-ui';
import { LoadingModel, reorderList } from '@platform/front-utils';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { generatePath } from 'react-router-dom';
import { DndRouteSettingWithId } from '../../../pages';
import { DashboardStore, PermissionsStore, RootStore } from '../../../stores';
import { DashboardRoutesWithAuxHandlers, DashboardTabDTO } from '../../../types';

export const dashboardTabsModelObservables = {
    dashboardStore: observable,
    rootStore: observable,
    routes: observable,
    tabs: observable,

    isThereAnyTabs: computed,

    checkMoveDashboardTabsAuth: action.bound,
    checkViewDashboardsListAuth: action.bound,
    getTabs: action.bound,
    redirectToFirstTabsPath: action.bound,
    reorderList: action.bound,
    setTabs: action.bound,
};

/** Модель для работы с табами дашбода */
export class DashboardTabsModel extends LoadingModel {
    protected dashboardStore: DashboardStore;
    protected rootStore: RootStore;

    routes: DashboardRoutesWithAuxHandlers;
    tabs: DndRouteSettingWithId[] = [];

    /**
     * @param {DashboardRoutesWithAuxHandlers} routes - Пути для соотв. типа страницы дашбордов
     */
    constructor(rootStore: RootStore, routes: DashboardRoutesWithAuxHandlers) {
        super();
        this.rootStore = rootStore;
        this.dashboardStore = rootStore.dashboardStore;
        this.routes = routes;
        makeObservable(this, dashboardTabsModelObservables);
    }

    get isThereAnyTabs(): boolean {
        return !!this.tabs.length;
    }

    /**
     * Проверяет авторизацию на просмотр списка табов дашборда
     */
    checkViewDashboardsListAuth(): Promise<boolean> {
        const { permissionsStore, authorizationStore } = this.rootStore.coreRootStore;
        const { systemConfig } = permissionsStore as PermissionsStore;
        const viewDashboardListQuery = systemConfig.viewDashboardList();
        return authorizationStore.check(viewDashboardListQuery);
    }

    /**
     * Проверяет авторизацию на передвижение табов дашборда
     */
    checkMoveDashboardTabsAuth(): Promise<boolean> {
        const { permissionsStore, authorizationStore } = this.rootStore.coreRootStore;
        const { systemConfig } = permissionsStore as PermissionsStore;
        const updatePositionsQuery = systemConfig.updateDashboardPositions();
        return authorizationStore.check(updatePositionsQuery);
    }

    /**
     * Редиректит пользователя на роут первого таба
     */
    redirectToFirstTabsPath(): void {
        const [firstTab] = this.tabs;
        typeof firstTab?.path === 'string' && this.rootStore.history.push(firstTab.path);
    }

    /**
     * @see {@link ReorderingFunction}
     */
    reorderList(tabs: DndRouteSettingWithId[], startIndex: number, endIndex: number): void {
        const reorderedList = reorderList<DndRouteSettingWithId>(toJS(tabs), startIndex, endIndex);
        const reorderedListWithIndices = reorderedList.map((item, index) => ({
            ...item,
            tab: index,
        }));
        this.dashboardStore.syncDashboardsTabsPosition(reorderedListWithIndices.map((tab) => tab.dashboardId));
        this.setTabs(reorderedListWithIndices);
    }

    /**
     * Получает ДТО табов, и сохраняет их в виде объектов для днд.
     */
    async getTabs(isAllowedToMoveTabs = true): Promise<void> {
        const tabsDTO: DashboardTabDTO[] = await this.dashboardStore.getDashboardTabs();
        const indexOfInitialTab: number = tabsDTO.findIndex((tab) => tab.isInitial);
        const initialTab = indexOfInitialTab > 0 ? tabsDTO.splice(indexOfInitialTab, 1) : [];

        const dndTabs: DndRouteSettingWithId[] = [...initialTab, ...tabsDTO].map((tab, index) => ({
            tab: index,
            title: tab.title,
            draggable: isAllowedToMoveTabs && !tab.isInitial,
            dashboardId: tab.dashboardId,
            path: generatePath(this.routes.dashboard, { dashboardId: tab.dashboardId }),
        }));

        this.setTabs(dndTabs);
    }

    /**
     * @param {DndRouteSettingWithId[]} tabs - Объекты для днд.
     */
    setTabs(tabs: DndRouteSettingWithId[]): void {
        this.tabs = tabs;
    }
}
