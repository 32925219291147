import UpdateIcon from '@mui/icons-material/Update';
import { ButtonProps, Grid } from '@mui/material';
import { useFetchAllPermissions } from '@platform/front-core';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import { WidgetModel } from 'models';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CreateSectionButton as CreateSectionButtonInj, CustomButton as CustomButtonInj } from '../../../components';
import { LastUpdateTime } from '../../../components/last-update-time/LastUpdateTime';
import { useCalculateLastUpdate, useDashboardPageContext, useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { DashboardPageHeaderMenuButton as DashboardPageHeaderMenuButtonInj } from '../../dashboards';

export const MyDashboardPageHeader = observer((): JSX.Element => {
    const [DashboardPageHeaderMenuButton] = di([DashboardPageHeaderMenuButtonInj], MyDashboardPageHeader);
    const [CreateSectionButton] = di([CreateSectionButtonInj], MyDashboardPageHeader);
    const [CustomButton] = di([CustomButtonInj], MyDashboardPageHeader);

    const { dashboardModel } = useDashboardPageContext();
    const { dashboardConfig } = useStore().coreRootStore.permissionsStore as PermissionsStore;
    const { id: dashboardId, sections, load } = dashboardModel;

    const allPermissionsQueries = useMemo(() => {
        return {
            allPermissionsQueries: [dashboardConfig.createSection(dashboardId)],
        };
    }, [dashboardId]);

    const [checkResults] = useFetchAllPermissions(allPermissionsQueries);
    const [isAllowedToCreateSection] = checkResults;

    const { whenUpdated, diffMinute, isWithColor, updateLastTime } = useCalculateLastUpdate();

    const onUpdateSectionButtonClick = (): Promise<void> => {
        return load().then(updateLastTime);
    };

    const widgets: WidgetModel[] = Object.values(sections).flatMap((section) => section.widgets);

    useEffect(() => {
        updateLastTime();
    }, [sections.length, widgets.length, dashboardModel.title]);

    const [isSubmitDisabled, submitEndIcon, submitHandler] = useAntiDoubleClick(onUpdateSectionButtonClick);

    const updateSectionButtonConfig: ButtonProps = {
        onClick: submitHandler,
        endIcon: submitEndIcon,
        disabled: isSubmitDisabled,
        startIcon: <UpdateIcon />,
    };

    const shouldDisplayCreateSectionButton = sections.length && isAllowedToCreateSection;

    return (
        <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
            <Grid item />
            <Grid item container width="auto" alignItems="center" flexShrink={0} marginLeft="auto" spacing={0.75}>
                <Grid item display="flex" flexDirection="row">
                    <LastUpdateTime whenUpdated={whenUpdated} diffMinute={diffMinute} isWithColor={isWithColor} />
                    <CustomButton {...updateSectionButtonConfig}>
                        <FormattedMessage id="common.update" />
                    </CustomButton>
                </Grid>
                {!!shouldDisplayCreateSectionButton && (
                    <Grid item>
                        <CreateSectionButton />
                    </Grid>
                )}
                <Grid item>
                    <DashboardPageHeaderMenuButton />
                </Grid>
            </Grid>
        </Grid>
    );
});
