import {
    WidgetDTOWithOptionalWidgetAttributes,
    WidgetType,
    LinkWidgetDTO,
    LinkWidgetSpecificAttributes,
} from '../../types';

const objContainingAllRequiredLinkWidgetAttributes: Record<keyof LinkWidgetSpecificAttributes, undefined> = {
    description: undefined,
    link: undefined,
};

/**
 * Функция type predicate для определения что в модели виджета содержатся данные виджета-ссылки
 * У нас изначально WidgetModel создается на основе {@link WidgetBaseDataDTO}, которая
 * НЕ содержит специфичные для конкретного виджета данные (они подгружаются в самой модели отдельным запросом)
 * Поэтому когда мы рендерим компонент виджета-ссылки нам нужно убедиться что в модели
 * есть необходимые для его отображения данные
 */
export const isWidgetTypeLink = (widget: WidgetDTOWithOptionalWidgetAttributes): widget is LinkWidgetDTO => {
    const { widgetType, widgetAttributes } = widget;
    if (widgetAttributes) {
        const isWidgetAttributesContainsWidgetTypeCounterData = Object.keys(
            objContainingAllRequiredLinkWidgetAttributes,
        ).every((key) => key in widgetAttributes);
        return widgetType === WidgetType.link && isWidgetAttributesContainsWidgetTypeCounterData;
    }
    return false;
};
