import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from '@mui/material';
import { activeSelector, blue, grey, hoverSelector, makeSxStyles, mergeSxStyles, SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getAreaButtonDashedBorder } from '../../utils';

const sxStyles = makeSxStyles({
    button: {
        borderRadius: '8px',
        backgroundImage: getAreaButtonDashedBorder(grey[300]),
        [hoverSelector]: {
            backgroundImage: getAreaButtonDashedBorder(blue[700]),
            backgroundColor: grey[0],
            color: blue[700],
        },
        [activeSelector]: {
            backgroundImage: getAreaButtonDashedBorder(blue[800]),
            backgroundColor: grey[0],
            color: blue[800],
        },
        '&:disabled': {
            border: 'none',
            backgroundColor: 'none',
            backgroundImage: getAreaButtonDashedBorder(grey[400]),
            color: grey[300],
        },
        '& .MuiButton-startIcon': {
            mr: 0.5,
        },
    },
});

export const DashedBorderButton = observer(
    React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
        const {
            variant = 'text',
            color = 'secondary',
            startIcon = <AddIcon />,
            children,
            sx: sxProp,
            ...restProps
        } = props;

        const sxArray: SxStyle[] = [sxStyles.button];
        sxProp && sxArray.push(sxProp);
        const sx = mergeSxStyles(...sxArray);

        return (
            <Button ref={ref} sx={sx} variant={variant} color={color} startIcon={startIcon} {...restProps}>
                {children}
            </Button>
        );
    }),
);
