import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../hooks';
import { DashboardEditDialogModel } from '../../../../../models';
import { DashboardRouteParams } from '../../../../../types';
import { DashboardCreateEditDialog as DashboardCreateEditDialogInj } from '../../../dashboard-create-edit-dialog';

export const DashboardEditDialog = observer((): JSX.Element => {
    const [DashboardCreateEditDialog] = di([DashboardCreateEditDialogInj], DashboardEditDialog);

    const rootStore = useStore();
    const { dashboardId } = useParams<DashboardRouteParams>();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();

    const reloadDashboardData = async (): Promise<void> => {
        await dashboardModel.dashboardTabsModel?.getTabs();
        await dashboardModel.load();
    };

    const dashboardEditDialogModel = useMemo(
        () => new DashboardEditDialogModel(rootStore, dashboardId, reloadDashboardData, routes),
        [rootStore, dashboardId, dashboardModel, routes],
    );

    useEffect(() => {
        dashboardEditDialogModel.loadCurrentDashboardInfo();
    }, [dashboardEditDialogModel]);

    return <DashboardCreateEditDialog dashboardCreateOrEditDialogModel={dashboardEditDialogModel} />;
});
