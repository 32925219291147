import { LocalesTree } from '@platform/front-utils';

export const common: LocalesTree = {
    hoursAgo: 'ч. назад',
    instructionFileName: 'Инструкция.docx',
    instructionSrc: '-',
    lastUpdate: 'Последнее обновление:',
    lessThanMinuteAgo: 'меньше минуты назад',
    minuteAgo: 'мин. назад',
    or: 'или',
    update: 'Обновить',
    yesterday: 'вчера',
};
