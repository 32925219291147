import { useContext } from 'react';
import { DashboardsAuxContext, DashboardsAuxContextType } from '../../contexts';

/**
 * Данный контекст используется для передачи дополнительных данных
 * для работы страниц с дашбордами, например, конфигурации роутов и тому подобного.
 */
export const useDashboardsAuxContext = (): DashboardsAuxContextType => {
    return useContext(DashboardsAuxContext);
};
