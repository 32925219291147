import { Box } from '@mui/material';
import { activeSelector, blue, focusSelector, makeSxStylesWithProps, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { stylesForCounterWidgetFontSizeSetting } from '../../../../../../../../constants/widgetStyles';
import { WidgetModel } from '../../../../../../../../models';
import { CounterWidgetSpecificAttributes, WidgetFontSizeViewSetting } from '../../../../../../../../types';
import { CounterWidgetContentLayout as CounterWidgetContentLayoutInj } from './CounterWidgetContentLayout';

type GetSxStyles = {
    fontSize: WidgetFontSizeViewSetting;
};

const getSxStyles = makeSxStylesWithProps((props: GetSxStyles) => {
    const { fontSize } = props;

    return {
        link: {
            display: 'block',
            width: '100%',
            height: '100%',
            textDecoration: 'none',
            color: 'unset',
            borderRadius: '8px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            [focusSelector]: {
                borderColor: blue[700],
                outline: 'none',
            },
            [activeSelector]: {
                borderColor: blue[700],
                outline: 'none',
            },
        },
        counterValueTypography: {
            ...stylesForCounterWidgetFontSizeSetting[fontSize].count,

            width: '100%',
            wordBreak: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        },
    };
});

export type CounterWidgetProps = {
    widgetModel: WidgetModel;
};

/**
 * Компонента виджета-счетчика
 * @param props {@link CounterWidgetProps}
 */
export const CounterWidget = observer((props: CounterWidgetProps): JSX.Element => {
    const [CounterWidgetContentLayout] = di([CounterWidgetContentLayoutInj], CounterWidget);

    const { widgetModel } = props;
    const { widgetAttributes, title, viewSettings, iconCode } = widgetModel;
    const { fontSize, backgroundColor } = viewSettings;

    const sxStyles = getSxStyles({ fontSize });

    if (!widgetAttributes) {
        return <React.Fragment />;
    }

    const { count, url, description } = widgetAttributes as CounterWidgetSpecificAttributes;

    const countLayoutComponent = (
        <Typography sx={sxStyles.counterValueTypography} title={String(count)} isWithoutAutoCapitalize>
            {count}
        </Typography>
    );

    return (
        <Box component="a" href={url} sx={sxStyles.link} target="_blank">
            <CounterWidgetContentLayout
                count={countLayoutComponent}
                title={title}
                titleTitlePropValue={title}
                description={description}
                descriptionTitlePropValue={description}
                fontSize={fontSize}
                iconCode={iconCode}
                backgroundColor={backgroundColor}
            />
        </Box>
    );
});
