import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { SxStyle, SxStyles } from '@platform/front-types';
import { blue, grey, hoverSelector, makeSxStylesWithProps } from '@platform/front-ui';
import { Field, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { widgetIcons } from '../../constants';
import { WidgetBaseIconCode, WidgetCreateEditFormCommonFields, WidgetCreateEditFormValues } from '../../types';

type GetRadioSxStylesProps = {
    noneIcon: boolean;
};

const defaultSx: SxStyle = {
    borderRadius: '4px',
    border: '2px solid transparent',
    padding: 0.2,

    'input:hover ~ &': {
        borderColor: blue[600],
    },
};

const sxStyles: SxStyles = {
    container: {
        marginLeft: 2,
        marginTop: 4,
    },
    title: {
        marginBottom: 2,
    },
    field: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        paddingLeft: 5,
        boxSizing: 'border-box',
        width: '100%',
    },
    formControlLabel: {
        marginLeft: '0 !important',
    },
    radio: {
        marginRight: 1,
        marginBottom: 2,
        [hoverSelector]: {
            background: 'none',
        },
    },
    clearIcon: {
        ...defaultSx,
        color: grey[200],
    },
    radioIcon: {
        ...defaultSx,
        color: grey[500],
    },
    checkedRadioIcon: {
        ...defaultSx,
        color: grey[700],
        borderColor: blue[600],
    },
    checkedClearIcon: {
        ...defaultSx,
        borderColor: blue[600],
        color: grey[200],
    },
};

const getRadioSxStyles = makeSxStylesWithProps((props: GetRadioSxStylesProps) => {
    const { noneIcon } = props;
    return {
        radio: {
            ...sxStyles.radio,
            ...(noneIcon && {
                position: 'absolute',
                left: 0,
            }),
        },
    };
});

/**
 * Отображение иконок в виде радиокнопок для попапа
 */
export const WidgetIconDecoration = observer((): JSX.Element => {
    const { values, setFieldValue } = useFormikContext<WidgetCreateEditFormValues>();

    const iconsArray = Object.keys(widgetIcons) as [WidgetBaseIconCode];

    const radioIcons: JSX.Element[] = iconsArray.map((key: WidgetBaseIconCode, index) => {
        const Icon = widgetIcons[key];

        const icon = Icon ? <Icon sx={sxStyles.radioIcon} /> : <ClearIcon sx={sxStyles.clearIcon} />;

        const checkedIcon = Icon ? (
            <Icon sx={sxStyles.checkedRadioIcon} />
        ) : (
            <ClearIcon sx={sxStyles.checkedClearIcon} />
        );

        const radioSxStyles = getRadioSxStyles({ noneIcon: !Icon });

        const radioBtn = <Radio checkedIcon={checkedIcon} icon={icon} sx={radioSxStyles.radio} />;

        return <FormControlLabel key={index} value={key} control={radioBtn} label="" sx={sxStyles.formControlLabel} />;
    });

    const onChange = (event: React.ChangeEvent<{}>, value: string): void => {
        setFieldValue(WidgetCreateEditFormCommonFields.iconCode, value);
    };

    const valueIcon = values.iconCode ? values.iconCode : 'none';

    return (
        <Box sx={sxStyles.container}>
            <Typography variant="body1" sx={sxStyles.title}>
                <FormattedMessage id="widget.icon" />
            </Typography>
            <Field
                component={RadioGroup}
                fieldName={WidgetCreateEditFormCommonFields.iconCode}
                value={valueIcon}
                onChange={onChange}
                sx={sxStyles.field}
            >
                {radioIcons}
            </Field>
        </Box>
    );
});
