import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const dashboardMessages = defineMessages({
    createDialogTitle: {
        id: 'dashboard.phrases.create',
        values: {
            dashboardGenitive: <FormattedMessage id="dashboard.single.genitive" />,
        },
    },
    createDashboard: {
        id: 'dashboard.phrases.createDashboard',
        values: {
            dashboardNominative: <FormattedMessage id="dashboard.single.nominative" />,
        },
    },
    editDialogTitle: {
        id: 'dashboard.phrases.edit',
        values: {
            dashboardGenitive: <FormattedMessage id="dashboard.single.genitive" />,
        },
    },
    makeInitialCreateDashboardInfo: {
        id: 'dashboard.phrases.makeInitialCreateDashboardInfo',
        values: {
            dashboardGenitive: <FormattedMessage id="dashboard.single.genitive" />,
            dashboardNominative: <FormattedMessage id="dashboard.single.nominative" />,
        },
    },
    myDashboardsTitle: {
        id: 'dashboard.phrases.myDashboards',
        values: {
            dashboardPluralNominative: <FormattedMessage id="dashboard.plural.nominative" />,
        },
    },
    noMyDashboardsSubtitle: {
        id: 'dashboard.phrases.noMyDashboardsSubtitle',
        values: {
            dashboardNominative: <FormattedMessage id="dashboard.plural.nominative" />,
        },
    },
    registryTitle: {
        id: 'dashboard.phrases.registryTitle',
        values: {
            dashboardPluralGenitive: <FormattedMessage id="dashboard.plural.genitive" />,
        },
    },
});
