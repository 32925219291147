import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { useDashboardPageContext, useDashboardsAuxContext, useStore } from '../../../../../../../hooks';
import { WidgetCreateDialogModel } from '../../../../../../../models';
import { PermissionsStore } from '../../../../../../../stores';
import { DashboardSectionRouteParams } from '../../../../../../../types';
import { WidgetCreateEditDialog as WidgetCreateEditDialogInj } from './WidgetCreateEditDialog';

export const WidgetCreateDialog = observer((): JSX.Element => {
    const [WidgetCreateEditDialog] = di([WidgetCreateEditDialogInj], WidgetCreateDialog);

    const rootStore = useStore();
    const { dashboardModel } = useDashboardPageContext();
    const { routes } = useDashboardsAuxContext();
    const { sections } = dashboardModel;
    const { sectionConfig } = rootStore.coreRootStore.permissionsStore as PermissionsStore;
    const { dashboardId, sectionId } = useParams<DashboardSectionRouteParams>();
    const sectionModelBySectionId = sections.find((sectionModel) => sectionModel.id === sectionId);
    const createWidgetQuery = sectionConfig.createWidget(sectionId);

    const widgetCreateDialogModel = useMemo<WidgetCreateDialogModel | undefined>(() => {
        return sectionModelBySectionId
            ? new WidgetCreateDialogModel(dashboardId, sectionId, rootStore, sectionModelBySectionId.load, routes)
            : undefined;
    }, [dashboardId, sectionId, rootStore, sectionModelBySectionId, routes]);

    return (
        <React.Fragment>
            {widgetCreateDialogModel && (
                <AuthorizationCheck {...createWidgetQuery}>
                    {(allowed: boolean) =>
                        allowed ? (
                            <WidgetCreateEditDialog widgetCreateOrEditDialogModel={widgetCreateDialogModel} />
                        ) : (
                            dashboardId && <Redirect to={generatePath(routes.dashboard, { dashboardId })} />
                        )
                    }
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
