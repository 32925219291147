import { Grid, Typography } from '@mui/material';
import { ServerErrorHelper, useLocale } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { FullScreenDialog, FullScreenForm, FullScreenFormProps, TabsField } from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { MultiLangField } from '@platform/multi-lang-field';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { matchPath, useLocation } from 'react-router-dom';
import {
    CustomDividerForDialog as CustomDividerForDialogInj,
    WidgetIconDecoration as WidgetIconDecorationInj,
} from '../../../../../../../components';
import { widgetMessages } from '../../../../../../../customization';
import { useDashboardsAuxContext, useStore } from '../../../../../../../hooks';
import { WidgetCreateEditDialogModel } from '../../../../../../../models';
import { WidgetCreateEditFormCommonFields, WidgetCreateEditFormValues, WidgetType } from '../../../../../../../types';
import { getWidgetTypeSelectOptions } from '../../../../../../../utils';
import { WidgetColorsField as WidgetColorsFieldInj } from './components';
import { getWidgetCreateEditDialogValidationSchema as getWidgetCreateEditDialogValidationSchemaInj } from './getWidgetCreateEditDialogValidationSchema';
import { getWidgetTypeSpecificFieldsByType as getWidgetTypeSpecificFieldsByTypeInj } from './getWidgetTypeSpecificFieldsByType';
import { WidgetCreateEditDialogContentSkeleton as WidgetCreateEditDialogContentSkeletonInj } from './WidgetCreateEditDialogContentSkeleton';

const gridItemSx: SxStyle = {
    position: 'relative',
};

/**
 * @param widgetCreateOrEditDialogModel {T} - модель, содержащая данные и логику диалога создания/редактирования виджета
 */
export type WidgetCreateEditDialogProps<T> = {
    widgetCreateOrEditDialogModel: T;
};

export const WidgetCreateEditDialog = observer(
    <T extends WidgetCreateEditDialogModel>(props: WidgetCreateEditDialogProps<T>): JSX.Element => {
        const [WidgetCreateEditDialogContentSkeleton] = di(
            [WidgetCreateEditDialogContentSkeletonInj],
            WidgetCreateEditDialog,
        );
        const [getWidgetCreateEditDialogValidationSchema] = di(
            [getWidgetCreateEditDialogValidationSchemaInj],
            WidgetCreateEditDialog,
        );
        const [getWidgetTypeSpecificFieldsByType] = di([getWidgetTypeSpecificFieldsByTypeInj], WidgetCreateEditDialog);
        const [WidgetColorsField] = di([WidgetColorsFieldInj], WidgetCreateEditDialog);
        const [CustomDividerForDialog] = di([CustomDividerForDialogInj], WidgetCreateEditDialog);
        const [WidgetIconDecoration] = di([WidgetIconDecorationInj], WidgetCreateEditDialog);

        const { widgetCreateOrEditDialogModel } = props;
        const {
            serverErrors,
            onCancel,
            onSubmit,
            formInitialValues,
            loadWidgetCreationInfoSettings,
            selectedWidgetType,
            setSelectedWidgetType,
            isFormInitialValuesLoading,
        } = widgetCreateOrEditDialogModel;

        const { serverFormErrors } = serverErrors;
        const { headerHeightStore, intlStore } = useStore().coreRootStore;
        const { visibleHeaderHeight } = headerHeightStore;

        const { formatMessageFromDefineMessage } = intlStore;
        const { routes } = useDashboardsAuxContext();
        const location = useLocation();
        const { pathname } = location;

        const isCreating = matchPath(pathname, routes.dashboardSectionCreateWidget);
        const dialogTitle = formatMessageFromDefineMessage(
            isCreating ? widgetMessages.createDialogTitle : widgetMessages.editDialogTitle,
        );

        const intl = useIntl();
        const { formatMessage, locale } = intl;

        const { titles, widgetType } = WidgetCreateEditFormCommonFields;
        const { Yup } = useYup();
        const validationSchema = getWidgetCreateEditDialogValidationSchema(Yup, {
            widgetType: selectedWidgetType,
            linkValidationMessage: formatMessage({ id: 'widget.invalidLink' }),
        });

        const [isSubmitDisabled, submitEndIcon, submitHandler] = useAntiDoubleClick(onSubmit);

        useEffect(() => {
            loadWidgetCreationInfoSettings();
        }, [widgetCreateOrEditDialogModel.selectedWidgetType]);

        const widgetTypeListOptions = useMemo(() => {
            return getWidgetTypeSelectOptions(intl);
        }, [locale, intl]);

        const submitButtonAdditionalProps: FullScreenFormProps['submitBtnAdditionalProps'] = {
            endIcon: submitEndIcon,
            disabled: isSubmitDisabled,
        };

        const titlesPlaceholder = useLocale('widget.phrases.titlesPlaceholder', true, {
            widgetGenitive: useLocale('widget.single.genitive', false)[0],
        })[0];

        return (
            <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} onCancel={onCancel}>
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                    enableReinitialize={true}
                >
                    {({ values }) => {
                        const { widgetType: widgetTypeValue } = values as WidgetCreateEditFormValues;
                        setSelectedWidgetType(widgetTypeValue ? (widgetTypeValue.code as WidgetType) : null);

                        return (
                            <FullScreenForm
                                onCancel={onCancel}
                                dialogTitle={dialogTitle}
                                submitBtnAdditionalProps={submitButtonAdditionalProps}
                            >
                                <Grid container direction="column" spacing={2}>
                                    {isFormInitialValuesLoading ? (
                                        <WidgetCreateEditDialogContentSkeleton />
                                    ) : (
                                        <React.Fragment>
                                            <Grid item>
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage {...widgetMessages.titlesPlaceholder} />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <MultiLangField
                                                    fieldName={titles}
                                                    ruEngLangs={true}
                                                    label={titlesPlaceholder}
                                                    isRequired
                                                />
                                                <ServerErrorHelper serverError={serverFormErrors[titles]} />
                                            </Grid>
                                            <CustomDividerForDialog spacing={2} gridItemSx={gridItemSx} />
                                            <Grid item>
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage {...widgetMessages.typePlaceholder} />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TabsField fieldName={widgetType} options={widgetTypeListOptions} />
                                            </Grid>
                                            {getWidgetTypeSpecificFieldsByType(
                                                widgetTypeValue,
                                                widgetCreateOrEditDialogModel,
                                            )}
                                            <CustomDividerForDialog spacing={2} gridItemSx={gridItemSx} />
                                            <Grid item>
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="widget.decor" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="widget.widgetBackgroundColor.buttonTooltip" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <WidgetColorsField />
                                            </Grid>
                                            <WidgetIconDecoration />
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </FullScreenForm>
                        );
                    }}
                </Formik>
            </FullScreenDialog>
        );
    },
);
