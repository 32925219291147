import { useEffect } from 'react';

/**
 * Хук для замены favicon
 * @param {string | undefined} faviconHref - source фавиконки
 */
export const useReplaceFavicon = (faviconHref?: string): void => {
    useEffect(() => {
        if (faviconHref) {
            let link = document.querySelector('link[rel="icon"]');
            if (!link) {
                link = document.createElement('link');
                link.setAttribute('rel', 'icon');
                const headEl = document.querySelector('head');
                if (headEl) {
                    headEl.appendChild(link);
                }
            }
            link.setAttribute('href', faviconHref);
        }
    }, [faviconHref]);
};
