import { Theme } from '@mui/material';
import { CoreRootStore, Env, getCoreRootStore, RootStoreProps as FrontCoreRootStoreProps } from '@platform/front-core';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { SettingsFromBackend } from '../types';
import { getCurrentUserStore } from './CurrentUserStore';
import { DashboardStore } from './DashboardStore';
import { getGlobalSearchStore } from './GlobalSearchStore';
import { getHeaderLinksStore } from './HeaderLinksStore';
import { getModulesStore } from './ModulesStore';
import { getPermissionsStore } from './permissions-store';
import { getUserStore, UserStore } from './UserStore';

export const rootStoreObservables = {
    history: observable,
    coreRootStore: observable,
    env: observable,
    settingsFromBackend: observable,

    setObservables: action.bound,
    setAppTheme: action.bound,
};

export type RootStoreProps = FrontCoreRootStoreProps & {
    settingsFromBackend: SettingsFromBackend;
};

export class RootStore {
    coreRootStore: CoreRootStore;
    dashboardStore: DashboardStore;
    userStore: UserStore;

    // дефолтные значения добавлены из-за того, что инициализация вынесена в метод
    history: History = {} as History;
    features: RootStoreProps['features'] = {};
    env: Env = {} as Env;
    theme: Theme = {} as Theme;
    settingsFromBackend: SettingsFromBackend = {} as SettingsFromBackend;

    constructor(props: RootStoreProps) {
        makeObservable(this, rootStoreObservables);

        const CoreRootStore = getCoreRootStore();
        const CurrentUserStore = getCurrentUserStore();
        const ModulesStore = getModulesStore();
        const PermissionsStore = getPermissionsStore();
        const HeaderLinksStore = getHeaderLinksStore();
        const UserStore = getUserStore();
        const GlobalSearchStore = getGlobalSearchStore();

        this.setObservables(props);
        this.coreRootStore = new CoreRootStore(props);
        this.coreRootStore.currentUserStore = new CurrentUserStore(this.coreRootStore);
        this.coreRootStore.modulesStore = new ModulesStore(this.coreRootStore);
        this.coreRootStore.permissionsStore = new PermissionsStore();
        this.coreRootStore.headerLinksStore = new HeaderLinksStore(this.coreRootStore);
        this.coreRootStore.globalSearchStore = new GlobalSearchStore(this.coreRootStore);
        this.dashboardStore = new DashboardStore(this.coreRootStore);
        this.userStore = new UserStore(this.coreRootStore);
    }

    setObservables(props: RootStoreProps): void {
        const { env, features, history, settingsFromBackend } = props;
        this.env = env;
        this.features = features;
        this.history = history;
        this.settingsFromBackend = settingsFromBackend;
    }

    setAppTheme(theme: Theme): void {
        this.theme = theme;
    }
}
