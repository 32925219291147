import { CoreRootStore } from '@platform/front-core';
import { LoadingModel } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { ModulesStore } from '../stores';
import { PSModuleDTO } from '../types';

export const homePageModelObservables = {
    modulesStore: observable,
    linksList: observable,

    load: action.bound,
    setLinksList: action.bound,
};

export class HomePageModuleLinksListModel extends LoadingModel {
    protected modulesStore: ModulesStore;

    linksList: PSModuleDTO[] = [];

    constructor(coreRootStore: CoreRootStore) {
        super();
        makeObservable(this, homePageModelObservables);
        this.modulesStore = coreRootStore.modulesStore as ModulesStore;
        this.stopLoading();
    }

    load(): void {
        this.startLoading();
        this.modulesStore
            .getHomePageModuleLinksList()
            .then(this.setLinksList)
            .catch(() => {
                this.setLinksList([]);
            })
            .finally(this.stopLoading);
    }

    setLinksList(linksList: PSModuleDTO[]): void {
        this.linksList = linksList;
    }
}
