import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { Theme, ThemeProvider } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { Env, Loader, RootProps, TotIntlProvider, YMetrika } from '@platform/front-core';
import { createTotTheme } from '@platform/front-ui';
import '@platform/front-ui/lib/index.css';
import { CoreStoreContext, getFullLocales, LocalizedMessages, TotLocale, useCoreStore } from '@platform/front-utils';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { FeaturesProvider, useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import { di, DiProvider } from 'react-magnetic-di';
import 'react-resizable/css/styles.css';
import { Router } from 'react-router-dom';
import { App } from './App';
import { StoreContext } from './contexts';
import { localesEn, localesRu } from './customization';
import { useReplaceFavicon, useSettingsFromBackend, useStore } from './hooks';
import startPageLogo from './resources/images/pop-logo.svg';
import { RootStore as RootStoreInj, RootStoreProps } from './stores';
import { PSFeatures } from './types';
import { getDefaultSettingsFromBackend, getSettingsFromBackend } from './utils';

const defaultLocales: RootStoreProps['locales'] = { ru: localesRu, en: localesEn };

const generateClassName = createGenerateClassName({
    productionPrefix: 'ps-front-core',
    seed: 'ps-front-core',
});

const RootUnwrapped = observer((): JSX.Element => {
    const { intlStore } = useCoreStore();
    const [yaMetrika] = useFeature('yaMetrika');
    return (
        <React.Fragment>
            {yaMetrika && <YMetrika />}
            <TotIntlProvider>
                <LocalizationProvider
                    dateAdapter={DateAdapter}
                    locale={intlStore.locale === TotLocale.ru ? ruLocale : enLocale}
                >
                    <App />
                </LocalizationProvider>
            </TotIntlProvider>
        </React.Fragment>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

const RootWithStore = (props: RootStoreProps): JSX.Element => {
    const [RootStore] = di([RootStoreInj], RootWithStore);

    const { theme } = props;
    const store = new RootStore(props);
    store.setAppTheme(theme);

    return (
        <Router history={store.history}>
            <StoreContext.Provider value={store}>
                <CoreStoreContext.Provider value={store.coreRootStore}>
                    <RootWithFeatures />
                </CoreStoreContext.Provider>
            </StoreContext.Provider>
        </Router>
    );
};

const getStartPageLogoLangConfig = (lang: string, logo?: string) => ({
    alt: lang === 'ru' ? 'TOT Старт логотип' : 'TOT Start logo',
    mdLogo: false,
    src: logo ?? startPageLogo,
    style: {
        width: '240px',
        marginBottom: logo ? '20px' : 0,
    },
});

export const Root = (props: RootProps): JSX.Element => {
    const { env, palette, use = [], locales: propsLocales, features } = props;
    const { apiUrl = '' } = env;
    const [locales, setLocales] = useState<LocalizedMessages>();
    const [settingsFromBackend] = useSettingsFromBackend({
        apiUrl,
        defaultSettings: getDefaultSettingsFromBackend(),
        getSettingsFromBackend,
    });

    useEffect(() => {
        apiUrl &&
            getFullLocales(defaultLocales, apiUrl).then((fullLocales) => {
                !!propsLocales
                    ? setLocales({
                          ru: {
                              ...fullLocales.ru,
                              ...propsLocales.ru,
                          },
                          en: {
                              ...fullLocales.en,
                              ...propsLocales.en,
                          },
                      })
                    : setLocales(fullLocales);
            });
    }, [apiUrl, propsLocales]);

    useReplaceFavicon(settingsFromBackend?.favicon);

    const theme = createTotTheme(palette);

    const envMergedWithSettingsFromBackend: Env = {
        ...env,
        totMail: settingsFromBackend?.supportEmail || env.totMail,
        totTel: settingsFromBackend?.supportPhone || env.totTel,
    };

    const featuresMergedWithSettingsFromBackend: PSFeatures = {
        ...features,
        headerLogo: settingsFromBackend?.headerLogo ?? (features?.headerLogo as PSFeatures['headerLogo']),
        customLogo: {
            isEnabled: true,
            locales: {
                en: getStartPageLogoLangConfig(TotLocale.en, settingsFromBackend?.loginPageLogo),
                ru: getStartPageLogoLangConfig(TotLocale.ru, settingsFromBackend?.loginPageLogo),
            },
        },
    };

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme as Theme}>
                {locales && settingsFromBackend ? (
                    <DiProvider use={use}>
                        <RootWithStore
                            {...props}
                            features={featuresMergedWithSettingsFromBackend}
                            settingsFromBackend={settingsFromBackend}
                            env={envMergedWithSettingsFromBackend}
                            locales={locales}
                            theme={theme}
                        />
                    </DiProvider>
                ) : (
                    <Loader fullSize color="primary" />
                )}
            </ThemeProvider>
        </StylesProvider>
    );
};
