import { Grid, Typography } from '@mui/material';
import { grey, makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { HTMLProps, ReactNode, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { IconForWidget as IconForWidgetInj } from '../../../../../../../../components';
import { stylesForLinkWidgetFontSizeSetting } from '../../../../../../../../constants';
import { useCalculateLinesCount } from '../../../../../../../../hooks';
import {
    WidgetBackgroundColorViewSetting,
    WidgetBaseIconCode,
    WidgetFontSizeViewSetting,
} from '../../../../../../../../types';

type GetCounterWidgetContentLayoutSxStylesProps = {
    fontSize: WidgetFontSizeViewSetting;
    backgroundColor: WidgetBackgroundColorViewSetting;
    isWithDescription: boolean;
    titleLines: number | undefined;
    descriptionLines: number | undefined;
};

const getSxStyles = makeSxStylesWithProps((props: GetCounterWidgetContentLayoutSxStylesProps) => {
    const { isWithDescription, titleLines, descriptionLines, fontSize } = props;
    return {
        contentContainer: {
            position: 'relative',
            padding: 3,
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            flex: '1 1 auto',
            userSelect: 'none',
        },
        mainTextContentWrapper: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flex: '1 1 auto',
            height: '100%',
            userSelect: 'none',
        },
        titleContainerWrapper: {
            flex: '0 0 auto',
            flexWrap: 'nowrap',
            height: isWithDescription ? '62%' : '100%',
            maxWidth: (theme) => `calc(100% - ${theme.spacing(4)})`,
            position: 'relative',
            textAlign: 'left',
        },
        titleContainer: {
            maxHeight: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        titleTypography: {
            ...stylesForLinkWidgetFontSizeSetting[fontSize].title,
            color: grey[900],
            wordBreak: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: titleLines,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        },
        descriptionContainerWrapper: {
            mt: 1,
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            flex: '1 1 auto',
            overflow: 'hidden',
            height: (theme) => `calc(38% - ${theme.spacing(1)})`,
        },
        descriptionContainer: {
            maxHeight: '100%',
            overflow: 'hidden',
        },
        descriptionTypography: {
            ...stylesForLinkWidgetFontSizeSetting[fontSize].description,
            wordBreak: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: descriptionLines,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        },
    };
});

/**
 * @prop {ReactNode} count - Значение счетчика
 * @prop {ReactNode} title - Наименование виджета
 * @prop {HTMLProps<HTMLElement>['title']} titleTitlePropValue? - Значение пропа `title` для наименования виджета
 * @prop {ReactNode} description? - Наименование виджета
 * @prop {HTMLProps<HTMLElement>['title']} descriptionTitlePropValue? - Значение пропа `title` для описания виджета
 */
export type LinkWidgetContentLayoutProps = {
    title: ReactNode;
    titleTitlePropValue?: HTMLProps<HTMLElement>['title'];
    description?: ReactNode;
    descriptionTitlePropValue?: string;
    fontSize: WidgetFontSizeViewSetting;
    backgroundColor: WidgetBackgroundColorViewSetting;
    iconCode?: WidgetBaseIconCode;
};

export const LinkWidgetContentLayout = observer((props: LinkWidgetContentLayoutProps): JSX.Element => {
    const [IconForWidget] = di([IconForWidgetInj], LinkWidgetContentLayout);
    const { title, titleTitlePropValue, description, descriptionTitlePropValue, fontSize, iconCode, backgroundColor } =
        props;

    const titleTypographyRef = useRef<HTMLDivElement>(null);
    const [titleContainerRef, titleLines] = useCalculateLinesCount<HTMLDivElement>({
        elementContainingLineHeightRef: titleTypographyRef,
    });

    const descriptionTypographyRef = useRef<HTMLDivElement>(null);
    const [descriptionContainerWrapperRef, descriptionLines] = useCalculateLinesCount<HTMLDivElement>({
        elementContainingLineHeightRef: descriptionTypographyRef,
    });

    const sxStyles = getSxStyles({
        isWithDescription: !!description,
        titleLines,
        descriptionLines,
        fontSize,
        backgroundColor,
    });

    return (
        <Grid container wrap="nowrap" alignItems="center" sx={sxStyles.contentContainer}>
            <Grid item container sx={sxStyles.mainTextContentWrapper}>
                <Grid item container sx={sxStyles.titleContainerWrapper}>
                    <IconForWidget iconCode={iconCode} backgroundColor={backgroundColor} />
                    <Grid item sx={sxStyles.titleContainer} ref={titleContainerRef}>
                        <Typography sx={sxStyles.titleTypography} title={titleTitlePropValue} ref={titleTypographyRef}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                {!!description && (
                    <Grid item container sx={sxStyles.descriptionContainerWrapper} ref={descriptionContainerWrapperRef}>
                        <Grid item sx={sxStyles.descriptionContainer}>
                            <Typography
                                sx={sxStyles.descriptionTypography}
                                title={descriptionTitlePropValue}
                                ref={descriptionTypographyRef}
                            >
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
});
