import { Box } from '@mui/material';
import { activeSelector, blue, focusSelector, grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { WidgetModel } from '../../../../../../../../models';
import { LinkWidgetSpecificAttributes } from '../../../../../../../../types';
import { LinkWidgetContentLayout as LinkWidgetContentLayoutInj } from './LinkWidgetContentLayout';

const linkWidgetTitleTextElementClassName = 'link-widget-title-text';

const sxStyles = makeSxStyles({
    link: {
        display: 'block',
        width: '100%',
        height: '100%',
        textDecoration: 'none',
        color: 'unset',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        [focusSelector]: {
            borderColor: blue[700],
            outline: 'none',
        },
        [activeSelector]: {
            borderColor: blue[700],
            outline: 'none',
        },
    },
    title: {
        color: grey[900],
    },
});

export type LinkWidgetProps = {
    widgetModel: WidgetModel;
};

export const LinkWidget = observer((props: LinkWidgetProps): JSX.Element => {
    const [LinkWidgetContentLayout] = di([LinkWidgetContentLayoutInj], LinkWidget);

    const { widgetModel } = props;
    const { widgetAttributes, title, viewSettings, iconCode } = widgetModel;
    const { fontSize, backgroundColor } = viewSettings;
    const { description, link } = widgetAttributes as LinkWidgetSpecificAttributes;

    const newTitle = (
        <Box sx={sxStyles.title} className={linkWidgetTitleTextElementClassName}>
            {title}
        </Box>
    );

    return (
        <Box component="a" href={link} sx={sxStyles.link} target="_blank">
            <LinkWidgetContentLayout
                title={newTitle}
                titleTitlePropValue={title}
                description={description}
                descriptionTitlePropValue={description}
                fontSize={fontSize}
                iconCode={iconCode}
                backgroundColor={backgroundColor}
            />
        </Box>
    );
});
