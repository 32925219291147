import axios, { AxiosResponse } from 'axios';
import { CatalogItemDTO, SettingsFromBackend } from '../types';
import popLogo from '../resources/images/pop-logo.svg';

export const getDefaultSettingsFromBackend = (): SettingsFromBackend => ({
    loginPageLogo: popLogo,
});

export const getSettingsFromBackend = (apiUrl: string): Promise<SettingsFromBackend> => {
    return axios
        .get(`${apiUrl}/settings`)
        .then((r: AxiosResponse<Array<CatalogItemDTO>>) => r.data)
        .then((settingsFromCatalog: Array<CatalogItemDTO>) => {
            const extractedSettings: SettingsFromBackend = {} as SettingsFromBackend;
            settingsFromCatalog.forEach((catalogItem) => {
                const key = catalogItem.code as keyof SettingsFromBackend;
                extractedSettings[key] = catalogItem.data.value;
            });
            return { ...getDefaultSettingsFromBackend(), ...extractedSettings };
        });
};
