import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export const DashboardCreateEditDialogContentSkeleton = (): JSX.Element => {
    return (
        <React.Fragment>
            <Grid item>
                <Skeleton height={80} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={60} width="100%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="50%" />
            </Grid>
            <Grid item>
                <Skeleton height={50} width="50%" />
            </Grid>
        </React.Fragment>
    );
};
