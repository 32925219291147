import { LocalesTree, ObjectLocales } from '@platform/front-utils';
import { WidgetBackgroundColorViewSetting, WidgetFontSizeViewSetting } from '../../../../types';

export const widget: LocalesTree & ObjectLocales = {
    decor: 'Оформление',
    icon: 'Иконка',
    invalidLink: 'Введите корректный URL. Например: https://example.com/index.html',
    linkPlaceholder: 'Ссылка',
    phrases: {
        add: 'Добавить {widget}',
        counterWidgetErrorDescription:
            'Пресет реестра удалён или недоступен. Можно изменить {widgetNominative}, удалить его или обратиться к администратору',
        delete: 'Удалить {widgetNominative}',
        deleteWidgetMessage: 'Вы действительно хотите удалить {widgetNominative}?',
        descriptionPlaceholder: 'Описание {widgetGenitive}',
        editing: 'Изменение {widgetGenitive}',
        new: 'Новый {widgetNominative}',
        noWidgetsPlaceholder: 'Нет созданных {widgetGenitive}',
        titlesPlaceholder: 'Название {widgetGenitive}',
        typePlaceholder: 'Тип {widgetGenitive}',
    },
    plural: {
        genitive: 'виджетов',
        nominative: 'виджеты',
    },
    presetPlaceholder: 'Пресет',
    registryPlaceholder: 'Реестр',
    single: {
        genitive: 'виджета',
        instrumental: 'виджетом',
        nominative: 'виджет',
    },
    widgetBackgroundColor: {
        buttonTooltip: 'Цвет фона',
        [WidgetBackgroundColorViewSetting.white]: 'Белый',

        [WidgetBackgroundColorViewSetting.lightRed]: 'Светло-красный',
        [WidgetBackgroundColorViewSetting.lightOrange]: 'Светло-оранжевый',
        [WidgetBackgroundColorViewSetting.lightChlorine]: 'Светло-салатовый',
        [WidgetBackgroundColorViewSetting.lightGreen]: 'Светло-зелёный',
        [WidgetBackgroundColorViewSetting.lightTurquoise]: 'Светло-бирюзовый',
        [WidgetBackgroundColorViewSetting.lightBlue]: 'Светло-синий',
        [WidgetBackgroundColorViewSetting.lightViolet]: 'Светло-фиолетовый',
        [WidgetBackgroundColorViewSetting.lightPink]: 'Светло-розовый',

        [WidgetBackgroundColorViewSetting.red]: 'Красный',
        [WidgetBackgroundColorViewSetting.orange]: 'Оранжевый',
        [WidgetBackgroundColorViewSetting.chlorine]: 'Салатовый',
        [WidgetBackgroundColorViewSetting.green]: 'Зелёный',
        [WidgetBackgroundColorViewSetting.turquoise]: 'Бирюзовый',
        [WidgetBackgroundColorViewSetting.blue]: 'Синий',
        [WidgetBackgroundColorViewSetting.violet]: 'Фиолетовый',
        [WidgetBackgroundColorViewSetting.pink]: 'Розовый',

        [WidgetBackgroundColorViewSetting.redGradient]: 'Красный градиент',
        [WidgetBackgroundColorViewSetting.orangeGradient]: 'Оранжевый градиент',
        [WidgetBackgroundColorViewSetting.chlorineGradient]: 'Салатовый градиент',
        [WidgetBackgroundColorViewSetting.greenGradient]: 'Зелёный градиент',
        [WidgetBackgroundColorViewSetting.turquoiseGradient]: 'Бирюзовый градиент',
        [WidgetBackgroundColorViewSetting.blueGradient]: 'Синий градиент',
        [WidgetBackgroundColorViewSetting.violetGradient]: 'Фиолетовый градиент',
        [WidgetBackgroundColorViewSetting.pinkGradient]: 'Розовый градиент',
    },
    widgetFontSize: {
        buttonTooltip: 'Размер шрифта',
        [WidgetFontSizeViewSetting.standard]: 'Стандартный',
        [WidgetFontSizeViewSetting.large]: 'Большой',
        [WidgetFontSizeViewSetting.huge]: 'Огромный',
    },
    widgetType: {
        counter: 'Счётчик',
        link: 'Ссылка',
    },
};
