import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { backgroundColorOrderedPalette, defaultWidgetBackgroundColor } from '../../../../../../../../constants';
import { WidgetModel } from '../../../../../../../../models';
import { Circle as CircleInj } from './Circle';

const sxStyles = makeSxStyles({
    paletteMenu: {
        py: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gridTemplateRows: '1fr',
        gridAutoRows: '1fr',
        gap: '8px 8px',
        gridAutoFlow: 'row',
    },
});

/**
 * @prop {WidgetModel} widgetModel - Модель виджета
 * флага отображения панели настроек виджета (см. место где колбэк создается)
 */
export type WidgetBackgroundColorButtonProps = {
    widgetModel: WidgetModel;
};

/**
 * Кнопка изменения настройки отображения фона виджета
 * @param props {@link WidgetBackgroundColorButtonProps}
 */
export const WidgetBackgroundColorButton = observer((props: WidgetBackgroundColorButtonProps): JSX.Element => {
    const [Circle] = di([CircleInj], WidgetBackgroundColorButton);

    const { widgetModel } = props;
    const { updateWidgetBackgroundViewSetting, load, currentColor } = widgetModel;

    const otherCircleColors: JSX.Element[] = backgroundColorOrderedPalette.map((colorSetting) => {
        const onColorOptionClick = (): void => {
            updateWidgetBackgroundViewSetting(colorSetting).then(load);
        };

        return (
            <Circle
                key={colorSetting}
                colorSetting={colorSetting}
                handleClick={onColorOptionClick}
                currentColor={currentColor}
            />
        );
    });

    const onColorOptionClick = (): void => {
        updateWidgetBackgroundViewSetting(defaultWidgetBackgroundColor).then(load);
    };

    const whiteCircleColor: JSX.Element = (
        <Circle
            colorSetting={defaultWidgetBackgroundColor}
            currentColor={currentColor}
            handleClick={onColorOptionClick}
        />
    );

    return (
        <Grid item container spacing={1}>
            <Grid item>{whiteCircleColor}</Grid>
            <Grid item sx={sxStyles.paletteMenu}>
                {otherCircleColors}
            </Grid>
        </Grid>
    );
});
