import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { activeSelector, blue, grey, hoverSelector, mergeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const customButtonSx: SxStyle = {
    color: blue[600],
    fontSize: '14px !important',
    backgroundColor: grey[0],
    [hoverSelector]: {
        backgroundColor: grey[0],
        color: blue[700],
    },
    [activeSelector]: {
        backgroundColor: grey[0],
        color: blue[800],
    },
    '& .MuiButton-startIcon': {
        mr: 0.5,
    },
};

// Синяя кнопка от дизайнера
export const CustomButton = observer(
    React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
        const {
            variant = 'text',
            color = 'secondary',
            startIcon = <AddIcon />,
            children,
            sx: sxProp,
            ...restProps
        } = props;

        const sxArray: SxStyle[] = [customButtonSx];
        sxProp && sxArray.push(sxProp);
        const sx = mergeSxStyles(...sxArray);

        return (
            <Button ref={ref} sx={sx} variant={variant} color={color} startIcon={startIcon} {...restProps}>
                {children}
            </Button>
        );
    }),
);
