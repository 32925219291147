import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { fontButtonFontSizes } from '../../../../../../../../constants';
import { WidgetModel } from '../../../../../../../../models';
import { FontButtonMenuOption as FontButtonMenuOptionInj } from './FontButtonMenuOption';

/**
 * @prop {WidgetModel} widgetModel - модель виджета
 * @prop {React.Dispatch<React.SetStateAction<boolean>>} setShouldDisplayWidgetActions - колбэк для изменения состояния
 * флага отображения панели настроек виджета (см. место где колбэк создается)
 */
export type FontButtonProps = {
    widgetModel: WidgetModel;
};

/**
 * Кнопка изменения настройки отображения размера шрифта
 * @param props {@link FontButtonProps}
 */
export const FontButton = observer((props: FontButtonProps): JSX.Element => {
    const [FontButtonMenuOption] = di([FontButtonMenuOptionInj], FontButton);

    const { widgetModel } = props;
    const { viewSettings, widgetType, updateWidgetFontSizeViewSettings, load } = widgetModel;
    const { fontSize } = viewSettings;

    const fontSizes: JSX.Element[] = fontButtonFontSizes.map((fontSizeOption) => {
        const onMenuOptionClick = (): Promise<void> => {
            return updateWidgetFontSizeViewSettings(fontSizeOption).then(load);
        };
        const selected = fontSizeOption === fontSize;

        return (
            <FontButtonMenuOption
                key={fontSizeOption}
                widgetType={widgetType}
                onClick={onMenuOptionClick}
                fontSizeOption={fontSizeOption}
                selected={selected}
            />
        );
    });

    return (
        <Grid item container py={1}>
            {fontSizes}
        </Grid>
    );
});
