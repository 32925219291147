import { AuthorizationCheck, ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { DashboardPageContext, DashboardPageContextType } from '../../../contexts';
import { useDashboardsAuxContext, useStore } from '../../../hooks';
import { DashboardModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { DashboardRouteParams } from '../../../types';
import {
    DashboardPageContent as DashboardPageContentInj,
    DashboardPageHeader as DashboardPageHeaderInj,
} from './components';

const sxStyles = makeSxStyles({
    contentWrapper: {
        py: 2,
        pl: 2,
        pr: 2,
        overflow: 'hidden',
    },
});

export const DashboardPage = observer((): JSX.Element => {
    const [DashboardPageHeader] = di([DashboardPageHeaderInj], DashboardPage);
    const [DashboardPageContent] = di([DashboardPageContentInj], DashboardPage);

    const { dashboardId } = useParams<DashboardRouteParams>();
    const { routes } = useDashboardsAuxContext();
    const rootStore = useStore();
    const model = useMemo(() => new DashboardModel(dashboardId, rootStore, routes), [dashboardId, rootStore]);
    const { load, silentLoad } = model;
    const { locale } = useIntl();

    const { dashboardConfig } = rootStore.coreRootStore.permissionsStore as PermissionsStore;
    const viewDashboardQuery = dashboardConfig.view();

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        !model.isLoading && silentLoad();
    }, [locale]);

    const header: ObjectLayoutProps['header'] = <DashboardPageHeader />;

    const content: ObjectLayoutProps['contentLayout'] = {
        contentWrapperProps: {
            sx: sxStyles.contentWrapper,
        },
        content: <DashboardPageContent />,
    };

    const dashboardPageContextValue: DashboardPageContextType = {
        dashboardModel: model,
    };

    return (
        <DashboardPageContext.Provider value={dashboardPageContextValue}>
            <AuthorizationCheck {...viewDashboardQuery} isWithRedirect>
                <ObjectLayout header={header} contentLayout={content} />
            </AuthorizationCheck>
        </DashboardPageContext.Provider>
    );
});
