import { TitlesDTO } from '@platform/front-types';
import { CodeTitleGroup } from '@platform/front-utils';
import { FieldValues } from '@platform/multi-lang-field';
import { Layouts } from 'react-grid-layout';
import { WidgetBaseDataDTO } from './widget';

export enum SectionCreateEditFields {
    titles = 'titles',
    roles = 'roles',
}

export type SectionCreationInfoDTO = {
    roles: CodeTitleGroup[];
};

export type SectionCreateUpdateDTO = {
    titles: TitlesDTO;
    roles: CodeTitleGroup[];
};

export type SectionCreateEditFormValues = {
    titles: FieldValues[];
    roles: CodeTitleGroup[];
};

export type SectionDTO = {
    id: string;
    title: string;
    layouts?: Layouts;
    widgets: WidgetBaseDataDTO[];
};
