import {
    WidgetCreationInfoSettings,
    WidgetCreationInfoSettingsFromBackend,
    CounterWidgetCreationInfoSettings,
    WidgetType,
} from '../../types';
import { mapRegistrySelectOptionDataItemToCodeTitleGroup } from './mapRegistrySelectOptionDataItemToCodeTitleGroup';

/**
 * Функция, трансформирующая данные с бэка, использующиеся для создания/редактирования виджета (например, опции селектов реестра и пресета для виджета-счетчика)
 */
export const transformWidgetCreationInfoForFrontend = (
    selectedWidgetType: WidgetType | null,
    dto: WidgetCreationInfoSettingsFromBackend,
): WidgetCreationInfoSettings | null => {
    switch (selectedWidgetType) {
        case WidgetType.counter: {
            const { presets, registries } = dto;
            const transformedDTO: CounterWidgetCreationInfoSettings = {
                registries: registries.map((registrySelectOptionData) =>
                    mapRegistrySelectOptionDataItemToCodeTitleGroup(registrySelectOptionData),
                ),
                presets,
            };
            return transformedDTO;
        }
    }
    return null;
};
