import { Grid } from '@mui/material';
import { ServerErrorHelper, useLocale } from '@platform/front-core';
import {
    AutocompleteField,
    FullScreenDialog,
    FullScreenForm,
    FullScreenFormProps,
    makeSxStyles,
} from '@platform/front-ui';
import { getLabelForCodeTitleGroupOption, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { MultiLangField, titlesYupSchema } from '@platform/multi-lang-field';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { matchPath, useLocation } from 'react-router-dom';
import { sectionMessages } from '../../../../../../customization';
import { useDashboardsAuxContext, useStore } from '../../../../../../hooks';
import { SectionCreateEditDialogModel } from '../../../../../../models';
import { SectionCreateEditFields } from '../../../../../../types';
import { SectionCreateEditDialogContentSkeleton as SectionCreateEditDialogContentSkeletonInj } from './SectionCreateEditDialogContentSkeleton';

export type SectionCreateEditDialogProps<T> = {
    sectionCreateOrEditDialogModel: T;
};

const sxStyles = makeSxStyles({
    userGroups: {
        display: 'none',
    },
});

export const SectionCreateEditDialog = observer(
    <T extends SectionCreateEditDialogModel>(props: SectionCreateEditDialogProps<T>): JSX.Element => {
        const [SectionCreateEditDialogContentSkeleton] = di(
            [SectionCreateEditDialogContentSkeletonInj],
            SectionCreateEditDialog,
        );
        const { sectionCreateOrEditDialogModel } = props;
        const rootStore = useStore();
        const { headerHeightStore, intlStore } = rootStore.coreRootStore;
        const { visibleHeaderHeight } = headerHeightStore;

        const { titles, roles } = SectionCreateEditFields;
        const {
            serverErrors,
            userGroupsList,
            loadUserGroupsList,
            isUserGroupsListLoading,
            onSubmit,
            onCancel,
            formInitialValues,
            isFormInitialValuesLoading,
        } = sectionCreateOrEditDialogModel;
        const { serverFormErrors } = serverErrors;

        const shouldShowUserGroups = !!userGroupsList.length;

        const { Yup } = useYup();
        const validationSchema = Yup.object({
            titles: titlesYupSchema(Yup, true).min(1),
            roles: shouldShowUserGroups ? Yup.array().min(1) : Yup.array(),
        });

        const { formatMessageFromDefineMessage } = intlStore;
        const { routes } = useDashboardsAuxContext();
        const location = useLocation();
        const { pathname } = location;

        const isCreating = matchPath(pathname, routes.dashboardCreateSection);
        const dialogTitle = formatMessageFromDefineMessage(
            isCreating ? sectionMessages.createDialogTitle : sectionMessages.editDialogTitle,
        );

        useEffect(() => {
            loadUserGroupsList();
        }, [sectionCreateOrEditDialogModel]);

        const [isSubmitDisabled, submitEndIcon, submitHandler] = useAntiDoubleClick(onSubmit);

        const submitButtonAdditionalProps: FullScreenFormProps['submitBtnAdditionalProps'] = {
            endIcon: submitEndIcon,
            disabled: isSubmitDisabled,
        };

        const titlesLabel = useLocale('section.phrases.titlesPlaceholder', true, {
            sectionGenitive: useLocale('section.single.genitive', false)[0],
        })[0];

        const rolesLabel = useLocale('user.phrases.userGroups', true, {
            user: useLocale('user.plural.accusative', false)[0],
        })[0];

        return (
            <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} onCancel={onCancel}>
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                    enableReinitialize={true}
                >
                    <FullScreenForm
                        dialogTitle={dialogTitle}
                        onCancel={onCancel}
                        submitBtnAdditionalProps={submitButtonAdditionalProps}
                    >
                        <Grid container direction="column" spacing={2}>
                            {isFormInitialValuesLoading ? (
                                <SectionCreateEditDialogContentSkeleton />
                            ) : (
                                <React.Fragment>
                                    <Grid item>
                                        <MultiLangField fieldName={titles} ruEngLangs={true} label={titlesLabel} />
                                        <ServerErrorHelper serverError={serverFormErrors[titles]} />
                                    </Grid>
                                    <Grid item {...(!shouldShowUserGroups && { sx: sxStyles.userGroups })}>
                                        <AutocompleteField
                                            fieldName={roles}
                                            label={rolesLabel}
                                            required
                                            options={userGroupsList}
                                            multiple={true}
                                            serverError={serverFormErrors[roles]}
                                            getLabel={getLabelForCodeTitleGroupOption}
                                            loading={isUserGroupsListLoading}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </FullScreenForm>
                </Formik>
            </FullScreenDialog>
        );
    },
);
